import React, { useState } from 'react';
import { empty } from '../infrastructure/Utils';
import { IDraftsQueryParams } from '../gen/ApiClients';

interface IProps {
    params: IDraftsQueryParams;
    setParams: (pars: IDraftsQueryParams) => void;
}

const fallbackProps: IProps = {
    params: {
        pageIndex: 1,
        pageSize: 10,
    },
    setParams: empty,
}

export const DraftsQueryParamsContext = React.createContext<IProps>(fallbackProps);

export const DraftsQueryParamsProvider = ({ children }: any) => {
    const [params, setParams] = useState<IDraftsQueryParams>(fallbackProps.params);

    return (
        <DraftsQueryParamsContext.Provider
            value={{
                params: params,
                setParams: setParams,
            }}>
            {children}
        </DraftsQueryParamsContext.Provider>
    );
}