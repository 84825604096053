import React, { useState, useEffect } from 'react';
import { useClient } from '../hooks/useClient';
import { IStringNamedEntity, ContactsClient } from '../gen/ApiClients';

interface IProps {
	allFilterableDepartments: string[];
	allFilterableSubDepartments: string[];
	allFilterableContacts: IStringNamedEntity[];
	isLoaded: boolean;
}

const fallbackProps: IProps = {
	allFilterableDepartments: [],
	allFilterableContacts: [],
	allFilterableSubDepartments: [],
	isLoaded: false,
};

export const FilterDataContext = React.createContext<IProps>(fallbackProps);

export const FilterDataProvider = ({ children }: any) => {
	const [departments, setDepartments] = useState<string[]>([]);
	const [subDepartments, setSubDepartments] = useState<string[]>([]);
	const [contacts, setContacts] = useState<IStringNamedEntity[]>([]);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const client = useClient(ContactsClient);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[]
	);

	const load = async () => {
		setDepartments(await client.allFilterableDepartments());
		setSubDepartments(await client.allFilterableSubDepartments());
		setContacts(await client.allFilterableContacts());
		setIsLoaded(true);
	};
	return (
		<FilterDataContext.Provider
			value={{
				allFilterableContacts: contacts,
				allFilterableDepartments: departments,
				allFilterableSubDepartments: subDepartments,
				isLoaded: isLoaded,
			}}>
			{children}
		</FilterDataContext.Provider>
	);
};
