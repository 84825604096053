import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';
import { AddressesClient, ISuggestion } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { ValidGreenIcon } from '../ValidGreenIcon';
import './AddressTypeAhead.scss';

interface IProps {
    placeholder?: string;
    onSelect: (str: ISuggestion) => void;
    clearSelect: () => void;
    selected: ISuggestion | undefined;
}
export const AddressTypeAhead = ({ placeholder, onSelect, clearSelect, selected }: IProps) => {
    const locContext = useLocalizationContext();
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
    const client = useClient(AddressesClient);
    const [to, setTo] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    const handleTextChanged = async (txt: string) => {
        if (selected) {
            clearSelect();
        }
        if (!isOpen) {
            setIsOpen(true);
        }
        setText(txt);
        if (!txt || txt.length < 3) {
            setSuggestions([]);
        }
        setIsTyping(true);
        clearTimeout(to);
        setTo(setTimeout(async () => {
            setIsTyping(false);
            if (txt && txt.length >= 3) {
                setIsLoading(true);
                setSuggestions(await client.suggestions(txt));
                setIsLoading(false);
            }
        }, 500));
    }
    const handleSetSelected = (suggestion: ISuggestion) => {
        if (suggestion.formatted) {
            setText(suggestion.formatted);
        }
        onSelect(suggestion);
        setIsOpen(false);
    }
    const handleFocus = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    }

    const renderWarningIcon = () => {
        return (
            <Popup
                content={locContext.alsoFillInAHouseNumber}
                basic
                trigger={<FontAwesomeIcon
                    className="warning-icon"
                    icon={['fas', 'exclamation-triangle']} />} />
        );
    }

    return (
        <Dropdown
            tabIndex={-1}
            className="dd-custom stretch-hor"
            trigger={
                <div className={`df-row-ac dd-trigger${isOpen ? ' is-open' : ''}`} onFocus={e => handleFocus()}>
                    <input
                        type="text"
                        value={selected && selected.formatted ? selected.formatted : text}
                        onChange={e => handleTextChanged(e.target.value)}
                        placeholder={placeholder}></input>
                    {selected && selected.isExact === true ? <ValidGreenIcon /> : null}
                    {selected && selected.isExact === false ? renderWarningIcon() : null}
                </div>
            }
            icon={null}
            onClose={() => { }}
            onOpen={() => { }}
            open={isOpen}
            onBlur={() => setIsOpen(false)}
            scrolling>
            <Dropdown.Menu>
                <div className="df-col">
                    {suggestions && !isLoading && !isTyping ?
                        suggestions.map(t => <Suggestion
                            key={`${t.xCoordinate}-${t.yCoordinate}-${t.formatted}`}
                            text={t.formatted}
                            handleSelect={() => handleSetSelected(t)} />)
                        : null}
                    {!isTyping && !isLoading && text && (!suggestions || suggestions.length === 0) ? <div className="simple-list-item">{locContext.noAddressFound}</div> : null}
                    {isTyping ? <div className="simple-list-item">{locContext.busyTyping}...</div> : null}
                    {isLoading ? <div className="simple-list-item">{locContext.busySearchingAddress}...</div> : null}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

interface ISimpleListItemProps {
    text: string | undefined;
    handleSelect: () => void;
}

const Suggestion = ({ text, handleSelect }: ISimpleListItemProps) => {
    return (
        <div className="simple-list-item" onClick={() => handleSelect()}>{text}</div>
    );
}