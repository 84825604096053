import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import './ModalContext.scss';

export interface IModalProps {
    open: (content: JSX.Element, closeOnEscapeOrDimmerClick?: boolean) => void;
    close: () => void;
}

export const ModalContext = React.createContext<IModalProps>({ open: () => { }, close: () => { } });

export const ModalProvider = (props: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [content, setContent] = useState<JSX.Element>(<div></div>);
    const [closeOnEscapeOrDimmerClick, setCloseOnEscapeOrDimmerClick] = useState<boolean>(true);

    return (
        <ModalContext.Provider
            value={{
                open: (content: JSX.Element, closeOnEscapeOrDimmerClick: boolean = true) => {
                    setIsOpen(true);
                    setContent(<div></div>)
                    setContent(content);
                    setCloseOnEscapeOrDimmerClick(closeOnEscapeOrDimmerClick);
                }, close: () => setIsOpen(false)
            }}>
            {props.children}
            <Modal
                className="default-modal"
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                open={isOpen}
                centered={false}
                closeOnEscape={closeOnEscapeOrDimmerClick}
                closeOnDimmerClick={closeOnEscapeOrDimmerClick}
                closeIcon={closeOnEscapeOrDimmerClick}>
                <Modal.Content>
                    {content}
                </Modal.Content>
            </Modal>
        </ModalContext.Provider>
    );
}

