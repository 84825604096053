import React, { useState, useContext } from 'react';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { ReactComponent as NewLogo } from './icons/newbooking.svg';
import { ReactComponent as DraftLogo } from './icons/draft.svg';
import { ReactComponent as TemplateLogo } from './icons/template.svg';
import { IOption } from '../../../infrastructure/Models';
import { SelectButton } from '../../common/buttons/SelectButton';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { CustomDropDown } from '../../common/dropdown/CustomDropdown';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { RouterContext } from '../../../context/RouterContext';
import * as routes from '../../../routes';
import './StartSelect.scss';
import { Responsive } from 'semantic-ui-react';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';


export const StartSelect = () => {
    const locContext = useLocalizationContext();
    const [draft, setDraft] = useState<string>('');
    const [template, setTemplate] = useState<string>('');
    const [selectNew, setSelectNew] = useState<boolean>(false);
    const context = useContext(TransportSubmitContext);
    const routerContext = useContext(RouterContext);

    if (!context.templates || !context.drafts) {
        return null;
    }

    context.init();

    const onSelectTemplate = (val: string) => {
        setDraft('');
        setSelectNew(false);
        setTemplate(val);
    }
    const onSelectDraft = (val: string) => {
        setTemplate('');
        setSelectNew(false);
        setDraft(val);
    }
    const onSelectNew = () => {
        setDraft('');
        setTemplate('');
        setSelectNew(!selectNew);
    }

    const onNext = () => {
        if (selectNew) {
            context.create();
        } else if (draft) {
            routerContext.navigate(routes.calculateNewRoute(draft));
        } else if (template) {
            // console.log(template);
            context.startFromTemplate(template);
        }
    }

    return (
        <div className="df-col-ac start-select">
            <div className="header-text">{locContext.transport}: {locContext.questionHowDoYouWantToStart}</div>
            <div className="df-row df-col-if-mobile">
                <div className={`df-col-ac jc-sb start-option-container ${selectNew ? ' selected' : ''}`}>
                    <NewLogo className="start-logo" />
                    <div className="df-col-ac">
                        <div className="option-header">{locContext.newTransport}</div>
                        <div className="option-description">{locContext.newTransportOptionDescription}</div>
                        <SelectButton text="Maak nieuw transport" selected={selectNew} onClick={onSelectNew} />
                    </div>
                </div>
                <div className={`df-col-ac start-option-container middle ${draft ? ' selected' : ''}`}>
                    <DraftLogo className="start-logo" />
                    <div className="df-col-ac">
                        <div className="option-header">{locContext.startFromAConcept}</div>
                        <div className="option-description">{locContext.conceptOptionDescription}</div>
                        <CustomDropDown<string>
                            onClear={() => setDraft('')}
                            placeholder={locContext.placeholderConcept}
                            options={context.drafts.map<IOption<string>>(t => ({ key: t.id ? t.id : '', text: t.value ? t.value : '', value: t.id ? t.id : '' }))}
                            onSelect={onSelectDraft}
                            selected={draft}
                            small
                            clearable
                            formatTrigger />
                    </div>
                </div>
                <div className={`df-col-ac start-option-container ${template ? ' selected' : ''}`}>
                    <TemplateLogo className="start-logo" />
                    <div className="df-col-ac">
                        <div className="option-header">{locContext.startFromATemplate}</div>
                        <div className="option-description">{locContext.templateOptionDescription}</div>
                        <CustomDropDown<string>
                            onClear={() => setTemplate('')}
                            placeholder={locContext.placeholderTemplate}
                            options={context.templates.map<IOption<string>>(t => ({ key: t.id ? t.id : '', text: t.value ? t.value : '', value: t.id ? t.id : '' }))}
                            onSelect={onSelectTemplate}
                            selected={template}
                            small
                            clearable
                            formatTrigger />
                    </div>
                </div>
            </div>
            <Responsive {...Responsive.onlyMobile} className="blank-mobile-footer">
                <div className="df-row-ac jc-sb">
                    <TextLinkButton onClick={() => context.cancel()}>{locContext.cancel}</TextLinkButton>
                    <PrimaryButton disabled={!draft && !template && !selectNew} rectanglePadding noStretch className="next-button" onClick={() => onNext()}>{locContext.next}</PrimaryButton>
                </div>
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <PrimaryButton disabled={!draft && !template && !selectNew} rectanglePadding noStretch className="next-button" onClick={() => onNext()}>{locContext.next}</PrimaryButton>
            </Responsive>
        </div>
    );
}
