import React from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { IUnloadOperationModel, ILoadOperationInCargo } from '../../../../gen/ApiClients';
import { TextLinkButton } from '../../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { SelectField } from '../../../common/forms/SelectField';
import { IOption } from '../../../../infrastructure/Models';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import * as yup from 'yup';
import { setFieldTouched } from '../../../common/forms/FormsUtils';

interface IProps {
    header: string;
    onSubmit: (unload: IUnloadOperationModel) => void;
    onPrevious: () => void;
    loadOperationsInCargo: ILoadOperationInCargo[];
}

export const UnloadOperationComponent = ({ header, loadOperationsInCargo, onSubmit, onPrevious }: IProps) => {
    const locContext = useLocalizationContext();
    const schema = yup.object<IUnloadOperationModel>().shape<IUnloadOperationModel>({
        loadId: yup.string().required(locContext.selectAPackage),
    });
    const options = loadOperationsInCargo.map<IOption<string>>(t => ({
        key: t.loadId ? t.loadId : '',
        text: t.formatted ? t.formatted : '',
        value: t.loadId ? t.loadId : ''
    }))
    const handleSubmit = async (model: IUnloadOperationModel, actions: FormikActions<IUnloadOperationModel>) => {
        //first hand validation
        setFieldTouched<IUnloadOperationModel>('loadId', actions);
        actions.setSubmitting(false);
        onSubmit(model);
    }

    return (
        <div className="blank-container">
            <div className="header-text">{header}</div>
            <Formik<IUnloadOperationModel>
                initialValues={{}}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <SelectField<IUnloadOperationModel>
                            xName="loadId"
                            options={options}
                            label={locContext.packageInCargo}
                            placeholder={locContext.selectAPackage} />
                        <div className={`df-row-ac blank-container-buttons jc-e`}>
                            <div className="df-row-ac">
                                <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.cancel}</TextLinkButton>
                                <PrimaryButton rectanglePadding disabled={props.isSubmitting} type="submit">{locContext.add}</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}