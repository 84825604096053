import React, { useContext } from 'react';
import { IOption } from '../../../infrastructure/Models';
import { CustomDropDown } from '../../common/dropdown/CustomDropdown';
import { LocalizationContext } from '../../../context/LocalizationContext';

interface IProps {
    isMobile: boolean;
    className?: string;
}

export const LanguageDropDown = ( {isMobile, className}: IProps ) => {
    const locContext = useContext(LocalizationContext);

    const options: IOption<"en" | "fr" | "nl">[] = [
        { key: "en", value: "en", text: "English" },
        { key: "fr", value: "fr", text: "Français" },
        { key: "nl", value: "nl", text: "Nederlands" },
    ]

    return (
        <CustomDropDown<("en" | "fr" | "nl") >
            onClear={() => { }}
            placeholder="Taal/Language/Langue"
            options={options}
            onSelect={(val) => locContext.setLanguage(val)}
            selected={locContext.getLanguage()}
            stretch={isMobile}
            searchable={false}
            clearable={false}
            small={true} />
    );
}