import React, { useState } from 'react';
import { empty } from '../infrastructure/Utils';
import { INotificationsQueryParams } from '../gen/ApiClients';

interface IProps {
    params: INotificationsQueryParams;
    setParams: (pars: INotificationsQueryParams) => void;
}

const fallbackProps: IProps = {
    params: {
        pageIndex: 1,
        pageSize: 10,
    },
    setParams: empty,
}

export const NotificationsQueryParamsContext = React.createContext<IProps>(fallbackProps);

export const NotificationsQueryParamsProvider = ({ children }: any) => {
    const [params, setParams] = useState<INotificationsQueryParams>(fallbackProps.params);

    return (
        <NotificationsQueryParamsContext.Provider
            value={{
                params: params,
                setParams: setParams,
            }}>
            {children}
        </NotificationsQueryParamsContext.Provider>
    );
}