import React, { useEffect, useState } from 'react';
import { AddressesClient, IAddressDetail, IAddressModel } from '../../../../gen/ApiClients';
import { useClient } from '../../../../hooks/useClient';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { tryCatch } from '../../../../infrastructure/Utils';
import { AddressFormComponent } from './AddressFormComponent';
import { SelectFromAddressBookComponent } from './SelectFromAddressBookComponent';

interface IProps {
    header: string;
    address: IAddressDetail | undefined;
    mustSave: boolean;
    defaultLoadAddress: IAddressDetail | undefined;
    fromAddressBookId: string | undefined;
    submit: (id: string | undefined, address: IAddressModel | undefined, mustSave: boolean) => void;
    onPrevious: () => void;
    modal?: boolean;
    previousText?: string;
}

export const AddressComponent = ({ header, address, mustSave, defaultLoadAddress, fromAddressBookId, submit, onPrevious, modal, previousText }: IProps) => {
    const locContext = useLocalizationContext();
    previousText = previousText ? previousText : locContext.previous;
    const client = useClient(AddressesClient);
    const [addresses, setAddresses] = useState<IAddressDetail[]>();
    const [selectFromAddressBook, setSelectFromAddressBook] = useState<boolean>(false);
    const [editFromAddressBook, setEditFromAddressBook] = useState<IAddressDetail>();

    useEffect(() => {
        loadAddresses();
    }, 
    // eslint-disable-next-line 
    []);

    const loadAddresses = async () => {
        setAddresses(await tryCatch(client.all(), locContext.serverError));
    }
    useEffect(() => {
        if (fromAddressBookId) {
            setSelectFromAddressBook(true);
        }
    }, [fromAddressBookId]);

    const editAddressFromAddressBook = (selAddress: IAddressDetail) => {
        setEditFromAddressBook(selAddress);
        setSelectFromAddressBook(false);
    }

    return (
        <div className="blank-container">
            <div className="header-text">{header}</div>
            <div className="df-row-ac jc-se main-select-container">
                <div className={`main-select${selectFromAddressBook ? '' : ' has-selected'}`} onClick={() => setSelectFromAddressBook(false)}>{locContext.fillInAddress}</div>
                <div className={`main-select${selectFromAddressBook ? ' has-selected' : ''}`} onClick={() => setSelectFromAddressBook(true)}>{locContext.selectFromAddressBook}</div>
            </div>
            {selectFromAddressBook ?
                <SelectFromAddressBookComponent
                    id={fromAddressBookId}
                    modal={modal}
                    addresses={addresses ? addresses : []}
                    onPrevious={onPrevious}
                    submit={(val) => {
                        setEditFromAddressBook(undefined);
                        submit(val, undefined, false);
                    }}
                    previousText={previousText}
                    onEdit={editAddressFromAddressBook} />
                :
                <AddressFormComponent
                    address={editFromAddressBook ? editFromAddressBook : address}
                    defaultMustSave={mustSave}
                    defaultLoadAddress={defaultLoadAddress}
                    modal={modal}
                    previousText={previousText}
                    onPrevious={onPrevious}
                    submit={(model, mustSave) => {
                        setEditFromAddressBook(undefined);
                        submit(undefined, model, mustSave);
                    }} />
            }
        </div>
    );
}