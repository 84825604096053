import React, { useState, useEffect, useContext } from 'react';
import { INotification, NotificationsClient } from '../gen/ApiClients';
import { useClient } from '../hooks/useClient';
import { ModalContext } from './ModalContext';
import { NotificationDetail } from '../components/dashboard/overview/bulletins/NotificationDetail';
import { empty } from '../infrastructure/Utils';
import { ITrigger, useTrigger } from '../hooks/useTrigger';
import { showSuccessToast } from '../components/common/toast/ToastNotification';
import { useLocalizationContext } from '../hooks/useLocalizationContext';
import { useInterval } from '../hooks/useInterval';

interface IProps {
    unread: INotification[];
    countUnread: number;
    show: (notification: INotification) => void;
    trigger: ITrigger;
    isLoading: boolean;
}

const fallbackProps: IProps = {
    unread: [],
    countUnread: 0,
    show: empty,
    trigger: { val: false },
    isLoading: false,
}

export const NotificationsContext = React.createContext<IProps>(fallbackProps);

export const NotificationsProvider = (props: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const client = useClient(NotificationsClient);
    const modalContext = useContext(ModalContext);
    const [trigger, hitTrigger] = useTrigger();
    const locContext = useLocalizationContext();

    useInterval(() => hitTrigger(), 15000);

    useEffect(() => {
        loadNotifications();
    }, 
    // eslint-disable-next-line
    [trigger]);

    const loadNotifications = async () => {
        setIsLoading(true);
        const t = await client.unread();
        setNotifications(t);
        setIsLoading(false);
    }

    const show = (notification: INotification) => {
        modalContext.open(
        <NotificationDetail
            notification={notification}
            cancel={() => modalContext.close()}
            confirm={ async () => {
                await client.markAsRead(notification.id ? notification.id : '');
                hitTrigger();
                showSuccessToast(locContext.notificationMarkedAsRead);
                modalContext.close();
            }} />);
    }

    return (
        <NotificationsContext.Provider
            value={{
                unread: notifications,
                countUnread: notifications ? notifications.length : 0,
                show: show,
                trigger: trigger,
                isLoading: isLoading,
            }}>
            {props.children}
        </NotificationsContext.Provider>
    );
}
