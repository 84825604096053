import React, { useState } from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { Dropdown } from 'semantic-ui-react';
import { IOption } from '../../../infrastructure/Models';
import { pad } from '../../../infrastructure/Utils';
import './DefaultTimeInput.scss';
import { DropDownTrigger } from '../dropdown/DropDownTrigger';

interface IProps {
    placeholder: string;
    value: string;
    onValueChanged: (time: string) => void;
    className?: string;
}

function generateOptions(): IOption<string>[] {
    let x: IOption<string>[] = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minutes = 0; minutes < 4; minutes++) {
            const y = `${pad(hour, 2)}:${pad(minutes * 15, 2).toString()}`;
            x.push({ key: y, value: y, text: y });
        }
    }
    return x;
}

const options = generateOptions();

export const DefaultTimeInput = ({ placeholder, value, onValueChanged, ...rest }: IProps) => {
    let triggerText = placeholder ? placeholder : '';
    if (value) {
        const x = options.find(t => t.value === value);
        if (x) {
            triggerText = x.text;
        }
    }
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleSelect = (option: IOption<string>) => {
        onValueChanged(option.value);
        setIsOpen(false);
    }

    return (
        <Dropdown
            className={useCombineClassNames("dd-custom stretch-hor", rest)}
            trigger={
                <DropDownTrigger
                    onClear={() => { }}
                    selected={value ? true : false}
                    text={triggerText}
                    isOpen={isOpen}
                    clickTrigger={() => setIsOpen(!isOpen)}
                    clearable={false}
                    formatSelected={false}
                    small={false} />
            }
            icon={null}
            onClose={() => { }}
            onOpen={() => { }}
            open={isOpen}
            onBlur={() => setIsOpen(false)}
            scrolling>
            <Dropdown.Menu>
                <div className="df-row time-list-items-container">
                    {options.map(t => <TimeListItem
                        key={t.key}
                        selected={value === t.value}
                        text={t.text}
                        handleSelect={() => handleSelect(t)} />)}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

interface ITimeListItemProps {
    text: string;
    selected: boolean;
    handleSelect: () => void;
}

const TimeListItem = ({ text, handleSelect, selected }: ITimeListItemProps) => {
    return (
        <div className={`time-list-item${selected ? ' selected' : ''}`} onClick={() => handleSelect()}>
            <div className="time-list-item-inner">{text}</div>
        </div>
    );
}