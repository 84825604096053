import React from 'react';
import { Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MoreInfo.scss';

interface IProps {
    info: string;
    withMargin?: boolean;
}
export const MoreInfo = ({ info: text, withMargin = true }: IProps) => {
    return (
        <Popup
            content={text}
            basic
            trigger={<FontAwesomeIcon
                className={`more-info${withMargin ? ' with-margin' : ''}`}
                icon={['fas', 'info-circle']} />} />
    );
}