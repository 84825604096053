import React, { useState } from 'react';
import { empty } from '../infrastructure/Utils';
import { ITemplatesQueryParams } from '../gen/ApiClients';

interface IProps {
    params: ITemplatesQueryParams;
    setParams: (pars: ITemplatesQueryParams) => void;
}

const fallbackProps: IProps = {
    params: {
        pageIndex: 1,
        pageSize: 10,
    },
    setParams: empty,
}

export const TemplatesQueryParamsContext = React.createContext<IProps>(fallbackProps);

export const TemplatesQueryParamsProvider = ({ children }: any) => {
    const [params, setParams] = useState<ITemplatesQueryParams>(fallbackProps.params);

    return (
        <TemplatesQueryParamsContext.Provider
            value={{
                params: params,
                setParams: setParams,
            }}>
            {children}
        </TemplatesQueryParamsContext.Provider>
    );
}