import React, { useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import '../../common/modal/BaseConfirmation.scss';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { IStopDateValidation } from '../../../gen/ApiClients';

interface IProps extends React.HTMLProps<HTMLDivElement> {
	validation: IStopDateValidation;
	confirm: () => void | Promise<any>;
}

export const ExtraTimeMarginConfirmationDialog = ({ confirm, validation, ...rest }: IProps) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const strings = useLocalizationContext();

	const submit = async () => {
		setIsSubmitting(true);
		await confirm();
		setIsSubmitting(false);
	};

	return (
		<div className={useCombineClassNames('df-col stretch-ver confirmation-modal', rest)}>
			<div className='df-col confirmation-modal-inner'>
				<div className='mc-title'>{strings.extraTimeApplicableAtThisPlace}</div>
				<div className='mc-description'>{`${strings.inThisTimeMinutesDelayAreIncludedPt1} ${validation.appliedExtraTimeMarginInMinutes?.toString()} ${
					strings.inThisTimeMinutesDelayAreIncludedPt2
				}`}</div>
				<div style={{ fontWeight: 'bold' }}>{strings.phoneNumber}</div>
				<div
					className='df-row-ac jc-sb'
					style={{ marginTop: '2px', marginBottom: '24px' }}>
					<div>0800 32 233</div>
					<div>{strings.available247}</div>
				</div>
			</div>
			<Responsive minWidth={Responsive.onlyTablet.minWidth}>
				<div className='df-row-ac jc-e'>
					<div className='df-row-ac'>
						<PrimaryButton
							disabled={isSubmitting}
							showLoader
							onClick={() => submit()}>
							{strings.okUnderstood}
						</PrimaryButton>
					</div>
				</div>
			</Responsive>
			<Responsive {...Responsive.onlyMobile}>
				<div className='df-col'>
					<PrimaryButton
						className='stretch-hor'
						onClick={() => submit()}>
						{strings.okUnderstood}
					</PrimaryButton>
				</div>
			</Responsive>
		</div>
	);
};
