import React, { useContext } from 'react';
import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
import { ReactComponent as DashboardSelectedIcon } from './icons/dashboard-sel.svg';
import { ReactComponent as TransportsIcon } from './icons/transports.svg';
import { ReactComponent as TransportsSelectedIcon } from './icons/transports-sel.svg';
import { ReactComponent as TemplatesIcon } from './icons/templates.svg';
import { ReactComponent as TemplatesSelectedIcon } from './icons/templates-sel.svg';
import { ReactComponent as DraftsIcon } from './icons/drafts.svg';
import { ReactComponent as DraftsSelectedIcon } from './icons/drafts-sel.svg';
import { ReactComponent as AddressbookIcon } from './icons/addressbook.svg';
import { ReactComponent as AddressbookSelectedIcon } from './icons/addressbook-sel.svg';
import { NavLink } from 'react-router-dom';
import * as fromSubRoutes from '../subRoutes';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './MobileFooter.scss';
import { RouterContext } from '../../../context/RouterContext';

export const MobileFooter = () => {
    const locContext = useLocalizationContext();

    return (
        <div className="df-row-ac mobile-footer-container stretch-hor">
            <MobileNavLink to={fromSubRoutes.OverviewRoute} text={locContext.dashboard} regularIcon={<DashboardIcon />} selectedIcon={<DashboardSelectedIcon />} />
            <MobileNavLink to={fromSubRoutes.TranportsRoute} text={locContext.transports} regularIcon={<TransportsIcon />} selectedIcon={<TransportsSelectedIcon />} />
            <MobileNavLink to={fromSubRoutes.TemplatesRoute} text={locContext.templates} regularIcon={<TemplatesIcon />} selectedIcon={<TemplatesSelectedIcon />} />
            <MobileNavLink to={fromSubRoutes.DraftsRoute} text={locContext.concepts} regularIcon={<DraftsIcon />} selectedIcon={<DraftsSelectedIcon />} />
            <MobileNavLink to={fromSubRoutes.AddressesRoute} text={locContext.addressBook} regularIcon={<AddressbookIcon />} selectedIcon={<AddressbookSelectedIcon />} />
        </div>
    );
}

interface IProps{
    to: string;
    regularIcon: JSX.Element;
    selectedIcon: JSX.Element;
    text: string;
}
const MobileNavLink = ({to, regularIcon, selectedIcon, text}: IProps) => {
    const routerContext = useContext(RouterContext);
    return (
        <NavLink className="nav-link" to={to}>
            <div className="df-col-ac">
                {routerContext.activeRoute.endsWith(to) ? selectedIcon : regularIcon}
                <span>{text}</span>
            </div>
        </NavLink>
    );
}
