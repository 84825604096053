import { Form, Formik, FormikActions } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { ModalContext } from '../../../context/ModalContext';
import { IStopDateValidation } from '../../../gen/ApiClients';
import { IStringsWithFormat, useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { tryCatchWithLoading } from '../../../infrastructure/Utils';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { setFieldError } from '../../common/forms/FormsUtils';
import { SelectDate } from '../../common/forms/SelectDate';
import { SelectTimeWithSpecifier } from '../../common/forms/SelectTimeWithSpecifier';
import { ExtraTimeMarginConfirmationDialog } from './ExtraTimeMarginConfirmationDialog';
import { roundToNearestTime } from './roundToNearestTime';
import React from 'react';

interface IModel {
	date: Date;
	time: string;
	specifier: string;
}

const createSchema = (strings: IStringsWithFormat) => {
	return yup.object<IModel>({
		time: yup.string().required(strings.validateTime),
		specifier: yup.mixed().required(strings.validateTimeSelector),
		date: yup.date().required(strings.validateTime),
	});
};

interface IProps {
	header: string;
	date: Date | undefined;
	setTime: boolean;
	specifier: string;
	onSubmit: (date: Date, specifier: string) => void;
	onPrevious: () => void;
	modal?: boolean;
	validate: (date: Date, dateSpecifier: string) => Promise<IStopDateValidation>;
}

export const DateComponentWithStopDateValidation = ({ validate, header, date, setTime, specifier, onSubmit, onPrevious, modal = false }: IProps) => {
	useEffect(() => {
		console.log(specifier);
	}, [specifier]);

	const strings = useLocalizationContext();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const modalContext = useContext(ModalContext);
	const initialValue: IModel = {
		// time: date ? moment(date, DefaultDateFormat).format('HH:mm') : '',
		time: date && setTime ? roundToNearestTime(date) : '',
		specifier: specifier ? specifier : (undefined as any),
		date: date ? date : new Date(),
	};
	const handleSubmit = async (model: IModel, actions: FormikActions<IModel>) => {
		var splits = model.time.split(':');
		let m = moment(model.date)
			.set({ hour: Number(splits[0]), minute: Number(splits[1]), second: 0, millisecond: 0 })
			.toDate();
		const response = await tryCatchWithLoading(validate(m, model.specifier), () => {}, strings.serverError);
		actions.setSubmitting(false);
		if (response === undefined || response === null) {
			return;
		}
		if (response.hasError) {
			if (response.errorMessage !== null && response.errorMessage !== undefined && response.errorMessage.indexOf('0800 32') > -1) {
				setFieldError<IModel>('date', response.errorMessage ? response.errorMessage : 'Error', actions);
			} else {
				setFieldError<IModel>('time', response.errorMessage ? response.errorMessage : 'Error', actions);
			}
		} else if (response.isExtraTimeMarginApplicable) {
			modalContext.open(
				<ExtraTimeMarginConfirmationDialog
					confirm={() => {
						modalContext.close();
						onSubmit(m, model.specifier);
					}}
					validation={response}
				/>
			);
		} else {
			onSubmit(m, model.specifier);
		}
	};

	return (
		<div className='blank-container'>
			<div className='header-text'>{header}</div>
			<Formik<IModel>
				initialValues={initialValue}
				validationSchema={schema}
				onSubmit={handleSubmit}>
				{props => (
					<Form>
						<div className='pos-rel'>
							<SelectDate<IModel>
								label={strings.date}
								xName='date'
							/>
							<SelectTimeWithSpecifier<IModel>
								label={strings.hours}
								specifierName='specifier'
								xName='time'
							/>
							<div className={`df-row-ac blank-container-buttons ${modal ? 'jc-e' : 'jc-c'}`}>
								<div className='df-row-ac'>
									<TextLinkButton
										className='previous-button'
										onClick={onPrevious}>
										{strings.previous}
									</TextLinkButton>
									<PrimaryButton
										rectanglePadding
										disabled={props.isSubmitting}
										showLoader
										type='submit'>
										{strings.next}
									</PrimaryButton>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
