import React, { useContext, useState } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { NavLink, Redirect, Switch } from 'react-router-dom';
import { Responsive } from 'semantic-ui-react';
import { AuthContext } from '../../context/AuthContext';
import { DashboardSearchProvider } from '../../context/DashboardSearchTextContext';
import { FilterDataProvider } from '../../context/FilterDataContext';
import { NotificationsProvider } from '../../context/NotificationsContext';
import { RouterContext } from '../../context/RouterContext';
import { WrapperFullScreenLoadContext, WrapperFullScreenLoadProvider } from '../../context/WrapperFullScreenLoadContext';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { ProtectedRoute } from '../../infrastructure/ProtectedRoute';
import * as fromMainRoutes from '../../routes';
import { FullScreenLoader } from '../common/loaders/FullScreenLoader';
import { Logo } from '../common/Logo';
import { Addresses } from './addresses/Addresses';
import './Dashboard.scss';
import { DashboardSearchbox } from './DashboardSearchbox';
import { DraftDetail } from './drafts/DraftDetail';
import { Drafts } from './drafts/Drafts';
import { MobileFooter } from './mobileFooter/MobileFooter';
import { MobileNotifications } from './MobileNotifications';
import { Notifications } from './notifications/Notifications';
import { NotificationsDropdown } from './NotificationsDropdown';
import { Overview } from './overview/Overview';
import { Settings } from './settings/Settings';
import * as fromSubRoutes from './subRoutes';
import { TemplateDetail } from './templates/TemplateDetail';
import { Templates } from './templates/Templates';
import { Transport } from './transports/Transport';
import { Transports } from './transports/Transports';

export const Dashboard = () => {
    return (
        <FilterDataProvider>
            <NotificationsProvider>
                <DashboardSearchProvider>
                    <WrapperFullScreenLoadProvider>
                        <InnerObject />
                    </WrapperFullScreenLoadProvider>
                </DashboardSearchProvider>
            </NotificationsProvider>
        </FilterDataProvider>
    );
}

const InnerObject = () => {
    const loaderContext = useContext(WrapperFullScreenLoadContext);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    return (
        <div className="stretch-ver stretch-hor">
            <Responsive {...Responsive.onlyMobile} className="stretch-ver">
                <div className="df-col container">
                    <div className="sticky-at-top z-index-1 stretch-hor">
                        <MobileHeader isMenuOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)} />
                    </div>
                    {isMenuOpen ?
                        <div className="df-col stretch-ver mobile-menu">
                            <LowerNavLinks clicked={() => setIsMenuOpen(false)} />
                        </div>
                        : null}
                    {isMenuOpen ? null :
                        <div className="mobile-body-wrapper df-col">
                            {loaderContext.isLoading ? <FullScreenLoader /> : null}
                            <div className="padding-10px">
                                <BodyContent />
                            </div>
                        </div>
                    }
                    {isMenuOpen ? null :
                        <div className="nav-footer df-row stretch-hor">
                            <MobileFooter />
                        </div>
                    }
                </div>
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.minWidth} className="stretch-ver">
                <div className="df-row container">
                    <div className="side-nav df-col jc-sb sticky-at-top">
                        <SideNav />
                    </div>
                    <div className="body-wrapper df-col pos-rel">
                        {loaderContext.isLoading ? <FullScreenLoader /> : null}
                        <div className="sticky-at-top z-index-1">
                            <DesktopHeader />
                        </div>
                        <div className="body-content stretch-ver">
                            <BodyContent />
                        </div>
                    </div>
                </div>
            </Responsive>
        </div>
    );
}

const BodyContent = () => {
    return (
        <Switch>
            <ProtectedRoute path={fromSubRoutes.DraftDetailRoute} component={DraftDetail} />
            <ProtectedRoute path={fromSubRoutes.DraftsRoute} component={Drafts} />
            <ProtectedRoute path={fromSubRoutes.TemplateDetailRoute} component={TemplateDetail} />
            <ProtectedRoute path={fromSubRoutes.TemplatesRoute} component={Templates} />
            <ProtectedRoute path={fromSubRoutes.TransportRoute} component={Transport} />
            <ProtectedRoute path={fromSubRoutes.TranportsRoute} component={Transports} />
            <ProtectedRoute path={fromSubRoutes.OverviewRoute} component={Overview} />
            <ProtectedRoute path={fromSubRoutes.AddressesRoute} component={Addresses} />
            <ProtectedRoute path={fromSubRoutes.SettingsRoute} component={Settings} />
            <ProtectedRoute path={fromSubRoutes.NotificationsRoute} component={Notifications} />
            <Redirect path={fromMainRoutes.DashboardRoute} to={fromSubRoutes.OverviewRoute} />
        </Switch>
    );
}

const SideNav = () => {
    const routerContext = useContext(RouterContext);
    const locContext = useLocalizationContext();

    return (
        <div className="df-col stretch-ver jc-sb">
            <div className="df-col">
                <Logo />
                <div className="nav-links df-col">
                    <NavLink className="upper-nav-link" to={fromSubRoutes.OverviewRoute}>{locContext.dashboard}</NavLink>
                    <NavLink className="upper-nav-link" to={fromSubRoutes.TranportsRoute}>{locContext.transports}</NavLink>
                    {routerContext.activeRoute.startsWith(fromSubRoutes.TranportsRoute) ?
                        <div className="df-col">
                            <NavLink className="sub-nav-link" to={fromSubRoutes.TemplatesRoute}>{locContext.templates}</NavLink>
                            <NavLink className="sub-nav-link" to={fromSubRoutes.DraftsRoute}>{locContext.concepts}</NavLink>
                        </div>
                        : null}
                    <NavLink className="upper-nav-link" to={fromSubRoutes.AddressesRoute}>{locContext.addressBook}</NavLink>
                    <NavLink className="upper-nav-link" to={fromSubRoutes.NotificationsRoute}>{locContext.notifications}</NavLink>
                </div>
            </div>
            <LowerNavLinks />
        </div>
    );
}

interface ILowerNavLinksProps {
    clicked?: () => void;
}
const LowerNavLinks = ({ clicked }: ILowerNavLinksProps) => {
    const authContext = useContext(AuthContext);
    const locContext = useLocalizationContext();
    return (
        <div className="nav-links df-col">
            <div className="company-name">{authContext.user ? authContext.user.companyName : ''}</div>
            <NavLink className="lower-nav-link" to={fromSubRoutes.SettingsRoute} onClick={() => {
                if (clicked) { 
                    clicked() 
                }
            }}>{locContext.settings}</NavLink>
            {/* eslint-disable-next-line */}
            <a className="lower-nav-link" onClick={() => { 
                authContext.logout(); 
                if (clicked) { 
                    clicked() 
                }}}>{locContext.logOut}</a>
        </div>
    );
}

const DesktopHeader = () => {
    const authContext = useContext(AuthContext);

    return (
        <div className="top-bar df-row-ac jc-sb">
            <DashboardSearchbox className="search-anything" />
            <div className="df-row-ac">
                <NotificationsDropdown />
                <div className="user-text">{authContext.user ? authContext.user.name : ''}</div>
            </div>
        </div>
    );
}

interface IMobileHeaderProps {
    isMenuOpen: boolean;
    toggle: () => void;
}
const MobileHeader = ({ isMenuOpen, toggle }: IMobileHeaderProps) => {
    const authContext = useContext(AuthContext);

    return (
        <div className="df-col">
            <div className="top-bar-mobile df-row-ac">
                <div className="df-row-afs-left">
                    <HamburgerMenu
                        isOpen={isMenuOpen}
                        menuClicked={() => toggle()}
                        color="black"
                        width={18}
                        height={15} />
                </div>
                <div className="user-text-mobile">{authContext.user ? authContext.user.name : ''}</div>
                <div className="df-row-afs-right">
                    <NavLink to={fromSubRoutes.NotificationsRoute}>
                        <MobileNotifications />
                    </NavLink>
                </div>
            </div>
            <DashboardSearchbox className="search-anything" />
        </div>
    );
}