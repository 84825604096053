import React, { useContext } from 'react';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { Overview } from './Overview';
import { Calculated } from './Calculated';
import './Step4.scss';
import { DiscountComponent } from './DiscountComponent';

export const Step4 = () => {
    const context = useContext(TransportSubmitContext);

    const onConfirm = async () => {
        await context.confirm();
    }
    
    if (context.subStep === 0) {
        return <Overview
            onPrevious={() => context.goBackToStep(3)}
            onNext={() => context.nextSubstep()} />
    } else if (context.subStep === 1) {
        return <DiscountComponent
            onPrevious={() => context.previousSubStep()}
            onNext={() => context.calculate()} />
    } 
    else if (context.subStep === 2) {
        return <Calculated
            onPrevious={() => context.previousSubStep()}
            onNext={() => onConfirm()} />
    }
    return <div>nothing found</div>
}