import React, { useContext } from 'react';
import { NotificationsContext } from '../../context/NotificationsContext';
import './MobileNotifications.scss';
import { Label } from 'semantic-ui-react';

export const MobileNotifications = () => {
    const context = useContext(NotificationsContext);

    return (
        <div className="mobile-notifications">
            {context.countUnread.toString()}
            {context.countUnread > 0
                ? <Label circular empty color='red' className="new-notifications-label"></Label>
                : null}
        </div>
    );
}