import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SelectButton.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    selected: boolean;
    text: string;
}

export const SelectButton = ({ text, selected, ...rest }: IProps) => {
    return (
        <div {...rest} className={`df-row-ac select-button${selected ? ' has-selected' : ''}`} >
            <div className="label-text">{text}</div>
            <FontAwesomeIcon className="sb-icon" icon={['fas', selected ? 'check' : 'plus']} />
        </div>
    );
}