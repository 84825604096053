import React, { useState, useEffect } from 'react';
import { IOption } from '../../../infrastructure/Models';
import { Dropdown } from 'semantic-ui-react';
import { Searchbox } from '../search/Searchbox';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { DropDownTrigger } from './DropDownTrigger';
import _ from 'lodash';
import './CustomDropdown.scss';

interface IProps<T> {
    placeholder: string;
    options: IOption<T>[];
    selected: T;
    onSelect: (str: T) => void;
    onClear: () => void;
    clearable?: boolean;
    formatTrigger?: boolean;
    searchable?: boolean;
    stretch?: boolean;
    small?: boolean;
}
export function CustomDropDown<T>({ options, placeholder, selected, onSelect, onClear, clearable = false, formatTrigger = false, searchable = true, stretch = true, small = false }: IProps<T>) {
    // options = _.orderBy(options, t => t.text);
    const locContext = useLocalizationContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<IOption<T>[]>([]);
    const [triggerText, setTriggerText] = useState<string>(placeholder);

    useEffect(() => {
        setFilteredOptions(_.orderBy(options, t => t.text));
        if (selected) {
            const x = options.find(t => t.value === selected);
            if (x) {
                setTriggerText(x.text);
            }
        }
    }, [options, selected]);

    const handleSearchTextChanged = (str: string) => {
        const x = _.orderBy(options, t => t.text).filter(t => t.text.toLowerCase().includes(str.toLowerCase()));
        setFilteredOptions(x);
    }

    const handleSelect = (option: IOption<T>) => {
        onSelect(option.value);
        // console.log('sets closed');
        setIsOpen(false);
    }

    return (
        <Dropdown
            className={`dd-custom${stretch ? ' stretch-hor' : ''}`}
            trigger={
                <DropDownTrigger
                    onClear={() => { setIsOpen(false); onClear(); }}
                    selected={selected ? true : false}
                    text={triggerText}
                    isOpen={isOpen}
                    clickTrigger={() => setIsOpen(!isOpen)}
                    clearable={clearable}
                    formatSelected={formatTrigger}
                    small={small} />
            }
            icon={null}
            onClose={() => { }}
            onOpen={() => { }}
            open={isOpen}
            onBlur={() => setIsOpen(false)}
            scrolling>
            <Dropdown.Menu>
                {searchable ?
                    <div className="df-row-ac sticky-at-top search-box-container">
                        <Searchbox className="dd-search-box" placeholder={`${locContext.search}...`} large onTextChanged={handleSearchTextChanged} onSearch={handleSearchTextChanged} />
                    </div>
                    : null}
                <div className="df-col">
                    {filteredOptions.map(t =>
                        <div key={t.key} className={`simple-list-item${selected === t.value ? ' selected' : ''}`} onClick={() => handleSelect(t)}>{t.text}</div>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
