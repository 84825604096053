import React from 'react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './ErrorScreen.scss';

export const ErrorScreen = () => {
    const locContext = useLocalizationContext();
    return (
        <div className="blank-container">
            <div className="header-text">ERROR</div>
            <div>Whoops, something is wrong...</div>
            <div className="server-error-text">{locContext.serverError}</div>
        </div>
    );
}