import React from 'react';
import { IModalProps } from '../../../context/ModalContext';
import { IRouterProps } from '../../../context/RouterContext';
import { ILoaderProps } from '../../../context/WrapperFullScreenLoadContext';
import { BuildClient, ITransportDetail, ITransportSummary, SaveAsTemplateModel, TransportsClient } from '../../../gen/ApiClients';
import { IDownloadClient } from '../../../hooks/useClient';
import { IStringsWithFormat } from '../../../hooks/useLocalizationContext';
import { SaveAsTemplateForm } from '../../blank/common/SaveAsTemplateForm';
import { BaseConfirmation } from '../../common/modal/BaseConfirmation';
import { showErrorToast, showSuccessToast } from '../../common/toast/ToastNotification';
import { calculateTransportDetailRoute } from '../subRoutes';

export async function toggleIsTemplate(
	transport: ITransportSummary | ITransportDetail,
	client: TransportsClient,
	hitTrigger: () => void,
	modalContext: IModalProps,
	locContext: IStringsWithFormat
) {
	if (transport.isTemplate === true) {
		modalContext.open(
			<BaseConfirmation
				title={locContext.deleteTemplate}
				description={locContext.confirmDeleteTemplate}
				confirmText={locContext.yesDelete}
				cancelText={locContext.cancel}
				confirm={async () => {
					await client.removeTemplate(transport.fileNumber ? transport.fileNumber : '');
					hitTrigger();
					showSuccessToast(locContext.transportNoLongerStoredAsTemplate);
					modalContext.close();
				}}
				cancel={() => modalContext.close()}
			/>
		);
	} else {
		modalContext.open(
			<SaveAsTemplateForm
				cancel={() => modalContext.close()}
				submit={async model => {
					const response = await client.saveAsTemplate(transport.fileNumber ? transport.fileNumber : '', new SaveAsTemplateModel(model));
					if (response.hasError === false) {
						hitTrigger();
						showSuccessToast(locContext.transportStoredAsTemplate);
					} else {
						showErrorToast(response.error ? response.error : 'Error');
					}
					modalContext.close();
				}}
			/>,
			false
		);
	}
}

export async function downloadPod(inst: ITransportSummary | ITransportDetail, client: IDownloadClient, buildClient: BuildClient, loaderContext: ILoaderProps) {
	loaderContext.setLoading(true);
	const baseUrl = await buildClient.url();
	const url = `${baseUrl}/api/transports/${inst.fileNumber}/downloadPod`;
	await client.download(url, `POD_${inst.fileNumber}.pdf`, () => loaderContext.setLoading(false));
}

export function viewTransportDetail(inst: ITransportSummary, routerContext: IRouterProps) {
	routerContext.navigate(calculateTransportDetailRoute(inst));
}
