import React, { useState } from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { Responsive } from 'semantic-ui-react';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { IDepartmentSummary } from '../../../gen/ApiClients';
import vs450 from "./icons/vs-450.png";
import vs800 from "./icons/vs-800.png";
import vs1500 from "./icons/vs-1500.png";
import "./VehicleSizeConfirmation.scss";
import "../../common/modal/BaseConfirmation.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {
    vehicle: IDepartmentSummary;
    confirm: () => void | Promise<any>;
    cancel: () => void;
}

export const VehicleSizeConfirmation = ({ vehicle, confirm, cancel, ...rest }: IProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const locContext = useLocalizationContext();
    const submit = async () => {
        setIsSubmitting(true);
        await confirm();
        setIsSubmitting(false);
    }

    const getVehicleSizeImage = () => {
        switch (vehicle.name) {
            case "450":
                return vs450;
            case "800":
                return vs800;
            case "1500":
                return vs1500;
        }
    }

    return (
        <div className={useCombineClassNames('df-col stretch-ver confirmation-modal', rest)}>
            <div className="df-col confirmation-modal-inner">
                <div className="mc-title">{locContext.dimensionsNotice}</div>
                <div className="mc-description">{locContext.maxSizeWarningText}</div>
                <div className="vs-image-container">
                    <img src={getVehicleSizeImage()} alt="vehicle-size" />
                </div>
                <div className="df-col-ac" style={{ marginBottom: "30px" }}>
                    <div className="vehicle-description">{locContext.max} {vehicle.maxWeight}{locContext.kg}</div>
                    {vehicle.name === '450' && <div className="vehicle-description">{vehicle.maxLength} x {vehicle.maxWidth} x {vehicle.maxHeight}</div>}
                    {vehicle.name !== '450' && <div className="vehicle-description">{vehicle.maxLength} x {vehicle.maxWidth} (1.2*) x {vehicle.maxHeight}</div>}
                    <div className="vehicle-description">({locContext.LxWxH_inMeters})</div>
                    {vehicle.name !== '450' && <div className="vehicle-description">{`* ${locContext.dimensionsBetweenWheelArches}`}</div>}
                </div>
            </div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-e">
                    <div className="df-row-ac">
                        <PrimaryButton disabled={isSubmitting} showLoader onClick={() => submit()}>{locContext.yesIUnderstand}</PrimaryButton>
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-col">
                    <PrimaryButton className="stretch-hor" onClick={() => confirm()}>{locContext.yesIUnderstand}</PrimaryButton>
                </div>
            </Responsive>
        </div>
    );
}