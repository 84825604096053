import React from 'react';
import './ValidGreenIcon.scss';
import { useCombineClassNames } from '../../hooks/useCombineClassNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps extends React.HTMLProps<HTMLDivElement> {

}

export const ValidGreenIcon = ({ ...rest }: IProps) => {
    return (
        <div {...rest} className={useCombineClassNames("df-row-ac jc-c valid-green-icon valid-icon", rest)}>
            <FontAwesomeIcon className="valid-svg-icon" icon={['fas', 'check']} />
        </div>
    );
}