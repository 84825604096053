import React, { useState, useContext, useEffect } from 'react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { useClient } from '../../../hooks/useClient';
import { ITransportFlow, TemplatesClient, TransportFlowClient } from '../../../gen/ApiClients';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { RouterContext } from '../../../context/RouterContext';
import { Card } from 'semantic-ui-react';
import { StopsViewComponent } from '../../blank/common/StopsViewComponent';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as routes from '../../../routes';
import './TemplateDetail.scss';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { tryCatchWithLoading } from '../../../infrastructure/Utils';

export const TemplateDetail = ({ match }: any) => {
    const id = match.params.id;
    const locContext = useLocalizationContext();
    const client = useClient(TemplatesClient);
    const [detail, setDetail] = useState<ITransportFlow>();
    const loaderContext = useContext(WrapperFullScreenLoadContext);
    const routerContext = useContext(RouterContext);
    const flowClient = useClient(TransportFlowClient);
    useContext(DashboardSearchContext).hideAndShowGoBackButton();

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    [id]);

    const load = async () => {
        if (id) {
            const t = await tryCatchWithLoading(client.detail(id), loaderContext.setLoading, locContext.serverError);
            setDetail(t);
        }
    }

    const onStartFromTemplate = async (inst: ITransportFlow) => {
        if (inst.id === undefined){
            return;
        }
        const newId = await tryCatchWithLoading(flowClient.createFromTemplate(inst.id), loaderContext.setLoading, locContext.serverError);
        routerContext.navigate(routes.calculateNewRoute(newId));
    }

    if (detail === undefined) {
        return null;
    }

    return (
        <Card className="template-detail-card df-col">
            <div className="df-row jc-sb">
                <div>
                    <PrimaryButton small onClick={() => onStartFromTemplate(detail)}><FontAwesomeIcon icon={['fas', 'car-side']} /> {locContext.startFromThisTemplate}</PrimaryButton>
                </div>
            </div>
            <div className="label">{locContext.department}</div>
            <div className="info">{detail.vehicleName}</div>
            <div className="label">{locContext.reference}</div>
            <div className="info">{detail.reference}</div>
            <div className="label">{locContext.costCenter}</div>
            <div className="info">{detail.costCenter}</div>
            <StopsViewComponent
                className="calc-stops-view"
                firstStop={detail.firstStop}
                extraStops={detail.extraStops}
                lastStop={detail.lastStop}
                showDividers={false}
                addTopMargin={false}
                hideLastDivider
                formatStopAsLabel={true} />
        </Card>
    );
}