import { IDraftSummary } from './gen/ApiClients';

export const LoginRoute = '/login';
export const ForgotPasswordRoute = '/forgot-password';
export const DashboardRoute = '/';
export const NewTransportRoute = '/blank';
export const CreatedTransportRoute = '/blank/:id';

export const calculateDraftsRoute = (inst: IDraftSummary) => '/blank/' + inst.id;
export const calculateNewRoute = (id: string) => '/blank/' + id;
