import { useContext, useState } from 'react';
import { ModalContext } from '../../../context/ModalContext';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import {
	AddressModel,
	FirstStopModel,
	IAddressModel,
	IFirstStopModel,
	ILoadOperation,
	IStopDateValidation,
	LoadOperation,
	TransportValidateClient,
	ValidateLeadTimeModel,
} from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { tryCatchWithLoading } from '../../../infrastructure/Utils';
import { showErrorToast } from '../../common/toast/ToastNotification';
import { AddressComponent } from '../common/addresses/AddressComponent';
import { DateComponentWithStopDateValidation } from '../common/DateComponentWithStopDateValidation';
import { validateCapacity } from '../common/Utils';
import { BookingOutOfOfficeHoursConfirmation } from './BookingOutOfOfficeHoursConfirmation';
import { LoadOperations } from './LoadOperations';
import './Step2.scss';
import { StopsView } from './StopsView';
import React from 'react';

export const Step2 = () => {
	const validateClient = useClient(TransportValidateClient);
	const locContext = useLocalizationContext();
	const context = useContext(TransportSubmitContext);
	const [model, setModel] = useState<IFirstStopModel | undefined>(context.state.firstStop);
	const [minLeadTime, setMinLeadTime] = useState<Date>();
	const loadContext = useContext(WrapperFullScreenLoadContext);
	const modalContext = useContext(ModalContext);

	const handleSetAddress = async (id: string | undefined, address: IAddressModel | undefined, mustSave: boolean) => {
		const m: IFirstStopModel = { ...model, fromAddressBook: id, address: new AddressModel(address), mustSaveAddress: mustSave };
		setModel(m);
		const r = await validateClient.minimumLeadTime(new FirstStopModel(m));
		setMinLeadTime(r);
		context.nextSubstep();
	};

	const handleSetDate = async (date: Date, specifier: string) => {
		// interceptor for showing modal view
		console.log(date);
		const rOutOfOfficeHours = await validateClient.isOutOfOfficeHours(date);
		if (rOutOfOfficeHours) {
			modalContext.open(
				<BookingOutOfOfficeHoursConfirmation
					confirm={async () => {
						modalContext.close();
						setModel({ ...model, date: date, dateSpecifier: specifier });
						context.nextSubstep();
					}}
					cancel={() => modalContext.close()}
				/>,
				false
			);
		} else {
			setModel({ ...model, date: date, dateSpecifier: specifier });
			context.nextSubstep();
		}
	};

	const handleAddLoadOp = async (loadOp: ILoadOperation) => {
		const x = model && model.loadOperations ? [...model.loadOperations, new LoadOperation(loadOp)] : [new LoadOperation(loadOp)];
		setModel({ ...model, loadOperations: x });
	};

	const handleUpdateLoadOp = async (index: number, loadOp: ILoadOperation) => {
		if (!model || !model.loadOperations) {
			return;
		}
		const x = [...model.loadOperations.slice(0, index), new LoadOperation(loadOp), ...model.loadOperations.slice(index + 1)];
		setModel({ ...model, loadOperations: x });
	};

	const handleDeleteLoadOp = async (index: number) => {
		if (!model || !model.loadOperations) {
			return;
		}
		const x = [...model.loadOperations.slice(0, index), ...model.loadOperations.slice(index + 1)];
		setModel({ ...model, loadOperations: x });
	};

	const setFirstStop = async () => {
		if (model) {
			await context.setFirstStop(model);
			context.nextSubstep();
		}
	};

	const validateLeadTime = async (date: Date, dateSpecifier: string): Promise<IStopDateValidation> => {
		return await validateClient.validateLeadTime(
			new ValidateLeadTimeModel({ date: date, dateSpecifier: dateSpecifier, address: model?.address, fromAddressBookId: model?.fromAddressBook })
		);
	};

	const goToLastStop = async () => {
		if (context.state.id) {
			const r2 = await tryCatchWithLoading(validateClient.validateEmptyCargo(context.state.id), loadContext.setLoading, locContext.serverError);
			if (r2.isValid === false) {
				showErrorToast(locContext.errorEmptyCargo);
				return;
			}
		}
		context.gotoStep3();
	};

	if (context.subStep === 0) {
		return (
			<AddressComponent
				defaultLoadAddress={context.defaultLoadAddress}
				mustSave={model && model.mustSaveAddress !== undefined ? model.mustSaveAddress : false}
				header={`${locContext.load}: ${locContext.addressDetails}`}
				address={model && model.address ? model.address : undefined}
				fromAddressBookId={model && model.fromAddressBook ? model.fromAddressBook : ''}
				submit={handleSetAddress}
				onPrevious={() => context.goBackToStep(1)}
			/>
		);
	} else if (context.subStep === 1 && model && context.state.id && minLeadTime !== undefined) {
		return (
			<DateComponentWithStopDateValidation
				header={`${locContext.load}: ${locContext.selectDateAndTime}`}
				date={model && model.date ? model.date : minLeadTime}
				setTime={true}
				specifier={model && model.dateSpecifier ? model.dateSpecifier : 'After'}
				onSubmit={handleSetDate}
				onPrevious={() => context.previousSubStep()}
				validate={validateLeadTime}
			/>
		);
	} else if (context.subStep === 2 && context.state.id) {
		return (
			<LoadOperations
				loadOperations={model && model.loadOperations ? model.loadOperations : []}
				add={handleAddLoadOp}
				update={handleUpdateLoadOp}
				del={handleDeleteLoadOp}
				onPrevious={() => context.previousSubStep()}
				onNext={setFirstStop}
				validate={loadOp => validateCapacity(validateClient, context.state.id ? context.state.id : '', undefined, model, loadOp)}
			/>
		);
	} else if (context.subStep === 3) {
		return (
			<StopsView
				onPrevious={() => context.previousSubStep()}
				onNext={() => goToLastStop()}
			/>
		);
	} else {
		return (
			<div>
				<div>{`substep: ${context.subStep}`}</div>
				<div>{`min lead time: ${minLeadTime === undefined ? 'undefined' : minLeadTime.toDateString()}`}</div>
			</div>
		);
	}
};
