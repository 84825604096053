import React, { useContext } from 'react';
import { FilterBar } from '../../common/filter/FilterBar';
import { DateFilterItem } from '../../common/filter/DateFilterItem';
import { StringListFilterItem } from '../../common/filter/StringListFilterItem';
import { IDraftsQueryParams } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { FilterDataContext } from '../../../context/FilterDataContext';

interface IProps {
    params: IDraftsQueryParams;
    onFilter: (params: IDraftsQueryParams) => void;
}

function isFiltered(pars: IDraftsQueryParams) {
    if (!pars) {
        return false;
    }
    if (pars.lastModifiedAfterDate || pars.lastModifiedBeforeDate){
        return true;
    }
    return pars.vehicles !== undefined && pars.vehicles.length > 0;
}

export const FilterDrafts = ({ params, onFilter }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(FilterDataContext);
    const handleClear = () => {
        onFilter({ ...params, vehicles: [], lastModifiedAfterDate: undefined, lastModifiedBeforeDate: undefined });
    }

    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac df-col-if-mobile">
                <StringListFilterItem
                    icon={['fas', 'car-side']}
                    label={locContext.department}
                    options={context.allFilterableDepartments}
                    selection={params.vehicles ? params.vehicles : []}
                    onFilter={(val) => onFilter({ ...params, vehicles: val })} />
                <DateFilterItem
                    undefinedLabel={locContext.lastModifiedDate}
                    afterDate={params.lastModifiedAfterDate}
                    beforeDate={params.lastModifiedBeforeDate}
                    onFilter={(val) => onFilter({ ...params, lastModifiedAfterDate: val.after, lastModifiedBeforeDate: val.before })} />
            </div>
        </FilterBar>
    );
}
