import { IStrings } from './IStrings';

export const nl: IStrings = {
    add: `Voeg toe`,
    addExtraPackage: `Voeg extra pakket toe`,
    addPackage: `Voeg pakket toe`,
    address: `Adres`,
    addressAddedToAddressBook: `Adres toegevoegd aan adresboek`,
    addressBook: `Adresboek`,
    addressChanged: `Adres gewijzigd`,
    addressDeleted: `Adres verwijderd`,
    addressDetails: `Adresdetails`,
    addStop: `Voeg een stop toe`,
    addToAddressBook: `Voeg toe aan adresboek`,
    after: `Na`,
    all: `Alles`,
    alsoFillInAHouseNumber: `Vul ook een huisnummer in`,
    at: `Om`,
    available247: `24/7 beschikbaar`,
    backToDashboard: `Terug naar dashboard`,
    backToLogin: `Terug naar login`,
    backToOverview: `Terug naar overzicht`,
    before: `Voor`,
    bookOutsideOfficeHoursContent: `Een boeking ingegeven via MySprint buiten de kantooruren is mogelijk, maar de chauffeur wordt pas ingepland nadat u ons gebeld hebt!`,
    businessUnit: `Vestiging`,
    busyCalculating: `Aan het berekenen…`,
    busySearchingAddress: `Adres zoeken`,
    busyTyping: `Bezig met typen`,
    calculatePrice: `Bereken prijs`,
    calculateVerb: `Berekenen`,
    cancel: `Annuleer`,
    canceled: `Geannuleerd`,
    cargo: `Vracht`,
    change: `Aanpassen`,
    checkDiscountCode: `Controleer code`,
    checkYourData: `Controleer je gegevens`,
    choose: `Kies`,
    chooseAType: `Kies een type`,
    clickHereToDownloadExampleFile: `Klik hier om een voorbeeldfile te downloaden`,
    clickHereToResolve: `Klik hier om de fout op te lossen`,
    client: `Klant`,
    comments: `Opmerkingen`,
    company: `Bedrijf`,
    companyName: `Bedrijfsnaam`,
    concept: `Concept`,
    conceptOptionDescription: `Een concept is een onafgemaakt transport`,
    concepts: `Concepten`,
    confirmAddStopover: `Wil je een tussenstop toevoegen?`,
    confirmDeleteAddress: `Ben je zeker dat je het adres wil verwijderen?`,
    confirmDeleteDraft: `Ben je zeker dat je dit concept wil verwijderen?`,
    confirmDeletePackage: `Ben je zeker dat je dit pakket wil verwijderen?`,
    confirmDeleteStop: `Ben je zeker dat je deze stop wil verwijderen?`,
    confirmDeleteTemplate: `Ben je zeker dat je deze template wil verwijderen?`,
    confirmed: `Bevestigd`,
    confirmedTransports: `Bevestigde transporten`,
    confirmTransport: `Bevestig transport`,
    confirmYourTransport: `Bevestig je transport`,
    contact: `Contact`,
    contactPerson: `Contactpersoon`,
    contactUs: `Contacteer ons.`,
    contentExtraCosts: `Extra kosten zoals tol, brug, tunnel, etc... zullen apart worden \ndoorgerekend indien van toepassing.`,
    contentNoDiscountCode: `Als je geen kortingscode hebt, kan je zo verder gaan.`,
    costCenter: `Kostenplaats`,
    createFromDraft: `Start van dit concept`,
    createNewAddress: `Creëer nieuw adres`,
    dashboard: `Dashboard`,
    date: `Datum`,
    deadFreight: `Foutvracht`,
    delete: `Verwijderen`,
    deleteAddress: `Adres verwijderen`,
    deleteDraft: `Concept verwijderen?`,
    deleteFilter: `Verwijder filter`,
    deletePackage: `Pakket verwijderen`,
    deleteStop: `Stop verwijderen`,
    deleteTemplate: `Template verwijderen`,
    department: `Afdeling`,
    departmentOrBusinessUnit: `Afdeling/Vestiging`,
    description: `Beschrijving`,
    dimensions: `Afmetingen`,
    dimensionsBetweenWheelArches: `Afmetingen tussen wielkasten`,
    dimensionsNotice: `Kennisgeving afmetingen`,
    discountAcknowledged: `Korting: {0} toegekend`,
    discountApplied: `Korting {0} toegekend`,
    discountCode: `Kortingscode`,
    downloadTrackAndTrace: `Download Track & Trace`,
    doYouHaveADiscountCode: `Heb je een kortingscode?`,
    edit: `Pas aan`,
    editAddress: `Pas adres aan`,
    editPackage: `Pas pakket aan`,
    email: `E-mailadres`,
    error: `Fout`,
    errorEmptyCargo: `Geen pakket meer te lossen, voeg een 'te laden' pakket toe in tussenstop.`,
    errorNoCargoOperation: `Selecteer minstens één pakket te laden of te lossen`,
    errorNullValuesAddress: `Het is niet mogelijk een prijs te berekenen via de web-tool voor dit adres.\nGelieve Euro-Sprinters te contacteren.`,
    errorTimingsInYourTransport: `Er is een fout in jouw transportingave`,
    excludingWaitingTimes: `Excl. eventuele wachttijden`,
    export: `Exporteer`,
    extraCosts: `Extra kosten`,
    extraStop: `Extra stop`,
    extraTimeApplicableAtThisPlace: `Extra tijd van toepassing op deze locatie`,
    fileNumber: `Dossiernummer`,
    fillInAddress: `Adres ingeven`,
    fillInAddressOrSelectFromAddressBook: `Vul adres in of selecteer adres van adresboek`,
    fillInQuantity: `Vul aantal in`,
    filterOn: `Filter op`,
    firstStop: `Eerste stop`,
    forgotPassword: `Wachtwoord vergeten?`,
    forgotPasswordContent: `Stuur een mail met je gebruikersnaam\nnaar info@eurosprinters.be.\nNadien krijg je instructies om je wachtwoord te wijzigen.`,
    generalConditions: `Door op bevestig transport te klikken verklaart\nde klant zich akkoord met de algemene voorwaarden van Euro-Sprinters. \nDeze zijn verkrijgbaar op eenvoudig verzoek via info@eurosprinters.be`,
    goToUnload: `Ga naar lossen`,
    height: `Hoogte`,
    heightPerPackage: `Hoogte (per pakket)`,
    hello: `Dag`,
    hours: `Uren`,
    houseNumber: `Huisnummer`,
    houseNumberWasNotFound: `Het huisnummer {0} was niet gevonden`,
    import: `Importeer`,
    importAddressBookContent: `Klik hieronder op -selecteer een file- om de adresboek te importeren.\nDe eerste rij van de file moet de volgende kolommen bevatten:`,
    inProgress: `In Uitvoering`,
    inThisTimeMinutesDelayAreIncludedPt1: `In deze tijd zijn`,
    inThisTimeMinutesDelayAreIncludedPt2: `minuten vertraging inbegrepen`,
    invalidCombinationUsernamePassword: `Ongeldige combinatie gebruikersnaam en wachtwoord`,
    isRead: `Gelezen?`,
    kg: `kg`,
    labelAdditionalInfo: `Extra info (busnr, verdieping, ...)`,
    labelAddressBook: `Adresboek`,
    labelContactPerson: `Persoon ter plaatse`,
    labelStreetNumberCityAndCode: `Adres (straat, huisnummer, stad en postcode)`,
    lastModifiedDate: `Datum laatste wijziging`,
    lastMonth: `Vorige maand`,
    lastStop: `Laatste stop`,
    lastWeek: `Vorige week`,
    lastYear: `Vorig jaar`,
    leaveThisPageDontSaveAsDraft: `Ga weg van deze pagina`,
    length: `Lengte`,
    lengthPerPackage: `Lengte (per pakket)`,
    load: `Laden`,
    loadAndUnloadAddress: `Laad- en losadres`,
    loadAndUnloadAddressPlusStops: `Laad- en losadres (+stops)`,
    loadNewPackage: `Laad nieuwe pakket`,
    login: `Aanmelden`,
    loginWithUsernamePassword: `Meld je aan met je gebruikersnaam en wachtwoord`,
    logOut: `Uitloggen`,
    LxWxH: `LxBxH`,
    LxWxH_inMeters: `L x B x H in meter`,
    markAsRead: `Markeer als gelezen`,
    max: `max`,
    max6stops: `Je kan tot max. 6 stops ingeven.\nWil je meer toevoegen? Contacteer dan de klantendienst.`,
    maxLengthValidation: `Lengte is beperkt tot max. {0} karakters`,
    maxSizeWarningText: `Opgelet, de afmetingen van een pakket mogen de maximale afmetingen van het voertuig niet overschrijden.`,
    moreInfo: `Meer info`,
    newAddress: `Nieuw adres`,
    newTemplate: `Nieuw template`,
    newTransport: `Nieuw transport`,
    newTransportOptionDescription: `Begin van nul en maak het transport die jouw noden vervult`,
    next: `Volgende`,
    no: `Nee`,
    noAccount: `Geen account`,
    noAddressFound: `Geen adres gevonden`,
    none: `Niets`,
    noNewNotifications: `Er zijn geen nieuwe meldingen`,
    notification: `Melding`,
    notificationMarkedAsRead: `Melding gemarkeerd als gelezen`,
    notificationMarkedAsUnread: `Melding gemarkeerd als ongelezen`,
    notifications: `Meldingen`,
    notStoredAsTemplate: `Niet opgeslaged als template`,
    noValidDiscountCode: `Deze kortingscode is niet geldig`,
    okUnderstood: `Oké, begrepen!`,
    optional: `Optioneel`,
    or: `Of`,
    overview: `Overzicht`,
    packageInCargo: `Pakket in cargo`,
    packageType: `Pakkettype`,
    pagingTable: `{0} {1} tot {2} van {3}`,
    password: `Wachtwoord`,
    phoneNumber: `Telefoonnummer`,
    placeholderAdditionalInfo: `Extra info`,
    placeholderAddress: `Geef een adres in`,
    placeholderCompany: `Geef de naam van het bedrijf`,
    placeholderConcept: `Selecteer concept`,
    placeholderContactPerson: `Geef de naam van de contactpersoon`,
    placeholderDimensions: `Afmetingen (LxBxH in m, per pakket)`,
    placeholderHouseNumber: `Vul het huisnummer hier in als het hierboven niet wordt gevonden`,
    placeholderPackageType: `Selecteer een pakkettype`,
    placeholderPhonenumber: `Geef een telefoonnummer`,
    placeholderTemplate: `Selecteer template`,
    placeholderWeightInKgPerPackage: `Gewicht (in kg, per pakket)`,
    previous: `Vorige`,
    price: `Prijs`,
    processed: `Uitgevoerd`,
    quantity: `Aantal`,
    quantityMustBeAnInteger: `Aantal moet een geheel getal zijn`,
    quantityMustBeMinimalOne: `Aantal moet minstens 1 zijn`,
    questionHowDoYouWantToStart: `Hoe wil je van start gaan?`,
    read: `Gelezen`,
    reference: `Referentie`,
    route: `Route`,
    saveAsConcept: `Opslaan als concept`,
    saveAsTemplate: `Opslaan als template`,
    savedAsConcept: `Opgeslagen als concept`,
    search: `Zoek`,
    searchOn: `Zoek op`,
    searchOnTitleOrDescription: `Zoek op titel of beschrijving`,
    select: `Selecteer`,
    selectAClient: `Selecteer een klant`,
    selectADate: `Selecteer een datum`,
    selectADepartmentOrBusinessUnit: `Selecteer een afdeling/vestiging`,
    selectAFile: `Selecteer een file`,
    selectAnAddress: `Selecteer een adres`,
    selectAPackage: `Selecteer een pakkettype`,
    selectAQuantity: `Selecteer een aantal`,
    selectDateAndTime: `Selecteer datum & tijd`,
    selectFromAddressBook: `Selecteer uit adresboek`,
    selectTime: `Selecteer uur`,
    serverError: `Een ongekende fout is opgetreden op de server.\nGelieve het opnieuw te proberen.\nAls de fout blijft aanhouden, gelieve Euro-Sprinters te contacteren.`,
    settings: `Instellingen`,
    shutDown: `Afsluiten`,
    startFromAConcept: `Start van een concept`,
    startFromATemplate: `Start van een template`,
    startFromThisTemplate: `Start van deze template`,
    status: `Status`,
    stayOnThisPage: `Blijf op deze pagina`,
    stop: `Stop`,
    stopover: `Tussenstop`,
    stops: `Stops`,
    storedAsTemplate: `Opgeslagen als template`,
    subDepartment: `Subafdeling`,
    subTotal: `Subtotaal`,
    successfullyImportedAddresses: `Adressen geïmporteerd`,
    successfullyLoggedOut: `Succesvol uitgelogd!`,
    template: `Template`,
    templateOptionDescription: `Gebruik een template om snel een transport in te geven.`,
    templates: `Templates`,
    thisMonth: `Deze maand`,
    thisWeek: `Deze week`,
    thisYear: `Dit jaar`,
    time: `Tijd`,
    title: `Titel`,
    today: `Vandaag`,
    total: `Totaal`,
    transport: `Transport`,
    transportNoLongerStoredAsTemplate: `Transport niet langer opgeslagen als template`,
    transportNotSavedAsDraft: `Transport niet opgeslagen als concept`,
    transportNotSavedAsDraftDescription: `Deze ingave is niet opgeslagen.\nAls je verdergaat kan je dit transport niet meer aanpassen.\nWat wil je doen?`,
    transports: `Transporten`,
    transportStoredAsTemplate: `Transport opgeslagen als template`,
    unload: `Lossen`,
    unloadPackage: `Los een pakket`,
    unread: `Niet gelezen`,
    upForToday: `dit gebeurt er vandaag!`,
    useDefaultLoadAddress: `Gebruik standaard laadadres`,
    userName: `Gebruikersnaam`,
    validateAddress: `Vul een adres in`,
    validateCompanyName: `Vul de naam van het bedrijf in`,
    validateContactPerson: `Vul contactpersoon in`,
    validateCostCenter: `Vul kostenplaats in`,
    validateDescription: `Vul een beschrijving in`,
    validateEmail: `Vul jouw email in`,
    validateHeightPerPackage: `Vul een hoogte (per pakket) in`,
    validateHouseNumberIfNotFoundAbove: `Vul een huisnummer in als het hierboven niet wordt gevonden`,
    validateLengthPerPackage: `Vul een lengte (per pakket) in`,
    validateLoginCompanyName: `Vul jouw bedrijfsnaam in`,
    validateMaxQuantity: `Er kan maar max. {0} stuk(s) gelost worden`,
    validatePassword: `Vul jouw wachtwoord in`,
    validateReference: `Vul referentie in`,
    validateTime: `Selecteer een tijdstip`,
    validateTimeSelector: `Selecteer vanaf/om/na`,
    validateUsername: `Vul jouw gebruikersnaam in`,
    validateWeightPerPackage: `Vul het gewicht (per pakket) in`,
    validateWidthPerPackage: `Vul een breedte (per pakket) in`,
    vehicle: `Voertuig`,
    viewAddressBook: `Bekijk adresboek`,
    viewAll: `Bekijk alle`,
    viewAllTransports: `Bekijk alle transporten`,
    viewDrafts: `Bekijk concepten`,
    warningBookOutsideOfficeHours: `Opgelet: u wilt boeken buiten de kantooruren. Bel ons!`,
    warningTransportAbroad: `U heeft een rit naar het buitenland geboekt! Let op: er komen kosten bij.`,
    warningTransportAbroadContent1: `In het door u geboekte transport bevindt zich minstens één halte in het buitenland. De prijs is zichtbaar via MySprint is exclusief deze extra's.`,
    warningTransportAbroadContent2: `Mogelijke extra kosten die er bovenop komen:`,
    warningTransportAbroadContent3: `Tol\nBrug\nOverzet\n…`,
    webCompany: `Web Company`,
    webPassword: `Web Paswoord`,
    webUser: `Web Gebruiker`,
    welcomeToMySprint: `Welkom bij MySprint`,
    width: `Breedte`,
    widthPerPackage: `Breedte (per pakket)`,
    yes: `Ja`,
    yesDelete: `Ja, verwijder`,
    yesIUnderstand: `Ja, ik heb het begrepen`,
    yesterday: `Gisteren`,
    youDontHaveAnyAddresses: `Je hebt nog geen adressen in je adresboek. Voeg er toe!`,
    youHaveNumberOfDrafts: `Je hebt {0} transport(-en) als concept. Werk ze verder af.`,
    youHaveNumberOfRoutesToday: `Er staan vandaag {0} ritten gepland.`,
    yourOrderNumber: `Jouw ordernummer`,
    yourTransportInputIsSuccess: `Jouw transportingave is gelukt!`,
}
