import React, { useContext } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import Moment from 'react-moment';
import { BulletinList } from './bulletins/BulletinList';
import { AuthContext } from '../../../context/AuthContext';
import './InfoTodoCard.scss';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';

interface IProps{
    className?: string;
}

export const InfoTodoCard = ({...rest}: IProps) => {
    const authContext = useContext(AuthContext);
    const locContext = useLocalizationContext();

    return (
        <Card className={useCombineClassNames('df-col', rest)}>
            <div className="title">
                {locContext.hello} {authContext.user ? authContext.user.name : '?'}, {locContext.upForToday}
            </div>
            <div className="date">
                <Moment format="ddd D MMM YYYY" date={new Date()}></Moment>
            </div>
            <Divider />
            <BulletinList />
        </Card>
    );
}