import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import { AddressModel, IAddressDetail, IAddressModel, ISuggestion } from '../../../gen/ApiClients';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { clearNullOrUndefinedValues } from '../../../infrastructure/Utils';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { AddressTypeAheadField } from '../../common/forms/AddressTypeAheadField';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { TextareaField } from '../../common/forms/TextareaField';
import { TextInputField } from '../../common/forms/TextInputField';
import { createValidateSchemaWithHouseNumber, createValidateSchemaWithoutHouseNumber, setFields } from './AddressUtils';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    confirm: (inst: IAddressModel) => Promise<any>;
    cancel: () => void;
    address?: IAddressDetail | undefined;
}
export const AddressForm = ({ confirm, cancel, address, ...rest }: IProps) => {
    const className = useCombineClassNames('df-col stretch-ver confirmation-modal', rest);
    const [hasSelAddressError, setHasSelAddressError] = useState<boolean>(false);
    const [isValidatedOnce, setIsValidatedOnce] = useState<boolean>(false);
    const [selAddress, setSelAddress] = useState<ISuggestion | undefined>(undefined);
    const locContext = useLocalizationContext();
    const isNew = address ? false : true;
    const initValues = clearNullOrUndefinedValues<IAddressModel>(address, AddressModel);

    const validateSchema = selAddress && !selAddress.isExact
        ? createValidateSchemaWithHouseNumber(locContext)
        : createValidateSchemaWithoutHouseNumber(locContext);

    useEffect(() => {
        if (address) {
            setIsValidatedOnce(true);
            setSelAddress(address);
        }
    }, [address]);

    const onClickSubmit = async (props: FormikProps<IAddressModel>, e: any) => {
        e.preventDefault();
        props.setSubmitting(true);
        setFieldTouched<IAddressModel>('street', props);
        setFieldTouched<IAddressModel>('company', props);
        setFieldTouched<IAddressModel>('contact', props);
        setFieldTouched<IAddressModel>('additionalInfo', props);
        setFieldTouched<IAddressModel>('notFoundHouseNumber', props);
        if (selAddress) {
            setIsValidatedOnce(true);
        }
        if (!selAddress) {
            setHasSelAddressError(true);
        } else if (props.isValid) {
            const x = setFields(props.values, selAddress);
            await confirm(x);
        }
        props.setSubmitting(false);
    }

    return (
        <div>
            <Formik<IAddressModel>
                initialValues={initValues}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={() => { }}>
                {(props) => (
                    <Form>
                        <div className={className}>
                            <div className="mc-title">{isNew ? locContext.newAddress : locContext.editAddress}</div>
                            <div className="mc-description">
                                <TextInputField<IAddressModel> label={locContext.companyName} xName="company" placeholder={locContext.placeholderCompany} />
                                <AddressTypeAheadField<IAddressModel>
                                    label={locContext.labelStreetNumberCityAndCode}
                                    xName="street"
                                    placeholder={locContext.placeholderAddress}
                                    warningMessage={locContext.alsoFillInAHouseNumber}
                                    value={selAddress}
                                    onValueChanged={(val) => {
                                        if (val) {
                                            setHasSelAddressError(false);
                                        }
                                        setSelAddress(val);
                                    }}
                                    overrideError={hasSelAddressError ? locContext.selectAnAddress : undefined} />
                                {selAddress && !selAddress.isExact && isValidatedOnce ?
                                    <TextInputField<IAddressModel> label={locContext.houseNumber} xName="notFoundHouseNumber" />
                                    : null}
                                <TextInputField<IAddressModel> label={locContext.labelAdditionalInfo} xName="additionalInfo" placeholder={locContext.placeholderAdditionalInfo} optional />
                                <TextInputField<IAddressModel> label={locContext.labelContactPerson} xName="contact" placeholder={locContext.placeholderContactPerson} />
                                <TextInputField<IAddressModel> label={locContext.phoneNumber} xName="phoneNumber" placeholder={locContext.placeholderPhonenumber} optional />
                                <TextareaField<IAddressModel> label={locContext.comments} xName="comment" optional />
                            </div>
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => cancel()}>{locContext.cancel}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{!isNew ? locContext.change : locContext.createNewAddress}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col">
                                    <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{!isNew ? locContext.change : locContext.createNewAddress}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => cancel()}>{locContext.cancel}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
