import React, { useContext, useEffect, useState } from 'react';
import './BulletinList.scss';
import { useCombineClassNames } from '../../../../hooks/useCombineClassNames';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { DashboardClient, IStartupHelp } from '../../../../gen/ApiClients';
import { useClient } from '../../../../hooks/useClient';
import { Bulletin } from './Bulletin';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { ModalContext } from '../../../../context/ModalContext';
import { BaseConfirmation } from '../../../common/modal/BaseConfirmation';
import { RouterContext } from '../../../../context/RouterContext';
import * as routes from '../../../dashboard/subRoutes';

interface IProps extends React.HTMLProps<HTMLDivElement> {
}

export const BulletinList = ({ ...rest }: IProps) => {
    const locContext = useLocalizationContext();
    const className = useCombineClassNames('bulletins df-col', rest);
    const context = useContext(NotificationsContext);
    const client = useClient(DashboardClient);
    const [startupHelp, setStartupHelp] = useState<IStartupHelp>({});
    const modalContext = useContext(ModalContext);
    const routerContext = useContext(RouterContext);

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    [])

    const load = async () => {
        const t = await client.help();
        setStartupHelp(t);
    }

    const handleViewTransportsToday = () => {
        modalContext.open(
            <BaseConfirmation
                title={locContext.transports}
                description={locContext.formatString('youHaveNumberOfRoutesToday', startupHelp.countTransportsToday)}
                confirmText={locContext.viewAllTransports}
                cancel={() => modalContext.close()}
                confirm={() => {
                    modalContext.close();
                    routerContext.navigate(routes.TranportsRoute)
                }}
            />, true);
    }
    const handleViewConcepts = () => {
        modalContext.open(
            <BaseConfirmation
                title={locContext.concepts}
                description={locContext.formatString('youHaveNumberOfDrafts', startupHelp.countDrafts)}
                confirmText={locContext.viewDrafts}
                cancel={() => modalContext.close()}
                confirm={() => {
                    modalContext.close();
                    routerContext.navigate(routes.DraftsRoute)
                }}
            />, true);
    }
    const handleAddAddresses = () => {
        modalContext.open(
            <BaseConfirmation
                title={locContext.addressBook}
                description={locContext.youDontHaveAnyAddresses}
                confirmText={locContext.viewAddressBook}
                cancel={() => modalContext.close()}
                confirm={() => {
                    modalContext.close();
                    routerContext.navigate(routes.AddressesRoute)
                }}
            />, true);
    }

    return (
        <div {...rest} className={className}>
            {context.unread.length === 0 && startupHelp.countTransportsToday === 0 && startupHelp.countDrafts === 0 && startupHelp.hasAddressesDefined === true ? <div>{locContext.noNewNotifications}</div> : null}
            {context.unread.map(t =>
                <Bulletin className={t.isHighPriority ? 'font-bold font-error' : 'font-bold'} key={t.id} title={t.title} onClickMoreInfo={() => context.show(t)} />
            )}
            {startupHelp.countTransportsToday && startupHelp.countTransportsToday > 0
                ? <Bulletin title={locContext.formatString('youHaveNumberOfRoutesToday', startupHelp.countTransportsToday)} onClickMoreInfo={handleViewTransportsToday}></Bulletin>
                : null}
            {startupHelp.countDrafts && startupHelp.countDrafts > 0
                ? <Bulletin title={locContext.formatString('youHaveNumberOfDrafts', startupHelp.countDrafts)} onClickMoreInfo={handleViewConcepts}></Bulletin>
                : null}
            {startupHelp.hasAddressesDefined === false
                ? <Bulletin title={locContext.youDontHaveAnyAddresses} onClickMoreInfo={handleAddAddresses}></Bulletin>
                : null}
        </div>
    );
}