import React, { useContext } from 'react';
import { TransportSubmitContext } from '../../context/TransportSubmitContext';
import { Step1 } from './step1/Step1';
import { Step2 } from './step2/Step2';
import { Step3 } from './step3/Step3';
import { Step4 } from './step4/Step4';
import { Step5 } from './step5/Step5';

export const InnerWrapper = ({ match }: any) => {
    const context = useContext(TransportSubmitContext);
    context.onMountOrRefresh(match.params.id);
    const render = () => {
        if (context.step === 1) {
            return <Step1 />
        } else if (context.step === 2) {
            return <Step2 />
        } else if (context.step === 3) {
            return <Step3 />
        } else if (context.step === 4) {
            return <Step4 />
        } else if (context.step === 5) {
            return <Step5 />
        }
        return <div></div>
    }
    return (render());
}