import React from 'react';
import { Loader } from './Loader';
import { CenterEveryting } from './CenterEverything';
import './FullScreenLoader.scss';

interface IProps {
    dim?: boolean;
}

export const FullScreenLoader = ({ dim = false }: IProps) => {
    return (
        <CenterEveryting className={`full-screen-loader${dim === true ? ' dim-background' : ''}`}>
            <Loader />
        </CenterEveryting>
    );
}