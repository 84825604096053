import React from 'react';
import './ForgotPassword.scss';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { Responsive } from 'semantic-ui-react';
import { Logo } from '../common/Logo';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';

export const ForgotPassword = () => {
    const locContext = useLocalizationContext();

    return (
        <div className="df-col full-page-sheet">
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-sb fp-header">
                    <Logo />
                    <div>
                        <Link to={routes.LoginRoute}>
                            <TextLinkButton underline>{locContext.backToLogin}</TextLinkButton>
                        </Link>
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-row-ac jc-c fp-header">
                    <Logo />
                </div>
            </Responsive>
            <div className="df-col-ac fp-body">
                <span className="welcome-text">{locContext.welcomeToMySprint}</span>
                <span className="sub-welcome-text">{locContext.forgotPassword}</span>
                <div className="fp-content">{locContext.forgotPasswordContent}</div>
                <Responsive {...Responsive.onlyMobile}>
                    <div className="df-row-ac fp-mobile-go-back-button">
                        <Link to={routes.LoginRoute}>
                            <TextLinkButton underline>{locContext.backToLogin}</TextLinkButton>
                        </Link>
                    </div>
                </Responsive>
            </div>
        </div>
    );
}