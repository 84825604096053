import { useContext, useEffect, useState } from 'react';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { AddressesQueryParamsContext } from '../../../context/AddressesQueryParamsContext';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { ModalContext } from '../../../context/ModalContext';
import { RouterContext } from '../../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import {
	AddressModel,
	AddressesClient,
	AddressesQueryParams,
	BuildClient,
	IAddressSummary,
	IAddressesQueryParams,
	IAddressSummaryQueryResult,
} from '../../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { useTrigger } from '../../../hooks/useTrigger';
import { tryCatch, tryCatchWithLoading } from '../../../infrastructure/Utils';
import { MoreInfo } from '../../common/MoreInfo';
import { IconButton } from '../../common/buttons/IconButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { BaseConfirmation } from '../../common/modal/BaseConfirmation';
import { BaseTableSortAndPage } from '../../common/table/BaseTableSortAndPage';
import { PagingFooter } from '../../common/table/PagingFooter';
import { createEmptyHeader, createHeader, createSortableHeader } from '../../common/table/TableUtils';
import { showSuccessToast } from '../../common/toast/ToastNotification';
import { AddressForm } from './AddressForm';
import './Addresses.scss';
import { AddressesFilter } from './AddressesFilter';
import { ImportFileForm } from './ImportFileForm';
import React from 'react';

export const Addresses = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const locContext = useLocalizationContext();
	const [trigger, hitTrigger] = useTrigger();
	const client = useClient(AddressesClient);
	const [queryResult, setQueryResult] = useState<IAddressSummaryQueryResult>();
	const { params, setParams } = useContext(AddressesQueryParamsContext);
	const modalContext = useContext(ModalContext);
	const searchContext = useContext(DashboardSearchContext);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const routerContext = useContext(RouterContext);
	const downloadClient = useDownloadClient(routerContext);
	searchContext.init(
		`${locContext.searchOn.toLowerCase()} ${locContext.address.toLowerCase()}, ${locContext.company.toLowerCase()} ${locContext.or.toLowerCase()} ${locContext.labelContactPerson.toLowerCase()}`,
		params.searchString,
		(str: string) => {
			setParams({ ...params, searchString: str, pageIndex: 1 });
		}
	);
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new AddressesQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError));
	};

	const headers = [
		createSortableHeader<IAddressSummary>(locContext.address, 'formatted', t => renderAddress(t)),
		createSortableHeader<IAddressSummary>(locContext.company, 'company', t => t.company),
		createSortableHeader<IAddressSummary>(locContext.contactPerson, 'contact', t => t.contact),
		createHeader<IAddressSummary>(locContext.phoneNumber, t => t.phoneNumber),
		createHeader<IAddressSummary>(locContext.comments, t => t.comment),
		createEmptyHeader<IAddressSummary>('actions', t => renderActions(t), true),
	];

	const createNew = () => {
		modalContext.open(
			<AddressForm
				cancel={() => modalContext.close()}
				confirm={async address => {
					await tryCatch(client.create(new AddressModel(address)), locContext.serverError);
					hitTrigger();
					showSuccessToast(locContext.addressAddedToAddressBook);
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const edit = async (inst: IAddressSummary) => {
		if (!inst.id) {
			return;
		}
		const detail = await client.detail(inst.id);
		modalContext.open(
			<AddressForm
				address={detail}
				cancel={() => modalContext.close()}
				confirm={async address => {
					if (inst.id) {
						await tryCatch(client.update(inst.id, new AddressModel(address)), locContext.serverError);
						hitTrigger();
						showSuccessToast(locContext.addressChanged);
					}
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const del = (inst: IAddressSummary) => {
		modalContext.open(
			<BaseConfirmation
				title={locContext.deleteAddress}
				description={locContext.confirmDeleteAddress}
				confirmText={locContext.yesDelete}
				cancelText={locContext.cancel}
				confirm={async () => {
					if (inst.id) {
						await tryCatch(client.delete(inst.id), locContext.serverError);
						hitTrigger();
						showSuccessToast(locContext.addressDeleted);
					}
					modalContext.close();
				}}
				cancel={() => modalContext.close()}
			/>
		);
	};

	function renderActions(inst: IAddressSummary) {
		return (
			<div className='df-row-ac jc-e actions-buttons'>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'edit']}
					onClick={() => edit(inst)}
					popupText={locContext.editAddress}
				/>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'trash-alt']}
					onClick={() => del(inst)}
					popupText={locContext.deleteAddress}
				/>
			</div>
		);
	}

	function renderAddress(inst: IAddressSummary) {
		return (
			<div className='df-row-ac'>
				<div>{inst.formatted}</div>
				{inst.additionalInfo ? <MoreInfo info={inst.additionalInfo} /> : null}
				{inst.notFoundHouseNumber ? <MoreInfo info={locContext.formatString('houseNumberWasNotFound', inst.notFoundHouseNumber)} /> : null}
			</div>
		);
	}

	const exportAddresses = async () => {
		wrapperLoaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		let url_ = `${baseUrl}/api/addresses/export?`;
		if (params.searchString !== undefined) url_ += 'searchString=' + encodeURIComponent('' + params.searchString) + '&';
		if (params.companies !== undefined)
			params.companies &&
				params.companies.forEach(item => {
					url_ += 'companies=' + encodeURIComponent('' + item) + '&';
				});
		if (params.contacts !== undefined)
			params.contacts &&
				params.contacts.forEach(item => {
					url_ += 'contacts=' + encodeURIComponent('' + item) + '&';
				});
		url_ = url_.replace(/[?&]$/, '');
		await downloadClient.download(url_, `addresses_export.xlsx`, () => wrapperLoaderContext.setLoading(false));
	};

	const importFile = () => {
		modalContext.open(
			<ImportFileForm
				onClose={() => {
					modalContext.close();
					hitTrigger();
				}}
			/>,
			true
		);
	};

	return (
		<Card className='base-addresses-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb header'>
					<div className=''>
						<PrimaryButton
							small
							onClick={createNew}>
							+ {locContext.newAddress}
						</PrimaryButton>
					</div>
					<Responsive minWidth={Responsive.onlyTablet.minWidth}>
						<div className='df-row-ac'>
							<PrimaryButton
								small
								outline
								className='import-button'
								onClick={() => importFile()}>
								{locContext.import}
							</PrimaryButton>
							<PrimaryButton
								small
								outline
								className='export-button'
								onClick={() => exportAddresses()}>
								{locContext.export}
							</PrimaryButton>
						</div>
					</Responsive>
					<Responsive {...Responsive.onlyMobile}>
						<PrimaryButton
							small
							outline
							className='filter-button'
							onClick={() => setIsFilterOpen(!isFilterOpen)}>
							{locContext.filterOn}
						</PrimaryButton>
					</Responsive>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<AddressesFilter
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
								trigger={trigger}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<AddressesFilter
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
						trigger={trigger}
					/>
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<BaseTableSortAndPage<IAddressSummary, IAddressesQueryParams>
						params={params}
						onSortOrPage={t => setParams(t)}
						queryResult={queryResult}
						canSort={true}
						headers={headers}
						uniqueIdentifier='id'
						singleIdentifier={locContext.address}
					/>
				</Responsive>
				<Responsive {...Responsive.onlyMobile}>
					{queryResult && queryResult.values ? (
						<div className='df-col'>
							{queryResult.values.map(t => (
								<div
									className='df-col'
									key={t.formatted}>
									<div className='mobile-single-draft padding-10px df-row'>
										<div
											className='fg1 df-col'
											onClick={() => edit(t)}>
											<div className='info-bold'>{t.company}</div>
											<div className='info'>{t.formatted}</div>
											<div className='info'>{t.contact}</div>
										</div>
										<div>
											<IconButton
												color='gray'
												size='large'
												icon={['far', 'trash-alt']}
												onClick={() => del(t)}
											/>
										</div>
									</div>
									<Divider className='no-margin' />
								</div>
							))}
							<PagingFooter
								className='padding-10px'
								singleIdentifier={locContext.address}
								qr={queryResult ? queryResult : {}}
								onNext={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex + 1 : 1 })}
								onPrevious={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex - 1 : 1 })}
							/>
						</div>
					) : null}
				</Responsive>
			</div>
		</Card>
	);
};
