import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { FilterDataContext } from '../../../context/FilterDataContext';
import { TransportStatus, ITransportsQueryParams } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { IOption } from '../../../infrastructure/Models';
import { enumToOptions } from '../../../infrastructure/Utils';
import { DateFilterItem } from '../../common/filter/DateFilterItem';
import { EnumListFilterItem } from '../../common/filter/EnumListFilterItem';
import { FilterBar } from '../../common/filter/FilterBar';
import { ListFilterItem } from '../../common/filter/ListFilterItem';
import { StringListFilterItem } from '../../common/filter/StringListFilterItem';

interface IProps {
	params: ITransportsQueryParams;
	onFilter: (params: ITransportsQueryParams) => void;
}

function isFiltered(pars: ITransportsQueryParams) {
	if (!pars) {
		return false;
	}
	if (pars.statuses && pars.statuses.length > 0) {
		return true;
	}
	if (pars.afterDate || pars.beforeDate) {
		return true;
	}
	if (pars.departments && pars.departments.length > 0) {
		return true;
	}
	if (pars.subDepartments && pars.subDepartments.length > 0) {
		return true;
	}
	if (pars.contactIds && pars.contactIds.length > 0) {
		return true;
	}
	return false;
}

export const TransportsFilter = ({ params, onFilter }: IProps) => {
	const authContext = useContext(AuthContext);
	const locContext = useLocalizationContext();
	const context = useContext(FilterDataContext);
	const handleClear = () => {
		onFilter({ ...params, statuses: [], departments: [], subDepartments: [], afterDate: undefined, beforeDate: undefined, contactIds: [] });
	};
	const translations = new Map<TransportStatus, string>([
		[TransportStatus.Confirmed, locContext.confirmed],
		[TransportStatus.InProgress, locContext.inProgress],
		[TransportStatus.Processed, locContext.processed],
		[TransportStatus.Canceled, locContext.canceled],
		[TransportStatus.DeadFreight, locContext.deadFreight],
	]);
	const options = enumToOptions<string>(TransportStatus, translations);

	return (
		<FilterBar
			onClear={() => handleClear()}
			isFiltered={isFiltered(params)}>
			<div className='df-row-ac df-col-if-mobile'>
				<EnumListFilterItem
					icon={['fas', 'info']}
					label={locContext.status}
					options={options}
					selection={params.statuses ? params.statuses : []}
					onFilter={sel => onFilter({ ...params, statuses: sel })}
				/>
				<StringListFilterItem
					icon={['fas', 'car-side']}
					label={locContext.department}
					options={context.allFilterableDepartments}
					selection={params.departments ? params.departments : []}
					onFilter={val => onFilter({ ...params, departments: val })}
				/>
				<StringListFilterItem
					icon={['fas', 'receipt']}
					label={locContext.subDepartment}
					options={context.allFilterableSubDepartments}
					selection={params.subDepartments ? params.subDepartments : []}
					onFilter={val => onFilter({ ...params, subDepartments: val })}
				/>
				{authContext && authContext.user && authContext.user.canViewTransportsOfAllUsers === true ? (
					<ListFilterItem<string>
						icon={['fas', 'users']}
						label={locContext.contactPerson}
						options={context.allFilterableContacts.map<IOption<string>>(t => ({
							key: t.id ? t.id : '',
							value: t.id ? t.id : '',
							text: t.value ? t.value : '',
						}))}
						selection={params.contactIds ? params.contactIds : []}
						onFilter={val => onFilter({ ...params, contactIds: val })}
					/>
				) : null}
				<DateFilterItem
					undefinedLabel={locContext.date}
					afterDate={params.afterDate}
					beforeDate={params.beforeDate}
					onFilter={val => onFilter({ ...params, afterDate: val.after, beforeDate: val.before })}
				/>
			</div>
		</FilterBar>
	);
};
