import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { IconButton } from '../buttons/IconButton';
import './FilterBar.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    onClear: () => void;
    isFiltered: boolean;
}

export const FilterBar = ({ children, onClear, isFiltered, ...rest }: IProps) => {
    const locContext = useLocalizationContext();
    return (
        <div {...rest} className={useCombineClassNames("df-row-ac filter-bar", rest)} >
            <div className="filter-on-text hide-if-mobile">{locContext.filterOn}:</div>
            <div className="fg1">
                {children}
            </div>
            {isFiltered ?
                <div className="df-row-ab filter-clear-container" onClick={onClear}>
                    <IconButton
                        className="filter-clear-icon"
                        icon={['fas', 'times']} />
                    <div>{locContext.deleteFilter}</div>
                </div>
                : null}
        </div>
    );
}