import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { ConfirmedTransportsQueryParamsContext } from '../../../context/ConfirmedTransportsQueryParamsContext';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { ModalContext } from '../../../context/ModalContext';
import { RouterContext } from '../../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { BuildClient, ConfirmedTransportsQueryParams, ITransportSummary, TransportsClient } from '../../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { useTrigger } from '../../../hooks/useTrigger';
import { ISortProps } from '../../../infrastructure/Models';
import { empty, nameof, toDefaultFormat, tryCatchWithLoading } from '../../../infrastructure/Utils';
import * as routes from '../../../routes';
import { IconButton } from '../../common/buttons/IconButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { ToggleIconButton } from '../../common/buttons/ToggleIconButton';
import { BaseTableSort } from '../../common/table/BaseTableSort';
import { createEmptyHeader, createHeader } from '../../common/table/TableUtils';
import { TranportsRoute } from '../subRoutes';
import { MobileSingleTransport } from '../transports/Transports';
import { downloadPod, toggleIsTemplate, viewTransportDetail } from '../transports/TransportUtils';
import { InfoTodoCard } from './InfoTodoCard';
import './Overview.scss';

const initialSort: ISortProps = {
	sortProperty: nameof<ITransportSummary>('date'),
	sortDirection: 'descending',
};

export const Overview = () => {
	const modalContext = useContext(ModalContext);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const locContext = useLocalizationContext();
	const routerContext = useContext(RouterContext);
	const [transports, setTransports] = useState<ITransportSummary[]>([]);
	const client = useClient(TransportsClient);
	const { params, setParams } = useContext(ConfirmedTransportsQueryParamsContext);
	const downloadClient = useDownloadClient(routerContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(DashboardSearchContext).init(`${locContext.searchOn} ${locContext.fileNumber}...`, params.searchString, st =>
		setParams({ ...params, searchString: st })
	);
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			loadTransports();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const loadTransports = async () => {
		const t = await tryCatchWithLoading(client.confirmed(new ConfirmedTransportsQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError);
		setTransports(t);
	};

	const headers = [
		createHeader<ITransportSummary>(locContext.fileNumber, t => t.fileNumber),
		createHeader<ITransportSummary>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
		createHeader<ITransportSummary>(locContext.reference, t => t.reference),
		createHeader<ITransportSummary>(locContext.department, t => t.department),
		createHeader<ITransportSummary>(locContext.price, t => <div>&euro; {t.amount}</div>),
		createHeader<ITransportSummary>(locContext.date, t => toDefaultFormat(t.date)),
		createHeader<ITransportSummary>(locContext.status, t => t.status),
		createEmptyHeader<ITransportSummary>('actions', t => renderActionsButtons(t), undefined, true),
	];

	function renderActionsButtons(inst: ITransportSummary) {
		return (
			<div className='df-row-ac'>
				<ToggleIconButton
					size='large'
					trueIcon={['fas', 'star']}
					falseIcon={['far', 'star']}
					checked={inst.isTemplate === true}
					color={inst.isTemplate ? 'warning' : 'gray'}
					toggle={() => toggleIsTemplate(inst, client, hitTrigger, modalContext, locContext)}
				/>
				<IconButton
					size='large'
					icon={['fas', 'file-signature']}
					className='download-button'
					color={inst.isTrackTraceAvailable === true ? 'success' : 'gray'}
					// popupText={locContext.downloadTrackAndTrace}
					onClick={e => downloadPod(inst, downloadClient, buildClient, wrapperLoaderContext)}
					disabled={inst.isTrackTraceAvailable === false}
				/>
			</div>
		);
	}

	return (
		<div className='df-col overview-container'>
			<div className='df-row df-col-reverse-if-mobile'>
				<InfoTodoCard className='info-card' />
				{/* <WeatherCard className="weather-card" /> */}
			</div>
			<Card className='confirmed-transports-card'>
				<div className='df-col stretch-ver'>
					<div className='df-row-ac df-col-if-mobile jc-sb transports-header'>
						<div className='confirmed-transports-title'>{locContext.confirmedTransports}</div>
						<div className='df-row-ac jc-sb buttons-container'>
							<Link
								to={routes.NewTransportRoute}
								className='new-transport-link'>
								<PrimaryButton small>+ {locContext.newTransport}</PrimaryButton>
							</Link>
							<Link to={TranportsRoute}>
								<PrimaryButton
									small
									outline>
									{locContext.viewAll}
								</PrimaryButton>
							</Link>
						</div>
					</div>
					<Responsive {...Responsive.onlyMobile}>
						{transports.map(t => (
							<div
								className='df-col'
								key={t.fileNumber}>
								<MobileSingleTransport
									transport={t}
									toggleTemplate={e => toggleIsTemplate(t, client, hitTrigger, modalContext, locContext)}
									viewDetail={() => viewTransportDetail(t, routerContext)}
								/>
								<Divider className='no-margin' />
							</div>
						))}
					</Responsive>
					<Responsive minWidth={Responsive.onlyTablet.minWidth}>
						<BaseTableSort<ITransportSummary, ISortProps>
							values={transports}
							onSort={empty}
							fallbackSortProps={initialSort}
							uniqueIdentifier='fileNumber'
							headers={headers}
							canSort={false}
							params={{}}
							onClickRow={inst => viewTransportDetail(inst, routerContext)}
						/>
					</Responsive>
				</div>
			</Card>
		</div>
	);
};
