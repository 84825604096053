import React, { useContext } from 'react';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { IStopView } from '../../../gen/ApiClients';
import { ExtraStop } from './extraStop/ExtraStop';
import { StopsViewComponent } from '../common/StopsViewComponent';
import { BaseConfirmation } from '../../common/modal/BaseConfirmation';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { TransportSubmitModalContext } from '../../../context/TransportSubmitModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showErrorToast } from '../../common/toast/ToastNotification';
import './StopsView.scss';

interface IProps {
    onPrevious: () => void;
    onNext: () => void;
}

export const StopsView = ({ onPrevious, onNext }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportSubmitContext);
    const modalContext = useContext(TransportSubmitModalContext);

    const onAddNew = () => {
        if (context.state.extraStops !== undefined && context.state.extraStops.length >= 6) {
            showErrorToast(locContext.max6stops);
            return;
        }
        modalContext.open(<ExtraStop
            transportId={context.state.id ? context.state.id : ''}
            onCancel={() => modalContext.close()}
            onSubmit={async (model) => {
                await context.addExtraStop(model);
                modalContext.close();
            }} />, false);
    }
    const onEdit = (stop: IStopView) => {
        // geen index nodig -> heeft een id!
        modalContext.open(<ExtraStop
            transportId={context.state.id ? context.state.id : ''}
            stopId={stop.id}
            stop={stop}
            onCancel={() => modalContext.close()}
            onSubmit={async (model) => {
                await context.editExtraStop(stop.id ? stop.id : '', model);
                modalContext.close();
            }} />, false);
    }
    const onDelete = (stop: IStopView) => {
        // geen index nodig -> heeft een id!
        modalContext.open(
            <BaseConfirmation
                title={locContext.deleteStop}
                description={locContext.confirmDeleteStop}
                confirmText={locContext.yesDelete}
                cancelText={locContext.cancel}
                confirm={async () => {
                    await context.deleteExtraStop(stop.id ? stop.id : '');
                    modalContext.close();
                }}
                cancel={() => modalContext.close()}
            />
            , false);
    }

    return (
        <div className="blank-container">
            <div className="header-text">{locContext.stopover}: {locContext.confirmAddStopover}</div>
            <StopsViewComponent
                firstStop={context.state.firstStop}
                extraStops={context.state.extraStops}
                lastStop={context.state.lastStop}
                onEdit={onEdit}
                onDelete={onDelete}
                showDividers
                addTopMargin={false}
                formatStopAsLabel={false} />
            <PrimaryButton className="extra-stop-button" outline rectanglePadding onClick={() => onAddNew()}>+ {locContext.addStop}</PrimaryButton>
            {/* <TextLinkButton className="font-bold" onClick={() => onAddNew()}>+ {locContext.addStop}</TextLinkButton> */}
            <div className="df-row max-stops-container">
                <FontAwesomeIcon icon={['fas', 'info-circle']} className="max-stops-icon" />
                <div className="max-stops">
                    {locContext.max6stops}
                </div>
            </div>
            <div className="df-row-ac jc-c blank-container-buttons">
                <div className="df-row-ac">
                    <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                    <PrimaryButton rectanglePadding onClick={onNext}>{locContext.goToUnload}</PrimaryButton>
                </div>
            </div>
        </div>
    );
}

