import React from 'react';
import { IFieldGroupProps, FieldGroup } from './FieldGroup';
import { Field } from 'formik';
import { enumToOptions } from '../../../infrastructure/Utils';
import { CustomDropDown } from '../dropdown/CustomDropdown';
import { DefaultTimeInput } from '../fields/DefaultTimeInput';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { DateSpecifier } from '../../../gen/ApiClients';
import './SelectTimeWithSpecifier.scss';

interface IProps<T> extends IFieldGroupProps<T> {
	specifierName: keyof T;
}

export function SelectTimeWithSpecifier<T>({ ...props }: IProps<T>) {
	const locContext = useLocalizationContext();
	const setValue = (fieldProps: any, val: string) => {
		fieldProps.form.setFieldValue(fieldProps.field.name, val, true);
		fieldProps.form.setFieldTouched(fieldProps.field.name, true, true);
	};
	const setEnum = (fieldProps: any, val: number | undefined) => {
		fieldProps.form.setFieldValue(fieldProps.field.name, val, true);
		fieldProps.form.setFieldTouched(fieldProps.field.name, true, true);
	};

	const translations = new Map<DateSpecifier, string>([
		[DateSpecifier.At, locContext.at],
		[DateSpecifier.Before, locContext.before],
		[DateSpecifier.After, locContext.after],
	]);
	const options = enumToOptions<number>(DateSpecifier, translations);

	return (
		<FieldGroup
			{...props}
			xNames={[props.xName, props.specifierName]}>
			<div className='df-row-ac select-time-with-specifier'>
				<Field
					name={props.specifierName}
					component={(fieldProps: any) => (
						<CustomDropDown<number>
							placeholder={locContext.choose}
							options={options}
							selected={fieldProps.form.values[fieldProps.field.name]}
							onSelect={val => setEnum(fieldProps, val)}
							onClear={() => setEnum(fieldProps, undefined)}
							searchable={false}
							stretch={false}
						/>
					)}
				/>
				<Field
					name={props.xName}
					component={(fieldProps: any) => (
						<DefaultTimeInput
							placeholder={locContext.selectTime}
							className='time-picker'
							value={fieldProps.form.values[fieldProps.field.name]}
							onValueChanged={val => setValue(fieldProps, val)}
						/>
					)}
				/>
			</div>
		</FieldGroup>
	);
}
