import React, { useContext, useEffect, useState } from 'react';
import { FullScreenLoader } from '../components/common/loaders/FullScreenLoader';
import { BaseConfirmation } from '../components/common/modal/BaseConfirmation';
import { showSuccessToast } from '../components/common/toast/ToastNotification';
import {
	ContactsClient,
	DraftsClient,
	ExtraStopModel,
	FirstStopModel,
	IAddressModel,
	ICalculateResult,
	IConfirmResult,
	IDepartmentSummary,
	IExtraStopModel,
	IFirstStopModel,
	ILanguageItemDescriptions,
	ILastStopModel,
	IGuidNamedEntity,
	IReferenceInfo,
	ISaveAsDraftModel,
	ISaveAsTemplateModel,
	IStep1Model,
	ITransportDetail,
	ITransportFlow,
	LastStopModel,
	SaveAsDraftModel,
	SaveAsTemplateModel,
	Step1Model,
	TemplatesClient,
	TransportFlowClient,
	TransportsClient,
	TypesClient,
} from '../gen/ApiClients';
import { useClient } from '../hooks/useClient';
import { useLocalizationContext } from '../hooks/useLocalizationContext';
import { empty, tryCatch, tryCatchWithLoading } from '../infrastructure/Utils';
import * as routes from '../routes';
import { ModalContext } from './ModalContext';
import { RouterContext } from './RouterContext';

interface IProps {
	step: number;
	progress: number;
	subStep: number;
	nextSubstep: () => void;
	previousSubStep: () => void;
	drafts: IGuidNamedEntity[];
	templates: IGuidNamedEntity[];
	init: () => void;
	create: () => void;
	setStep1: (model: IStep1Model) => Promise<void>;
	setFirstStop: (model: IFirstStopModel) => void;
	addExtraStop: (model: IExtraStopModel) => void;
	editExtraStop: (stopId: string, model: IExtraStopModel) => void;
	deleteExtraStop: (stopId: string) => void;
	gotoStep3: () => void;
	setLastStop: (model: ILastStopModel) => void;
	calculate: () => void;
	confirm: () => void;
	cancel: () => void;
	onMountOrRefresh: (id: string) => void;
	goBackToStep: (step: number) => void;
	goBackToStartOfStep: (step: number) => void;
	defaultLoadAddress: IAddressModel;
	state: ITransportFlow;
	calculatedResult: ICalculateResult | undefined;
	confirmResult: IConfirmResult | undefined;
	packages: ILanguageItemDescriptions[];
	referenceInfo: IReferenceInfo | undefined;
	selectableDepartments: IDepartmentSummary[] | undefined;
	loadDetail: (fileNumber: string) => Promise<ITransportDetail>;
	saveAsDraft: (model: ISaveAsDraftModel) => void;
	saveAsTemplate: (model: ISaveAsTemplateModel) => void;
	startFromTemplate: (id: string) => void;
	isSubmitting: boolean;
}

const fallback: IProps = {
	step: 0,
	progress: 0,
	subStep: 0,
	nextSubstep: empty,
	previousSubStep: empty,
	drafts: [],
	templates: [],
	init: empty,
	create: empty,
	setStep1: async () => {},
	setFirstStop: empty,
	addExtraStop: empty,
	editExtraStop: empty,
	deleteExtraStop: empty,
	gotoStep3: empty,
	setLastStop: empty,
	calculate: empty,
	confirm: empty,
	cancel: empty,
	onMountOrRefresh: empty,
	goBackToStep: empty,
	goBackToStartOfStep: empty,
	state: {},
	calculatedResult: {},
	confirmResult: undefined,
	packages: [],
	defaultLoadAddress: {},
	referenceInfo: {},
	selectableDepartments: [],
	loadDetail: emptyPromise,
	saveAsDraft: empty,
	saveAsTemplate: empty,
	startFromTemplate: empty,
	isSubmitting: false,
};

async function emptyPromise(): Promise<any> {
	return {};
}

export const TransportSubmitContext = React.createContext<IProps>(fallback);

const mapSubSteps = new Map<number, number>([
	[0, 0],
	[1, 0],
	[2, 3],
	[3, 1],
	[4, 2],
	[5, 0],
]);

export const TransportSubmitProvider = ({ children }: any) => {
	const [isLoading, localSetIsLoading] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [step, setStep] = useState<number>(0);
	const [subStep, setSubStep] = useState<number>(0);
	const [progress, setProgress] = useState<number>(0);
	const [drafts, setDrafts] = useState<IGuidNamedEntity[]>([]);
	const [templates, setTemplates] = useState<IGuidNamedEntity[]>([]);
	const [defaultLoadAddress, setDefaultLoadAddress] = useState<IAddressModel>({});
	const [packages, setPackages] = useState<ILanguageItemDescriptions[]>([]);
	const [id, setId] = useState<string>('');
	const [state, setBlankResult] = useState<ITransportFlow>({});
	const [calculateResult, setCalculateResult] = useState<ICalculateResult | undefined>(undefined);
	const [confirmResult, setConfirmResult] = useState<IConfirmResult | undefined>(undefined);
	const [departments, setDepartments] = useState<IDepartmentSummary[]>();
	const [referenceInfo, setReferenceInfo] = useState<IReferenceInfo>();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const transportsClient = useClient(TransportsClient);
	const client = useClient(TransportFlowClient);
	const draftsClient = useClient(DraftsClient);
	const templatesClient = useClient(TemplatesClient);
	const typesClient = useClient(TypesClient);
	const contactsClient = useClient(ContactsClient);
	const routerContext = useContext(RouterContext);
	const modalContext = useContext(ModalContext);
	const locContext = useLocalizationContext();

	// useEffect(() => {
	//     load();
	// }, []);

	// eslint-disable-next-line
	history.pushState(null, '', location.href);

	useEffect(() => {
		window.onpopstate = () => {
			// eslint-disable-next-line
			history.go(1);
		};
		return function cleanup() {
			window.onpopstate = () => {};
		};
	}, []);

	useEffect(() => {
		const x = mapSubSteps.get(step);
		if (x !== undefined) {
			if (x === 0) {
				setProgress(0);
			} else {
				setProgress((subStep / x) * 100);
			}
		}
	}, [subStep, step]);

	const onNextSubstep = () => {
		setSubStep(subStep + 1);
	};

	const onPreviousSubstep = () => {
		setSubStep(subStep - 1);
	};

	let timer: NodeJS.Timeout;
	const setIsLoading = (isLoading: boolean) => {
		if (isLoading) {
			setIsSubmitting(true);
			timer = setTimeout(() => {
				localSetIsLoading(true);
			}, 1000);
		} else {
			clearTimeout(timer);
			localSetIsLoading(false);
			setIsSubmitting(false);
		}
	};

	const load = async () => {
		setIsLoaded(true);
		setIsLoading(true);
		setDrafts(await tryCatch(draftsClient.summaries(), locContext.serverError));
		setTemplates(await tryCatch(templatesClient.summaries(), locContext.serverError));
		setDefaultLoadAddress(await tryCatch(contactsClient.defaultLoadAddress(), locContext.serverError));
		setPackages(await tryCatch(typesClient.packages(), locContext.serverError));
		setDepartments(await tryCatch(contactsClient.selectableDepartments(), locContext.serverError));
		setReferenceInfo(await tryCatch(contactsClient.referenceInfo(), locContext.serverError));
		// setIsLoaded(true);
		setIsLoading(false);
	};

	const loadState = async () => {
		setBlankResult(await tryCatchWithLoading(client.get(id), setIsLoading, locContext.serverError)); //500 error
	};

	const onCreate = async () => {
		const id = await tryCatchWithLoading(client.create(), setIsLoading, locContext.serverError);
		setId(id);
		routerContext.navigate(routes.calculateNewRoute(id));
		setBlankResult({});
		setStep(1);
		setSubStep(0);
	};

	const onSetStep1 = async (model: IStep1Model) => {
		await tryCatchWithLoading(client.setStep1(id, new Step1Model(model)), setIsLoading, locContext.serverError);
		await loadState();
		setStep(2);
		setSubStep(0);
	};

	const onSetFirstStop = async (model: IFirstStopModel) => {
		await tryCatchWithLoading(client.addFirstStop(id, new FirstStopModel(model)), setIsLoading, locContext.serverError);
		await loadState();
	};

	const onAddExtraStop = async (model: IExtraStopModel) => {
		await tryCatchWithLoading(client.addStop(id, new ExtraStopModel(model)), setIsLoading, locContext.serverError);
		await loadState();
	};

	const onEditExtraStop = async (stopId: string, model: IExtraStopModel) => {
		await tryCatchWithLoading(client.editStop(id, stopId, new ExtraStopModel(model)), setIsLoading, locContext.serverError);
		await loadState();
	};

	const onDeleteExtraStop = async (stopId: string) => {
		await tryCatchWithLoading(client.deleteStop(id, stopId), setIsLoading, locContext.serverError);
		await loadState();
	};

	const onGotoLastStop = () => {
		setStep(3);
		setSubStep(0);
	};

	const onSetLastStop = async (model: ILastStopModel) => {
		await tryCatchWithLoading(client.addLastStop(id, new LastStopModel(model)), setIsLoading, locContext.serverError);
		await loadState();
		setStep(4);
		setSubStep(0);
	};

	const onCalculate = async () => {
		setCalculateResult(undefined);
		await loadState();
		const result = await tryCatchWithLoading(client.calculate(id), setIsLoading, locContext.serverError);
		setCalculateResult(result);
		onNextSubstep();
	};

	const onConfirm = async () => {
		if (!calculateResult || !calculateResult.fileId) {
			setIsLoading(false);
			return;
		}
		const result = await tryCatchWithLoading(client.confirm(id), setIsLoading, locContext.serverError);
		setConfirmResult(result);
		setStep(5);
		setSubStep(0);
	};

	const onGoBackToStep = (step: number) => {
		setStep(step);
		const x = mapSubSteps.get(step);
		setSubStep(x ? x : 0);
	};
	const onGoBackToStartOfStep = (step: number) => {
		setStep(step);
		setSubStep(0);
	};

	const onCancel = () => {
		if (state.isSavedAsDraft === true) {
			routerContext.navigate(routes.DashboardRoute);
		} else {
			modalContext.open(
				<BaseConfirmation
					title={locContext.transportNotSavedAsDraft}
					description={locContext.transportNotSavedAsDraftDescription}
					confirmText={locContext.stayOnThisPage}
					cancelText={locContext.leaveThisPageDontSaveAsDraft}
					confirm={() => modalContext.close()}
					cancel={() => {
						modalContext.close();
						routerContext.navigate(routes.DashboardRoute);
					}}
				/>,
				false
			);
		}
	};

	const onInit = async () => {
		setStep(0);
		setSubStep(0);
		setCalculateResult(undefined);
		setConfirmResult(undefined);
		if (!isLoaded) {
			await load();
		}
		// cannot do setState({}) <= results in memory leak...
	};

	const onMountOrRefresh = async (paramsId: string) => {
		// happens always, but also in case of browser refresh
		if (!paramsId) {
			console.log('no id provided');
			return;
		}
		setId(paramsId);
		if (!isLoaded) {
			await load();
		}
		if (id !== paramsId) {
			const x = await tryCatchWithLoading(client.get(paramsId), setIsLoading, locContext.serverError);
			setBlankResult(x);
			if (x.hasNullDates === true) {
				setStep(2);
				setSubStep(0);
			} else if (x.lastStop) {
				setStep(4);
			} else if (x.extraStops && x.extraStops.length > 0) {
				setStep(2);
				const x = mapSubSteps.get(2);
				setSubStep(x ? x : 0);
			} else if (x.firstStop) {
				setStep(2);
				const x = mapSubSteps.get(2);
				setSubStep(x ? x : 0);
			} else if (x.vehicleName) {
				setStep(2);
			} else {
				setStep(1);
			}
		}
	};

	const loadDetail = async (fileNumber: string) => {
		return await tryCatchWithLoading(transportsClient.detail(fileNumber), setIsLoading, locContext.serverError);
	};

	const onSaveAsDraft = async (model: ISaveAsDraftModel) => {
		if (id === undefined) {
			return;
		}
		await tryCatchWithLoading(draftsClient.saveAsDraft(new SaveAsDraftModel({ ...model, transportFlowId: id })), setIsLoading, locContext.serverError);
		showSuccessToast(locContext.savedAsConcept);
		await loadState();
	};

	const onSaveAsTemplate = async (model: ISaveAsTemplateModel) => {
		if (id === undefined) {
			return;
		}
		await tryCatchWithLoading(client.saveAsTemplate(id, new SaveAsTemplateModel(model)), setIsLoading, locContext.serverError);
		showSuccessToast(locContext.storedAsTemplate);
		await loadState();
	};

	const onStartFromTemplate = async (templateId: string) => {
		const newId = await tryCatchWithLoading(client.createFromTemplate(templateId), setIsLoading, locContext.serverError);
		routerContext.navigate(routes.calculateNewRoute(newId));
	};

	return (
		<TransportSubmitContext.Provider
			value={{
				step: step,
				subStep: subStep,
				nextSubstep: onNextSubstep,
				previousSubStep: onPreviousSubstep,
				progress: progress,
				drafts: drafts,
				templates: templates,
				init: onInit,
				create: onCreate,
				setStep1: onSetStep1,
				setFirstStop: onSetFirstStop,
				addExtraStop: onAddExtraStop,
				editExtraStop: onEditExtraStop,
				deleteExtraStop: onDeleteExtraStop,
				gotoStep3: onGotoLastStop,
				setLastStop: onSetLastStop,
				calculate: onCalculate,
				confirm: onConfirm,
				cancel: onCancel,
				onMountOrRefresh: onMountOrRefresh,
				goBackToStep: onGoBackToStep,
				goBackToStartOfStep: onGoBackToStartOfStep,
				state: state,
				calculatedResult: calculateResult,
				confirmResult: confirmResult,
				packages: packages,
				defaultLoadAddress: defaultLoadAddress,
				referenceInfo: referenceInfo,
				selectableDepartments: departments,
				loadDetail: loadDetail,
				saveAsDraft: onSaveAsDraft,
				saveAsTemplate: onSaveAsTemplate,
				startFromTemplate: onStartFromTemplate,
				isSubmitting: isSubmitting,
			}}>
			<div className='pos-rel stretch-ver stretch-hor'>
				{isLoading ? <FullScreenLoader dim /> : null}
				{children}
			</div>
		</TransportSubmitContext.Provider>
	);
};
