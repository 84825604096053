import _ from 'lodash';
import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useEnterKeyDown } from '../../../hooks/useEnterKeyDown';
import { IOption } from '../../../infrastructure/Models';
import { IconButton } from '../buttons/IconButton';
import './CustomDropdown.scss';

interface IProps {
    placeholder: string;
    options: IOption<string>[];
    selected: string;
    onSelect: (str: string) => void;
    onClear: () => void;
    onAdd?: (str: string) => void;
}

export function AutoCompleteDropdown({ options, placeholder, selected, onSelect, onClear, onAdd }: IProps) {
    options = _.orderBy(options, t => t.text);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<IOption<string>[]>(options);
    const [typedText, setTypedText] = useState<string>(selected);

    const handleSelect = (option: IOption<string>) => {
        onSelect(option.value);
        setIsOpen(false);
    }

    const handleTyping = (str: string) => {
        setIsOpen(true);
        setTypedText(str);
        setFilteredOptions(options.filter(t => t.text.toLowerCase().includes(str.toLowerCase())));
    }

    const handleKeyDown = useEnterKeyDown(() => {
        if (onAdd) {
            onAdd(typedText);
            setIsOpen(false);
        }
    });

    const handleBlur = () => {
        if (onAdd){
            if (selected !== typedText){
                onAdd(typedText);
            }
        } else if (selected !== typedText) {
            setTypedText(selected);
            setFilteredOptions(options);
        }
    }

    return (
        <Dropdown
            className="dd-custom stretch-hor"
            trigger={
                <div className={`df-row-ac dd-trigger${isOpen ? ' is-open' : ''}`}>
                    {onAdd && selected !== typedText ?
                        <IconButton size="large" color="success" icon={['fas', 'plus-circle']} onClick={() => onAdd(typedText)} className="add-button" />
                        : null}
                    <input type="text" value={typedText} onChange={(e) => handleTyping(e.target.value)} placeholder={placeholder} onBlur={() => handleBlur()} onFocus={() => setIsOpen(true)} onKeyDown={handleKeyDown} />
                    {selected ?
                        <IconButton size="large" className="ddt-icon" icon={['fas', 'times']} onClick={() => { onClear(); setTypedText(''); }} />
                        :
                        <IconButton className="ddt-icon" icon={['fas', isOpen ? 'chevron-up' : 'chevron-down']} onClick={() => setIsOpen(!isOpen)} />
                    }
                </div>
            }
            icon={null}
            onClose={() => { }}
            onOpen={() => { }}
            open={isOpen}
            onBlur={() => setIsOpen(false)}
            scrolling>
            <Dropdown.Menu>
                <div className="df-col">
                    {filteredOptions.map(t =>
                        <div key={t.key} className={`simple-list-item${selected === t.value ? ' selected' : ''}`} onClick={(e) => { e.preventDefault(); handleSelect(t);}}>{t.text}</div>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
