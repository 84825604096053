import React, { useState, useEffect, useContext } from 'react';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { useClient } from '../../../hooks/useClient';
import { createHeader, createSortableHeader, createEmptyHeader } from '../../common/table/TableUtils';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { BaseTableSortAndPage } from '../../common/table/BaseTableSortAndPage';
import {
	TemplatesClient,
	ITemplateSummaryQueryResult,
	TemplatesQueryParams,
	ITemplatesQueryParams,
	ITemplateSummary,
	TransportFlowClient,
} from '../../../gen/ApiClients';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { TemplatesQueryParamsContext } from '../../../context/TemplatesQueryParamsContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { toDefaultFormat, tryCatchWithLoading, tryCatch } from '../../../infrastructure/Utils';
import { IconButton } from '../../common/buttons/IconButton';
import { RouterContext } from '../../../context/RouterContext';
import { FilterTemplates } from './FilterTemplates';
import { ModalContext } from '../../../context/ModalContext';
import { BaseConfirmation } from '../../common/modal/BaseConfirmation';
import { useTrigger } from '../../../hooks/useTrigger';
import * as routes from '../../../routes';
import * as subRoutes from '../subRoutes';
import './Templates.scss';
import { PagingFooter } from '../../common/table/PagingFooter';

export const Templates = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const flowClient = useClient(TransportFlowClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const locContext = useLocalizationContext();
	const client = useClient(TemplatesClient);
	const [queryResult, setQueryResult] = useState<ITemplateSummaryQueryResult>();
	const routerContext = useContext(RouterContext);
	const { params, setParams } = useContext(TemplatesQueryParamsContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(DashboardSearchContext).init(`${locContext.searchOn} ${locContext.reference}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		const t = await tryCatchWithLoading(client.query(new TemplatesQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError);
		setQueryResult(t);
	};
	const viewDetail = (inst: ITemplateSummary) => {
		routerContext.navigate(subRoutes.calculateTemplateDetailRoute(inst));
	};

	const onStartFromTemplate = async (inst: ITemplateSummary) => {
		if (inst.id === undefined) {
			return;
		}
		const newId = await tryCatchWithLoading(flowClient.createFromTemplate(inst.id), wrapperLoaderContext.setLoading, locContext.serverError);
		routerContext.navigate(routes.calculateNewRoute(newId));
	};

	function renderActions(inst: ITemplateSummary) {
		return (
			<div className='df-row-ac action-buttons-container jc-e'>
				<IconButton
					color='success'
					size='large'
					icon={['fas', 'car-side']}
					onClick={() => onStartFromTemplate(inst)}
					popupText={locContext.startFromThisTemplate}
				/>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'trash-alt']}
					onClick={() => del(inst)}
					popupText={locContext.deleteTemplate}
				/>
			</div>
		);
	}

	const headers = [
		createSortableHeader<ITemplateSummary>(locContext.date, 'createdDate', t => toDefaultFormat(t.createdDate)),
		createHeader<ITemplateSummary>(locContext.reference, t => t.reference),
		createHeader<ITemplateSummary>(locContext.vehicle, t => t.vehicleName),
		createHeader<ITemplateSummary>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
		createEmptyHeader<ITemplateSummary>('actions', t => renderActions(t), false, true),
	];

	const del = (inst: ITemplateSummary) => {
		modalContext.open(
			<BaseConfirmation
				title={locContext.deleteTemplate}
				description={locContext.confirmDeleteTemplate}
				confirmText={locContext.yesDelete}
				cancelText={locContext.cancel}
				confirm={async () => {
					if (inst.id) {
						await tryCatch(client.delete(inst.id), locContext.serverError);
						hitTrigger();
					}
					modalContext.close();
				}}
				cancel={() => modalContext.close()}
			/>
		);
	};

	return (
		<Card className='base-templates-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb'>
					<div className='title'>{locContext.templates}</div>
					<Responsive
						{...Responsive.onlyMobile}
						className='padding-10px'>
						<PrimaryButton
							small
							outline
							className='filter-button'
							onClick={() => setIsFilterOpen(!isFilterOpen)}>
							{locContext.filterOn}
						</PrimaryButton>
					</Responsive>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<FilterTemplates
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<FilterTemplates
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
					/>
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<BaseTableSortAndPage<ITemplateSummary, ITemplatesQueryParams>
						params={params}
						onSortOrPage={t => setParams(t)}
						queryResult={queryResult}
						canSort={true}
						headers={headers}
						uniqueIdentifier='id'
						singleIdentifier={locContext.template}
						onClickRow={t => viewDetail(t)}
					/>
				</Responsive>
				<Responsive {...Responsive.onlyMobile}>
					{queryResult && queryResult.values ? (
						<div className='df-col'>
							{queryResult.values.map(t => (
								<div
									className='df-col'
									key={t.reference}>
									<div className='mobile-single-template padding-10px df-row'>
										<div
											className='fg1 df-col'
											onClick={() => viewDetail(t)}>
											<div className='reference'>{t.reference}</div>
											<div className='info'>{t.formattedAddress}</div>
											<div className='info'>{t.vehicleName}</div>
										</div>
										<div>{renderActions(t)}</div>
									</div>
									<Divider className='no-margin' />
								</div>
							))}
							<PagingFooter
								className='padding-10px'
								singleIdentifier={locContext.template}
								qr={queryResult ? queryResult : {}}
								onNext={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex + 1 : 1 })}
								onPrevious={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex - 1 : 1 })}
							/>
						</div>
					) : null}
				</Responsive>
			</div>
		</Card>
	);
};
