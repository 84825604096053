import React from 'react';
import { INotificationsQueryParams } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { FilterBar } from '../../common/filter/FilterBar';
import { BooleanFilterItem } from '../../common/filter/BooleanFilterItem';
import { DateFilterItem } from '../../common/filter/DateFilterItem';

interface IProps {
    params: INotificationsQueryParams;
    onFilter: (params: INotificationsQueryParams) => void;
}

function isFiltered(pars: INotificationsQueryParams): boolean {
    if (pars === undefined){
        return false;
    } else if (pars.afterDate || pars.beforeDate) {
        return true;
    } else {
        return (pars.isRead === true || pars.isRead === false)
    }
}

export const FilterNotifications = ({ params, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const handleClear = () => {
        onFilter({ ...params, isRead: undefined, afterDate: undefined, beforeDate: undefined });
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac df-col-if-mobile">
                <BooleanFilterItem
                    icon={['fas', 'star']}
                    label={locContext.isRead}
                    value={params.isRead}
                    trueText={locContext.read}
                    falseText={locContext.unread}
                    onFilter={(val) => onFilter({ ...params, isRead: val })} />
                <DateFilterItem
                    undefinedLabel={locContext.date}
                    afterDate={params.afterDate}
                    beforeDate={params.beforeDate}
                    onFilter={(val) => onFilter({ ...params, afterDate: val.after, beforeDate: val.before })} />
            </div>
        </FilterBar>
    );
}