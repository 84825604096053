import React, { useContext } from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextInputField } from '../../common/forms/TextInputField';
import { useClient } from '../../../hooks/useClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransportFlowClient } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { setFieldError } from '../../common/forms/FormsUtils';
import { showErrorToast, showSuccessToast } from '../../common/toast/ToastNotification';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import * as yup from 'yup';
import './DiscountComponent.scss';

interface IModel {
    code: string | undefined;
}

const schema = yup.object<IModel>().shape<IModel>({
    code: yup.string().notRequired(),
});

interface IProps {
    onPrevious: () => void;
    onNext: () => void;
}

export const DiscountComponent = ({ onPrevious, onNext }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportSubmitContext);
    const client = useClient(TransportFlowClient);
    const initialValue: IModel = { code: '' };
    const handleSubmit = async (model: IModel, actions: FormikActions<IModel>) => {
        const result = await client.validateDiscount(context.state.id ? context.state.id : '', model.code);
        actions.setSubmitting(false);
        actions.resetForm();
        if (result.hasError) {
            showErrorToast(result.errorMessage ? result.errorMessage : locContext.noValidDiscountCode);
            setFieldError<IModel>('code', result.errorMessage ? result.errorMessage : locContext.noValidDiscountCode, actions);
        } else {
            showSuccessToast(locContext.formatString("discountApplied", model.code));
            onNext();
        }
    }

    return (
        <div className="blank-container">
            <div className="header-text">{locContext.doYouHaveADiscountCode}</div>
            <Formik<IModel>
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="pos-rel">
                            {context.state && context.state.discountCode ?
                                <div className="df-row-ac selected-discount jc-c">
                                    <FontAwesomeIcon icon={['fas', 'check-circle']} />
                                    <div className="sd-description">{locContext.formatString('discountAcknowledged', context.state.discountCode)}</div>
                                </div>
                                : <TextInputField<IModel> xName="code" label={locContext.discountCode} />}                            
                            {context.state && context.state.discountCode ?
                                null
                                :
                                <div>{locContext.contentNoDiscountCode}</div>
                            }
                            <div className="df-row-ac jc-c blank-container-buttons">
                                <div className="df-row-ac">
                                    <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                                    {props.values.code ?
                                        <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit">{locContext.checkDiscountCode}</PrimaryButton>
                                        :
                                        <PrimaryButton rectanglePadding disabled={context.isSubmitting} showLoader onClick={onNext}>{locContext.next}</PrimaryButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}