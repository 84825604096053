import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { ModalContext } from '../../../context/ModalContext';
import { RouterContext } from '../../../context/RouterContext';
import { TransportsQueryParamsContext } from '../../../context/TransportsQueryParamsContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import {
	BuildClient,
	ITransportSummaryQueryResult,
	ITransportsQueryParams,
	ITransportSummary,
	TransportsClient,
	TransportsQueryParams,
} from '../../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { useTrigger } from '../../../hooks/useTrigger';
import { ISortProps } from '../../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../../infrastructure/Utils';
import * as routes from '../../../routes';
import { IconButton } from '../../common/buttons/IconButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { ToggleIconButton } from '../../common/buttons/ToggleIconButton';
import { BaseTableSortAndPage } from '../../common/table/BaseTableSortAndPage';
import { PagingFooter } from '../../common/table/PagingFooter';
import { createEmptyHeader, createHeader, createSortableHeader } from '../../common/table/TableUtils';
import './Transports.scss';
import { TransportsFilter } from './TransportsFilter';
import { downloadPod, toggleIsTemplate, viewTransportDetail } from './TransportUtils';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<ITransportSummary>('date'),
	sortDirection: 'descending',
};

export const Transports = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const modalContext = useContext(ModalContext);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const locContext = useLocalizationContext();
	const client = useClient(TransportsClient);
	const routerContext = useContext(RouterContext);
	const [queryResult, setQueryResult] = useState<ITransportSummaryQueryResult>();
	const { params, setParams } = useContext(TransportsQueryParamsContext);
	useContext(DashboardSearchContext).init(`${locContext.searchOn} ${locContext.fileNumber}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});
	const downloadClient = useDownloadClient(routerContext);
	const [trigger, hitTrigger] = useTrigger();
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			loadTransports();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const loadTransports = async () => {
		const t = await tryCatchWithLoading(client.query(new TransportsQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError);
		setQueryResult(t);
	};

	const headers = [
		createSortableHeader<ITransportSummary>(locContext.fileNumber, 'fileNumber', t => t.fileNumber),
		createHeader<ITransportSummary>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
		createSortableHeader<ITransportSummary>(locContext.reference, 'reference', t => t.reference),
		createSortableHeader<ITransportSummary>(locContext.department, 'department', t => t.department),
		createHeader<ITransportSummary>(locContext.price, t => <div>&euro; {t.amount}</div>),
		createSortableHeader<ITransportSummary>(locContext.date, 'date', t => toDefaultFormat(t.date)),
		createSortableHeader<ITransportSummary>(locContext.status, 'status', t => t.status),
		createEmptyHeader<ITransportSummary>('actions', t => renderActionsButtons(t), undefined, true),
	];

	function renderActionsButtons(inst: ITransportSummary) {
		return (
			<div className='df-row-ac jc-e'>
				<ToggleIconButton
					size='large'
					trueIcon={['fas', 'star']}
					falseIcon={['far', 'star']}
					checked={inst.isTemplate === true}
					color={inst.isTemplate ? 'warning' : 'gray'}
					toggle={() => toggleIsTemplate(inst, client, hitTrigger, modalContext, locContext)}
				/>
				<IconButton
					size='large'
					icon={['fas', 'file-signature']}
					className='download-button'
					color={inst.isTrackTraceAvailable === true ? 'success' : 'gray'}
					popupText={locContext.downloadTrackAndTrace}
					onClick={e => downloadPod(inst, downloadClient, buildClient, wrapperLoaderContext)}
					disabled={inst.isTrackTraceAvailable === false}
				/>
			</div>
		);
	}

	const exportTransports = async () => {
		wrapperLoaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		let url_ = `${baseUrl ?? ''}/api/transports/export?`;
		if (params.searchString !== undefined) url_ += 'searchString=' + encodeURIComponent('' + params.searchString) + '&';
		if (params.statuses !== undefined)
			params.statuses &&
				params.statuses.forEach(item => {
					url_ += 'statuses=' + encodeURIComponent('' + item) + '&';
				});
		if (params.departments !== undefined)
			params.departments &&
				params.departments.forEach(item => {
					url_ += 'departments=' + encodeURIComponent('' + item) + '&';
				});
		if (params.subDepartments !== undefined)
			params.subDepartments &&
				params.subDepartments.forEach(item => {
					url_ += 'subDepartments=' + encodeURIComponent('' + item) + '&';
				});
		if (params.contactIds !== undefined)
			params.contactIds &&
				params.contactIds.forEach(item => {
					url_ += 'contactIds=' + encodeURIComponent('' + item) + '&';
				});
		if (params.beforeDate !== undefined) url_ += 'beforeDate=' + encodeURIComponent(params.beforeDate ? '' + params.beforeDate.toJSON() : '') + '&';
		if (params.afterDate !== undefined) url_ += 'afterDate=' + encodeURIComponent(params.afterDate ? '' + params.afterDate.toJSON() : '') + '&';
		url_ = url_.replace(/[?&]$/, '');
		await downloadClient.download(url_, `transports_export.xlsx`, () => wrapperLoaderContext.setLoading(false));
	};

	return (
		<Card className='base-transports-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb transports-header'>
					<div className=''>
						<Link to={routes.NewTransportRoute}>
							<PrimaryButton small>+ {locContext.newTransport}</PrimaryButton>
						</Link>
					</div>
					<div className='df-row-ac'>
						<Responsive {...Responsive.onlyMobile}>
							<PrimaryButton
								small
								outline
								className='export-button'
								onClick={() => setIsFilterOpen(!isFilterOpen)}>
								{locContext.filterOn}
							</PrimaryButton>
						</Responsive>
						<Responsive minWidth={Responsive.onlyTablet.minWidth}>
							<PrimaryButton
								small
								outline
								className='export-button'
								onClick={() => exportTransports()}>
								{locContext.export}
							</PrimaryButton>
						</Responsive>
					</div>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<TransportsFilter
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<TransportsFilter
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
					/>
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<BaseTableSortAndPage<ITransportSummary, ITransportsQueryParams>
						params={params}
						onSortOrPage={t => setParams(t)}
						fallbackSortProps={fallbackSortProps}
						queryResult={queryResult}
						canSort={true}
						headers={headers}
						uniqueIdentifier='fileNumber'
						singleIdentifier={locContext.transport}
						onClickRow={inst => viewTransportDetail(inst, routerContext)}
					/>
				</Responsive>
				<Responsive {...Responsive.onlyMobile}>
					{queryResult && queryResult.values ? (
						<div className='df-col'>
							{queryResult.values.map(t => (
								<div
									className='df-col'
									key={t.fileNumber}>
									<MobileSingleTransport
										transport={t}
										toggleTemplate={() => toggleIsTemplate(t, client, hitTrigger, modalContext, locContext)}
										viewDetail={() => viewTransportDetail(t, routerContext)}
									/>
									<Divider className='no-margin' />
								</div>
							))}
							<PagingFooter
								className='padding-10px'
								singleIdentifier={locContext.transport}
								qr={queryResult ? queryResult : {}}
								onNext={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex + 1 : 1 })}
								onPrevious={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex - 1 : 1 })}
							/>
						</div>
					) : null}
				</Responsive>
			</div>
		</Card>
	);
};

interface IMobileSingleTransportProps {
	transport: ITransportSummary;
	toggleTemplate: (e: any) => void;
	viewDetail: () => void;
}
export const MobileSingleTransport = ({ transport, toggleTemplate, viewDetail }: IMobileSingleTransportProps) => {
	return (
		<div className='mobile-single-transport df-row'>
			<div
				className='fg1 df-col'
				onClick={() => viewDetail()}>
				<div className='amount'>&euro; {transport.amount}</div>
				<div className='address'>{transport.formattedAddress}</div>
				<div className='date'>{toDefaultFormat(transport.date)}</div>
			</div>
			<div>
				<ToggleIconButton
					size='large'
					trueIcon={['fas', 'star']}
					falseIcon={['far', 'star']}
					checked={transport.isTemplate === true}
					color={transport.isTemplate ? 'warning' : 'gray'}
					toggle={e => toggleTemplate(e)}
				/>
			</div>
		</div>
	);
};
