import { IStrings } from './IStrings';

export const fr: IStrings = {
    add: `Ajouter`,
    addExtraPackage: `Ajouter  un paquet supplémentaire`,
    addPackage: `Ajouter un paquet`,
    address: `Adresse`,
    addressAddedToAddressBook: `Ajouter adresse au carnet d'adresses`,
    addressBook: `Carnet d'adresses`,
    addressChanged: `Adresse changée`,
    addressDeleted: `Adresses supprimées`,
    addressDetails: `Détails d'adresse`,
    addStop: `Ajouter un stop`,
    addToAddressBook: `Ajouter un carnet d'adresses`,
    after: `Après`,
    all: `Tout`,
    alsoFillInAHouseNumber: `Introduire aussi un numéro de maison`,
    at: `A`,
    available247: `disponible 24/7`,
    backToDashboard: `Retour au tableau de bord`,
    backToLogin: `Retour au login`,
    backToOverview: `Retour à l'aperçu`,
    before: `Devat/Avant`,
    bookOutsideOfficeHoursContent: `Une réservation par MySprint hors nos heures ouvrables est possible, mais il ne sera que plannifier après votre contacte téléphonique avec nous.`,
    businessUnit: `Emplacement`,
    busyCalculating: `A calculer`,
    busySearchingAddress: `Recherche d'adresses`,
    busyTyping: `Dactylographie`,
    calculatePrice: `Calculer le prix`,
    calculateVerb: `Calculer`,
    cancel: `Annuler`,
    canceled: `Annulé`,
    cargo: `Fret`,
    change: `Ajuster`,
    checkDiscountCode: `Code de vérification`,
    checkYourData: `Vérifiez vos coordonnées`,
    choose: `Choisir`,
    chooseAType: `Choississez un type`,
    clickHereToDownloadExampleFile: `Cliquez ici pour télécharger un exemple de fichier`,
    clickHereToResolve: `Cliquez ici pour trouver l'erreur`,
    client: `Client`,
    comments: `Remarques`,
    company: `Société`,
    companyName: `Nom de l'entreprise`,
    concept: `Concept`,
    conceptOptionDescription: `Un concept est un transport inaachevé`,
    concepts: `Concepts`,
    confirmAddStopover: `Voulez-vous ajouter une escale?`,
    confirmDeleteAddress: `Êtes -vous sûr de vouloir supprimer l'adresse?`,
    confirmDeleteDraft: `Êtes-vous sûr de vouloir supprimer ce brouillon?`,
    confirmDeletePackage: `Êtes-vous sûr de vouloir supprimer ce paquet?`,
    confirmDeleteStop: `Êtes-vous sûr de vouloir supprimer cet arrêt?`,
    confirmDeleteTemplate: `Êtes-vous sûr de vouloir supprimer ce modèle?`,
    confirmed: `Confirmé`,
    confirmedTransports: `Transport confirmé`,
    confirmTransport: `Confirmer le transport`,
    confirmYourTransport: `Confirmez votre transport`,
    contact: `Contact`,
    contactPerson: `Personne de contact`,
    contactUs: `Nous contacter`,
    contentExtraCosts: `Calculer les coûts supplémentaires tels que péage, pont, tunnel, ect….le cas échéant`,
    contentNoDiscountCode: `Si vous n'avez pas de code de réduction, vous pouvez continuer,`,
    costCenter: `Centre de coûts`,
    createFromDraft: `Début de ce concept`,
    createNewAddress: `Créer une nouvelle adresse`,
    dashboard: `Dashboard`,
    date: `Date`,
    deadFreight: `Erreur de fret`,
    delete: `Supprimer`,
    deleteAddress: `Supprimer l'adresse`,
    deleteDraft: `Supprimer le concept?`,
    deleteFilter: `Supprimer les filtres`,
    deletePackage: `Supprimer le paquet`,
    deleteStop: `Supprimer le stop`,
    deleteTemplate: `supprimer le brouillon`,
    department: `Département`,
    departmentOrBusinessUnit: `Départemnt/Lieu`,
    description: `Descritption`,
    dimensions: `Dimenssions`,
    dimensionsBetweenWheelArches: `Dimensions entre passages de roue`,
    dimensionsNotice: `Avis de dimensions`,
    discountAcknowledged: `Remise: {0} accorder`,
    discountApplied: `Remise {0} accordée`,
    discountCode: `Code de réduction`,
    downloadTrackAndTrace: `Télécharger Track & Trace`,
    doYouHaveADiscountCode: `Avez-vous un code de réduction?`,
    edit: `Ajuster`,
    editAddress: `Ajuster l'adresse`,
    editPackage: `Ajuster le coli`,
    email: `E-mailadres`,
    error: `Faute`,
    errorEmptyCargo: `Plus de paquets à décharger. Ajoutez une charge en arrêt supplémentaire.`,
    errorNoCargoOperation: `Sélectionnez au moins un package à charger ou décharger`,
    errorNullValuesAddress: `Il n'est pas possible de calculer un prix pour cette addresse.\nVeuillez contacter Euro-Sprinters.`,
    errorTimingsInYourTransport: `Il ya une faute dans votre entrée de transport`,
    excludingWaitingTimes: `Sans tous les temps d'attente`,
    export: `Exporter`,
    extraCosts: `Coûts supplémentaires`,
    extraStop: `Arrêt supplémentaire`,
    extraTimeApplicableAtThisPlace: `Temps supplémentaire applicable à cet endroit`,
    fileNumber: `Numéro du dossier`,
    fillInAddress: `Remplir l'adresses`,
    fillInAddressOrSelectFromAddressBook: `Saisir l'adresse ou la sélectionner dans le carnet d'adresses`,
    fillInQuantity: `Remplir le numéro`,
    filterOn: `Filtrer par`,
    firstStop: `Premier arrêt`,
    forgotPassword: `Mot de passe oublié?`,
    forgotPasswordContent: `Stuur een mail met je gebruikersnaam\nnaar info@eurosprinters.be.\nNadien krijg je instructies om je wachtwoord te wijzigen.`,
    generalConditions: `En cliquant sur confirmer le transport, le client déclare accepter les conditions générales de Euro-Sprinters NV, Celles-ci sont disponibles sur simple de mande via info@eurosprinters.be`,
    goToUnload: `Décharger`,
    height: `Hauteur`,
    heightPerPackage: `Hauteur (par paquet)`,
    hello: `Bonjour`,
    hours: `Heures`,
    houseNumber: `Numéro de maison`,
    houseNumberWasNotFound: `Le numéro de la maison {0}est introuvable`,
    import: `Importer`,
    importAddressBookContent: `Cliquez sur -sélectionnez un fichier- ci-dessous pour importer le carnet d'adresses.\nLa première ligne du fichier doit contenir les colonnes suivantes:`,
    inProgress: `En cours`,
    inThisTimeMinutesDelayAreIncludedPt1: `Ce temps comprend`,
    inThisTimeMinutesDelayAreIncludedPt2: `minutes de retard`,
    invalidCombinationUsernamePassword: `Combinaison nom d'utilisateur / mot de passe invalide`,
    isRead: `Lire?`,
    kg: `kg`,
    labelAdditionalInfo: `Informations supplémentaires ( numéro de boîte, étage,…)`,
    labelAddressBook: `Carnet d'adresses`,
    labelContactPerson: `Personne sur place`,
    labelStreetNumberCityAndCode: `Adresse (rue, numéro, ville et code postal)`,
    lastModifiedDate: `Date du dernier changement`,
    lastMonth: `Mois passé`,
    lastStop: `Dernier stop`,
    lastWeek: `Semaine passée`,
    lastYear: `L'année dernière`,
    leaveThisPageDontSaveAsDraft: `Sortir de cette page`,
    length: `Longueur`,
    lengthPerPackage: `Longueur (par paquet)`,
    load: `Chargement`,
    loadAndUnloadAddress: `Adresse de chargement et de déchargement`,
    loadAndUnloadAddressPlusStops: `Adresse de chargement et de déchargement (+ stop)`,
    loadNewPackage: `Charger un nouveau paquet`,
    login: `S'inscrire`,
    loginWithUsernamePassword: `Connectez-vous avec votre nom d'utilisateur et votre mot de passe`,
    logOut: `Deconnexion`,
    LxWxH: `LxBxH`,
    LxWxH_inMeters: `L x B x H en mètres`,
    markAsRead: `Marquer comme lu`,
    max: `max`,
    max6stops: `Vous pouvez entrer jusqu'à 6 arrêts.\nVoulez-vous ajouter plus? Contactez le service client.`,
    maxLengthValidation: `La longueur est limitée à max  {0} caractères`,
    maxSizeWarningText: `Attention, les dimensions d'un colis ne doivent pas dépasser les dimensions maximales du véhicule.`,
    moreInfo: `Plus d'informations`,
    newAddress: `Nouvelle adresse`,
    newTemplate: `Nouveau modèle`,
    newTransport: `Nouveau transport`,
    newTransportOptionDescription: `Partir de zéro et faire le transport qui répond à vos besoins`,
    next: `Suivant`,
    no: `Non`,
    noAccount: `Aucun compte`,
    noAddressFound: `Aucune adresse trouvée`,
    none: `Rien`,
    noNewNotifications: `Il n'y a pas de nouvelles notifications`,
    notification: `Message`,
    notificationMarkedAsRead: `Message marqué comme lu`,
    notificationMarkedAsUnread: `Message marqué comme non lu`,
    notifications: `Notifications`,
    notStoredAsTemplate: `Non enregistré en tant que modèle`,
    noValidDiscountCode: `Ce code de réduction n'est pas valide`,
    okUnderstood: `D'accord, j'ai compris!`,
    optional: `Facultatif`,
    or: `Ou`,
    overview: `Vue d'ensemble`,
    packageInCargo: `Paquet dans la cargaison`,
    packageType: `Tuype de colis`,
    pagingTable: `{0} {1} à {2} à partir {3}`,
    password: `Mot de passe oublié?`,
    phoneNumber: `Numéro de téléphone`,
    placeholderAdditionalInfo: `Informations supplémentaires`,
    placeholderAddress: `Entrez une adresse`,
    placeholderCompany: `Entrez le nom de l'entreprise`,
    placeholderConcept: `Choisir un brouillon`,
    placeholderContactPerson: `Entrez le nom de la personne de contact`,
    placeholderDimensions: `Dimensions (LxBxH en m, par paquet)`,
    placeholderHouseNumber: `Compléter le numéro de la maison ici s'il ne se trouve pas ci-dessus`,
    placeholderPackageType: `Sélectionnez le type de paquet`,
    placeholderPhonenumber: `Entrez un numéro de téléphone`,
    placeholderTemplate: `Choisir un modèle`,
    placeholderWeightInKgPerPackage: `Poids (en kg, par paquet)`,
    previous: `Précédent`,
    price: `Prix`,
    processed: `Effectué`,
    quantity: `Nombre`,
    quantityMustBeAnInteger: `Le nombre doit être un nombre entier`,
    quantityMustBeMinimalOne: `La quantité doit être au minimum 1`,
    questionHowDoYouWantToStart: `Comment voulez-vous commencer?`,
    read: `Lire?`,
    reference: `Références`,
    route: `Route`,
    saveAsConcept: `Enregistrer comme brouillon`,
    saveAsTemplate: `Enregistrer comme modèle`,
    savedAsConcept: `Enregistré comme bruoillon`,
    search: `Recherche`,
    searchOn: `Recherche sur`,
    searchOnTitleOrDescription: `Recherche par titre ou description`,
    select: `Sélectionner`,
    selectAClient: `Sélectionnez un client`,
    selectADate: `Sélectionnez une date`,
    selectADepartmentOrBusinessUnit: `Sélectionnez un département / emplacement`,
    selectAFile: `Sélectionnez un fichier`,
    selectAnAddress: `Sélectionnez une adresse`,
    selectAPackage: `Sélectionnez un type de paquet`,
    selectAQuantity: `Sélectionnez un nombre`,
    selectDateAndTime: `Sélectionnez la date/l'heure`,
    selectFromAddressBook: `Sélectionnez dans le carnet d'adresse`,
    selectTime: `Sélectionnez une heure`,
    serverError: `Une erreur inconnue est survenue sur le serveur. Veuillez réessayer. Si l'erreur persiste, veuillez contacter Euro-Sprinters.`,
    settings: `Paramètres`,
    shutDown: `Sortie`,
    startFromAConcept: `Début du concept`,
    startFromATemplate: `Début d'un modèle`,
    startFromThisTemplate: `Début de ce modèle`,
    status: `Statut`,
    stayOnThisPage: `Restez sur cette page`,
    stop: `Stop`,
    stopover: `Stop entre`,
    stops: `Stops`,
    storedAsTemplate: `Enregistrer en tant que modèle`,
    subDepartment: `Sous-section`,
    subTotal: `Sous-total`,
    successfullyImportedAddresses: `Adresses importées avec succès`,
    successfullyLoggedOut: `Déconnecté avec succès!`,
    template: `Modèle`,
    templateOptionDescription: `Utilisez un modèle pour saisir rapidement un envoi`,
    templates: `Modèles`,
    thisMonth: `Ce mois`,
    thisWeek: `Cette semaine`,
    thisYear: `Cette année`,
    time: `Le temps`,
    title: `Titre`,
    today: `Aujourd'hui`,
    total: `Total`,
    transport: `Transport`,
    transportNoLongerStoredAsTemplate: `Le transport n'est plus stocké en tant que modèle`,
    transportNotSavedAsDraft: `Transport non enregistré comme brouillon`,
    transportNotSavedAsDraftDescription: `Cette entrée n'a pas été sauvegardée. Si vous continuez, vous ne pouvez pas modifié le transport. Que vouslez-vous faire ?`,
    transports: `Transports`,
    transportStoredAsTemplate: `Transport enregistré en tant que modèle`,
    unload: `Décharger`,
    unloadPackage: `Décharger un coli`,
    unread: `Pas lu`,
    upForToday: `cela se passe aujourd'hui!`,
    useDefaultLoadAddress: `Utiliser une adresse de chargement standard`,
    userName: `Nom d'utilisateur`,
    validateAddress: `Entrez une adresse`,
    validateCompanyName: `Entrez le nom de l'entreprise`,
    validateContactPerson: `Entrez la personne de contact`,
    validateCostCenter: `Entrez le centre de coûts`,
    validateDescription: `Entrez une description`,
    validateEmail: `Entrez votre adresse mail`,
    validateHeightPerPackage: `Entrez une hauteur (par paquet)`,
    validateHouseNumberIfNotFoundAbove: `Entrez un numéro de rue s'il ne se trouve pas ci-dessus`,
    validateLengthPerPackage: `Entrez une longueur (par paquet)`,
    validateLoginCompanyName: `Entrez le nom de votre entreprise`,
    validateMaxQuantity: `Seuls max. {0} pièce(s)peuvent être déchargées`,
    validatePassword: `Entrez votre mot de passe`,
    validateReference: `Entrez la référence`,
    validateTime: `Sélectionnez une heure`,
    validateTimeSelector: `Sélectionnez de / à / après`,
    validateUsername: `Entrez votre nom d'utilisateur`,
    validateWeightPerPackage: `Entrez le poids (par paquet)`,
    validateWidthPerPackage: `Entrez une largeur (par paquet)`,
    vehicle: `Véhicule`,
    viewAddressBook: `Afficher le carnet d'adresses`,
    viewAll: `Voir tout`,
    viewAllTransports: `Voir tous les transports`,
    viewDrafts: `Voir les concepts`,
    warningBookOutsideOfficeHours: `Attention: vous voulez organiser un transport hors nos heures ouvrables. Téléphonez-nous!`,
    warningTransportAbroad: `Vous avez plannifier un transport à l'étranger! Attion: il y a des frais suppléméntaires`,
    warningTransportAbroadContent1: `Votre transport contient au moins un stop a l'étrange. Le prix affiché dans MySprint est hors frais supplémentaires.`,
    warningTransportAbroadContent2: `Frais supplémentaires possibles:`,
    warningTransportAbroadContent3: `Péage\nPont\nTransfert\n…`,
    webCompany: `Web Company`,
    webPassword: `Web Mot du passe`,
    webUser: `Web Utilisateur`,
    welcomeToMySprint: `Bienvenue sur MySprint`,
    width: `Largeur`,
    widthPerPackage: `Largeur (par paquet)`,
    yes: `Oui`,
    yesDelete: `Oui, supprimer`,
    yesIUnderstand: `Oui, je comprends`,
    yesterday: `Hier`,
    youDontHaveAnyAddresses: `Vous n'avez encore aucune adresses dans votre carnet d'adresses. Ajoutez cela!`,
    youHaveNumberOfDrafts: `Vous avez{0} transport(s) en tant que concept. Finissez les plus loin.`,
    youHaveNumberOfRoutesToday: `{0} voyage(s) sont prévus pour aujourd'hui.`,
    yourOrderNumber: `Votre numéro de commande`,
    yourTransportInputIsSuccess: `L'encodage de votre transport a été réussi!`,
}
