import { IStrings } from './IStrings';

export const en: IStrings = {
    add: `Add`,
    addExtraPackage: `Add extra parcel`,
    addPackage: `Add parcel`,
    address: `Address`,
    addressAddedToAddressBook: `Address added to address-book`,
    addressBook: `Address book`,
    addressChanged: `Address changed`,
    addressDeleted: `Address removed`,
    addressDetails: `Address details`,
    addStop: `Add stop`,
    addToAddressBook: `Add to addressbook`,
    after: `After`,
    all: `All`,
    alsoFillInAHouseNumber: `Please fill in a housenumber`,
    at: `At`,
    available247: `24/7 available`,
    backToDashboard: `Back to dashboard`,
    backToLogin: `Back to login`,
    backToOverview: `Back to overview`,
    before: `Before`,
    bookOutsideOfficeHoursContent: `A booking via MySprint outside the office hours is possible, but the driver will not be planned until before you've contacted us by phone!`,
    businessUnit: `Establishment`,
    busyCalculating: `Calculating…`,
    busySearchingAddress: `Search address`,
    busyTyping: `Busy typing`,
    calculatePrice: `Calculate price`,
    calculateVerb: `Calculate`,
    cancel: `Cancel`,
    canceled: `Canceled`,
    cargo: `Freight`,
    change: `Adjust`,
    checkDiscountCode: `Check discount code`,
    checkYourData: `Check your data`,
    choose: `Choose`,
    chooseAType: `Choose a type`,
    clickHereToDownloadExampleFile: `Click here to download a sample file`,
    clickHereToResolve: `Click here to resolve`,
    client: `Client`,
    comments: `Comments`,
    company: `Company`,
    companyName: `Company name`,
    concept: `Concept`,
    conceptOptionDescription: `A concept is an unfinished transport`,
    concepts: `Concepts`,
    confirmAddStopover: `Do you want to add an additional stop?`,
    confirmDeleteAddress: `Are you sure you want to delete the address?`,
    confirmDeleteDraft: `Are you sure you want to delete this concept?`,
    confirmDeletePackage: `Are you sure you want to delete this package?`,
    confirmDeleteStop: `Are you sure you want to delete this stop?`,
    confirmDeleteTemplate: `Are you sure you want to delete this template?`,
    confirmed: `Confirmed`,
    confirmedTransports: `Confirmed transports`,
    confirmTransport: `Confirm transport`,
    confirmYourTransport: `Confirm your transport`,
    contact: `Contact`,
    contactPerson: `Contact person`,
    contactUs: `Contact us.`,
    contentExtraCosts: `Extra costs such as tolls, bridge, tunnel, …  will be charged separately when applicable.`,
    contentNoDiscountCode: `When you don't have a discount code, you can continue.`,
    costCenter: `Cost center`,
    createFromDraft: `Create from this concept`,
    createNewAddress: `Create new address`,
    dashboard: `Dashboard`,
    date: `Date`,
    deadFreight: `Dead freight`,
    delete: `Delete`,
    deleteAddress: `Delete address`,
    deleteDraft: `Delete concept?`,
    deleteFilter: `Delete filter`,
    deletePackage: `Delete package`,
    deleteStop: `Delete stop`,
    deleteTemplate: `Delete template`,
    department: `Department`,
    departmentOrBusinessUnit: `Department/Business unit`,
    description: `Description`,
    dimensions: `Dimensions`,
    dimensionsBetweenWheelArches: `Dimensions between wheel arches`,
    dimensionsNotice: `Dimensions notice`,
    discountAcknowledged: `Discount: {0} acknowledged`,
    discountApplied: `Discount {0} applied`,
    discountCode: `Discount code`,
    downloadTrackAndTrace: `Download Track & Trace`,
    doYouHaveADiscountCode: `Do you have a discount code?`,
    edit: `Edit`,
    editAddress: `Edit address`,
    editPackage: `Edit package`,
    email: `Email`,
    error: `Error`,
    errorEmptyCargo: `No more packages to unload. Add a load in extra stop.`,
    errorNoCargoOperation: `Select at least one package to load or unload`,
    errorNullValuesAddress: `It's not possible to calculate a price for this address.\nPlease contact Euro-Sprinters.`,
    errorTimingsInYourTransport: `Something went wrong while creating your transport`,
    excludingWaitingTimes: `Excl. waiting times`,
    export: `Export`,
    extraCosts: `Extra costs`,
    extraStop: `Extra stop`,
    extraTimeApplicableAtThisPlace: `Additional time applicable at this location`,
    fileNumber: `Order number`,
    fillInAddress: `Fill in address`,
    fillInAddressOrSelectFromAddressBook: `Fill in address or select from adressbook`,
    fillInQuantity: `Fill in quantity`,
    filterOn: `Filter on`,
    firstStop: `First stop`,
    forgotPassword: `Forgot password?`,
    forgotPasswordContent: `Send an email with your username to\ninfo@eurosprinters.be.\nYou'll get instructions to reset your password afterwards.`,
    generalConditions: `By entrusting a service to Euro-Sprinters, the customer accepts the general terms & conditions of Euro-Sprinters. These conditions are available via info@eurosprinters.be.`,
    goToUnload: `Go to unload`,
    height: `Height`,
    heightPerPackage: `Height (per package)`,
    hello: `Hello`,
    hours: `Hours`,
    houseNumber: `House number`,
    houseNumberWasNotFound: `House number {0} was not found`,
    import: `Import`,
    importAddressBookContent: `Click on -select a file- below to import the address book.\nThe first row of the file must contain the following columns:`,
    inProgress: `In progress`,
    inThisTimeMinutesDelayAreIncludedPt1: `This time includes`,
    inThisTimeMinutesDelayAreIncludedPt2: `minutes of delay`,
    invalidCombinationUsernamePassword: `Invalid combination username and password`,
    isRead: `Read?`,
    kg: `kg`,
    labelAdditionalInfo: `Additional info (busnr, floor, ...)`,
    labelAddressBook: `Address book`,
    labelContactPerson: `Contact person on the spot`,
    labelStreetNumberCityAndCode: `Address (street, house number, city and postal code)`,
    lastModifiedDate: `Date last modification`,
    lastMonth: `Last month`,
    lastStop: `Last stop`,
    lastWeek: `Last week`,
    lastYear: `Last year`,
    leaveThisPageDontSaveAsDraft: `Leave this page`,
    length: `Length`,
    lengthPerPackage: `Length (per package)`,
    load: `Load`,
    loadAndUnloadAddress: `Loading- and unloading address`,
    loadAndUnloadAddressPlusStops: `Loading- and unloading address (+ stops)`,
    loadNewPackage: `Load new package`,
    login: `Login`,
    loginWithUsernamePassword: `Login with your username and password`,
    logOut: `Log out`,
    LxWxH: `LxWxH`,
    LxWxH_inMeters: `LxWxH in meters`,
    markAsRead: `Mark as read`,
    max: `max`,
    max6stops: `You can enter up to max. 6 stops.\nWant to add more? Contact customer service.`,
    maxLengthValidation: `Length is limited to max. {0} characters`,
    maxSizeWarningText: `Please note, the dimensions of a package must not exceed the maximum dimensions of the vehicle.`,
    moreInfo: `More info`,
    newAddress: `New address`,
    newTemplate: `New template`,
    newTransport: `New transport`,
    newTransportOptionDescription: `Start from scrath and create the transport that meets your needs`,
    next: `Next`,
    no: `No`,
    noAccount: `No account`,
    noAddressFound: `No address found`,
    none: `None`,
    noNewNotifications: `There are no new notifications`,
    notification: `Notification`,
    notificationMarkedAsRead: `Notification marked as read`,
    notificationMarkedAsUnread: `Notification marked as unread`,
    notifications: `Notifications`,
    notStoredAsTemplate: `Not saved as template`,
    noValidDiscountCode: `This discount code is not valid`,
    okUnderstood: `Ok, understood!`,
    optional: `Optional`,
    or: `Or`,
    overview: `Overview`,
    packageInCargo: `Package in cargo`,
    packageType: `Package type`,
    pagingTable: `{0} {1} until {2} from {3}`,
    password: `Password`,
    phoneNumber: `Phone number`,
    placeholderAdditionalInfo: `Additional info`,
    placeholderAddress: `Fill in address`,
    placeholderCompany: `Fill in company name`,
    placeholderConcept: `Select concept`,
    placeholderContactPerson: `Fill in the name of the contact person`,
    placeholderDimensions: `Dimensions (LxWxH in m, per package)`,
    placeholderHouseNumber: `Fill in the house number when it hasn't been found above`,
    placeholderPackageType: `Select package type`,
    placeholderPhonenumber: `Fill in phone number`,
    placeholderTemplate: `Select template`,
    placeholderWeightInKgPerPackage: `Weight (in kg, per package)`,
    previous: `Previous`,
    price: `Price`,
    processed: `Executed`,
    quantity: `Quantity`,
    quantityMustBeAnInteger: `Quantity must be a round number`,
    quantityMustBeMinimalOne: `Quantity must be minimal one`,
    questionHowDoYouWantToStart: `How do you want to start?`,
    read: `Read`,
    reference: `Reference`,
    route: `Route`,
    saveAsConcept: `Save as concept`,
    saveAsTemplate: `Save as template`,
    savedAsConcept: `Saved as concept`,
    search: `Search`,
    searchOn: `Search on`,
    searchOnTitleOrDescription: `Search on title or description`,
    select: `Select`,
    selectAClient: `Select a client`,
    selectADate: `Select a date`,
    selectADepartmentOrBusinessUnit: `Select a department/business unit`,
    selectAFile: `Select a file`,
    selectAnAddress: `Select an address`,
    selectAPackage: `Select a package type`,
    selectAQuantity: `Select a quantity`,
    selectDateAndTime: `Select date & time`,
    selectFromAddressBook: `Select from address book`,
    selectTime: `Select time`,
    serverError: `An unknown error has occured. Please try again. If this error returns, please contact Euro-Sprinters!`,
    settings: `Settings`,
    shutDown: `Shut down`,
    startFromAConcept: `Start from a concept`,
    startFromATemplate: `Start from a template`,
    startFromThisTemplate: `Start from this template`,
    status: `Status`,
    stayOnThisPage: `Stay on this page`,
    stop: `Stop`,
    stopover: `Additional stop`,
    stops: `Stops`,
    storedAsTemplate: `Saved as template`,
    subDepartment: `Sub department`,
    subTotal: `Sub total`,
    successfullyImportedAddresses: `Addresses successfully imported`,
    successfullyLoggedOut: `Successfully logged out!`,
    template: `Template`,
    templateOptionDescription: `Use a template to register a transport quickly`,
    templates: `Templates`,
    thisMonth: `This month`,
    thisWeek: `This week`,
    thisYear: `This year`,
    time: `Time`,
    title: `Title`,
    today: `Today`,
    total: `Total`,
    transport: `Transport`,
    transportNoLongerStoredAsTemplate: `Transport no longer saved as template`,
    transportNotSavedAsDraft: `Transport no longer saved as concept`,
    transportNotSavedAsDraftDescription: `This data has not been saved. If you continue, you can't adjust this transport. Do you want to continue?`,
    transports: `Transports`,
    transportStoredAsTemplate: `Transport saved as template`,
    unload: `Unload`,
    unloadPackage: `Unload a package`,
    unread: `Unread`,
    upForToday: `this is happening today!`,
    useDefaultLoadAddress: `Use default loading address`,
    userName: `Username`,
    validateAddress: `Fill in address`,
    validateCompanyName: `Fill in your company name`,
    validateContactPerson: `Fill in contact person`,
    validateCostCenter: `Fill in cost center`,
    validateDescription: `Fill in description`,
    validateEmail: `Fill in your email`,
    validateHeightPerPackage: `Fill in height per package`,
    validateHouseNumberIfNotFoundAbove: `Fill in a house number when it hasn't been found above`,
    validateLengthPerPackage: `Fill in length per package`,
    validateLoginCompanyName: `Fill in your company name`,
    validateMaxQuantity: `Max. {0} piece(s) can be unloaded`,
    validatePassword: `Fill in your password`,
    validateReference: `Fill in your reference`,
    validateTime: `Selete a time of day`,
    validateTimeSelector: `Select from /at/after`,
    validateUsername: `Fill in your username`,
    validateWeightPerPackage: `Fill in the weight (per package)`,
    validateWidthPerPackage: `Fill in the width (per package)`,
    vehicle: `Vehicle`,
    viewAddressBook: `View address book`,
    viewAll: `View all`,
    viewAllTransports: `View all transports`,
    viewDrafts: `View concepts`,
    warningBookOutsideOfficeHours: `Warning: you want to book outside office hours. Call us!`,
    warningTransportAbroad: `You've planned a transport abroad! Warning: additional charges apply.`,
    warningTransportAbroadContent1: `Your transport contains at least one stop abroad. The price shown in MySprint is excluding these extra.`,
    warningTransportAbroadContent2: `Possible extra charges:`,
    warningTransportAbroadContent3: `Toll\nBridge\nTransfer\n…`,
    webCompany: `Web Company`,
    webPassword: `Web Password`,
    webUser: `Web User`,
    welcomeToMySprint: `Welcome at MySprint`,
    width: `width`,
    widthPerPackage: `Width (per package)`,
    yes: `Yes`,
    yesDelete: `Yes, delete`,
    yesIUnderstand: `Yes, I understand`,
    yesterday: `Yesterday`,
    youDontHaveAnyAddresses: `There are no addresses in your address book. Add one!`,
    youHaveNumberOfDrafts: `You have {0} transport(s) as concept. Finish them.`,
    youHaveNumberOfRoutesToday: `There are {0} transports planned`,
    yourOrderNumber: `Your order number`,
    yourTransportInputIsSuccess: `Your transport input has succeeded`,
}
