import React from 'react';
import { useCombineClassNames } from '../../../../hooks/useCombineClassNames';
import { TextLinkButton } from '../../../common/buttons/TextLinkButton';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import './Bulletin.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    title?: string;
    onClickMoreInfo: () => void;
}

export const Bulletin = ({ title, onClickMoreInfo, ...rest }: IProps) => {
    const locContext = useLocalizationContext();
    const className = useCombineClassNames('single-bulletin df-row-ac jc-sb', rest);
    return (
        <div {...rest} className={className} onClick={onClickMoreInfo}>
            <div className="bulletin-title">{title}</div>
            <TextLinkButton gray className="bulletin-more-info">{locContext.moreInfo}</TextLinkButton>
        </div>
    );
}
