import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { ModalContext } from '../../../context/ModalContext';
import { RouterContext } from '../../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { BuildClient, TransportDetail, TransportsClient } from '../../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { toDefaultFormat, tryCatchWithLoading } from '../../../infrastructure/Utils';
import { StopsViewComponent } from '../../blank/common/StopsViewComponent';
import { IconButton } from '../../common/buttons/IconButton';
import { ToggleIconButton } from '../../common/buttons/ToggleIconButton';
import './Transport.scss';
import { downloadPod, toggleIsTemplate } from './TransportUtils';

export const Transport = ({ match }: any) => {
	const locContext = useLocalizationContext();
	const fileNumber = match.params.fileNumber;
	const client = useClient(TransportsClient);
	const [detail, setDetail] = useState<TransportDetail>();
	const loaderContext = useContext(WrapperFullScreenLoadContext);
	const routerContext = useContext(RouterContext);
	const download = useDownloadClient(routerContext);
	const modalContext = useContext(ModalContext);
	useContext(DashboardSearchContext).hideAndShowGoBackButton();
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[fileNumber]
	);

	const load = async () => {
		if (fileNumber) {
			const t = await tryCatchWithLoading(client.detail(fileNumber), loaderContext.setLoading, locContext.serverError);
			setDetail(t);
		}
	};

	if (detail === undefined) {
		return null;
	}

	return (
		<Card className='transport-detail-card df-col'>
			<div className='df-row jc-sb'>
				{detail.isTrackTraceAvailable ? (
					<div className='df-col'>
						<div className={`status df-row-ac confirmed`}>
							<FontAwesomeIcon icon={['fas', 'check']} />
							<div className='status-text'>{detail.status}</div>
						</div>
					</div>
				) : (
					<div className='df-col'>
						<div className='label'>{locContext.status}</div>
						<div className='info'>{detail.status}</div>
					</div>
				)}
				<div className='df-row-ac'>
					<ToggleIconButton
						size='very-large'
						trueIcon={['fas', 'star']}
						falseIcon={['far', 'star']}
						checked={detail.isTemplate === true}
						color={detail.isTemplate ? 'warning' : 'gray'}
						toggle={() => toggleIsTemplate(detail, client, () => load(), modalContext, locContext)}
					/>
					<IconButton
						size='very-large'
						icon={['fas', 'file-signature']}
						className='download-button'
						color={detail.isTrackTraceAvailable === true ? 'success' : 'gray'}
						// popupText={locContext.downloadTrackAndTrace}
						onClick={() => downloadPod(detail, download, buildClient, loaderContext)}
						disabled={detail.isTrackTraceAvailable === false}
					/>
				</div>
			</div>
			<div className='label'>{locContext.fileNumber}</div>
			<div className='info'>{detail.fileNumber}</div>
			<div className='label'>{locContext.date}</div>
			<div className='info'>{toDefaultFormat(detail.date)}</div>
			<div className='label'>{locContext.reference}</div>
			<div className='info'>{detail.reference}</div>
			<div className='label'>{locContext.costCenter}</div>
			<div className='info'>{detail.costCenter}</div>
			<StopsViewComponent
				firstStop={detail.firstStop}
				extraStops={detail.extraStops}
				lastStop={detail.lastStop}
				showDividers={false}
				addTopMargin={false}
				hideLastDivider
				formatStopAsLabel={true}
			/>
			<div className='label'>{locContext.department}</div>
			<div className='info'>{detail.department}</div>
			<div className='label'>{locContext.price}</div>
			<div className='info'>&euro; {detail.amount}</div>
			<div className='df-row'>
				{detail.costs
					? detail.costs.map(t => (
							<div
								className='df-col single-price-calc'
								key={t.description}>
								<div className='label'>{t.description}</div>
								<div className='info'>&euro; {t.amount}</div>
							</div>
					  ))
					: null}
			</div>
			<div className='df-row'>
				{detail.discounts
					? detail.discounts.map(t => (
							<div
								className='df-col single-price-calc'
								key={t.description}>
								<div className='label'>{t.description}</div>
								<div className='info'>&euro; {t.amount}</div>
							</div>
					  ))
					: null}
			</div>
			<div className='label'>{locContext.route}</div>
			<div className='info'>{detail.totalDistance} km</div>
		</Card>
	);
};
