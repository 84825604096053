import React, { useState, useContext } from 'react';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { ILastStopModel, IAddressModel, AddressModel, TransportValidateClient, ValidateTimingsModel, IStopDateValidation } from '../../../gen/ApiClients';
import { AddressComponent } from '../common/addresses/AddressComponent';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './Step3.scss';
import { useClient } from '../../../hooks/useClient';
import { DateComponentWithStopDateValidation } from '../common/DateComponentWithStopDateValidation';

export const Step3 = () => {
	const validateClient = useClient(TransportValidateClient);
	const locContext = useLocalizationContext();
	const context = useContext(TransportSubmitContext);
	const [model, setModel] = useState<ILastStopModel | undefined>(context.state.lastStop);

	const handleSetAddress = (id: string | undefined, address: IAddressModel | undefined, mustSave: boolean) => {
		setModel({ ...model, fromAddressBook: id, address: new AddressModel(address), mustSaveAddress: mustSave });
		context.nextSubstep();
	};
	const handleSetDate = async (date: Date, specifier: string) => {
		const x = { ...model, date: date, dateSpecifier: specifier };
		setModel(x);
		await context.setLastStop(x);
	};

	const validateTimings = async (date: Date, spec: string): Promise<IStopDateValidation> => {
		return await validateClient.validateTimingLastStop(
			context.state.id!,
			new ValidateTimingsModel({
				date: date,
				dateSpecifier: spec,
				stopId: context.state.lastStop?.id,
				fromAddressBook: model ? model.fromAddressBook : undefined,
				address: model?.address,
			})
		);
	};

	if (context.subStep === 0) {
		return (
			<AddressComponent
				header={`${locContext.unload}: ${locContext.addressDetails}`}
				address={model && model.address ? model.address : undefined}
				mustSave={model && model.mustSaveAddress !== undefined ? model.mustSaveAddress : false}
				defaultLoadAddress={undefined}
				fromAddressBookId={model && model.fromAddressBook ? model.fromAddressBook : ''}
				submit={handleSetAddress}
				onPrevious={() => context.goBackToStep(2)}
			/>
		);
	} else if (context.subStep === 1 && context.state.id) {
		return (
			<DateComponentWithStopDateValidation
				header={`${locContext.unload}: ${locContext.selectDateAndTime}`}
				date={model && model.date ? model.date : new Date()}
				setTime={false}
				specifier={model && model.dateSpecifier ? model.dateSpecifier : 'Before'}
				onSubmit={handleSetDate}
				onPrevious={() => context.previousSubStep()}
				validate={(date, spec) => validateTimings(date, spec)}
			/>
		);
	} else {
		return <div>nothing found</div>;
	}
};
