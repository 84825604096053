import { Form, Formik, FormikActions } from 'formik';
import React, { useContext } from 'react';
import { Responsive } from 'semantic-ui-react';
import * as yup from 'yup';
import { LocalizationContext } from '../../../context/LocalizationContext';
import { ILanguageItemDescriptions, ILoadOperation, IValidateCapacityResponse, LoadOperation } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { IOption } from '../../../infrastructure/Models';
import { clearNullOrUndefinedValues } from '../../../infrastructure/Utils';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { AutoCompleteSelectField } from '../../common/forms/AutoCompleteSelectField';
import { setFieldError } from '../../common/forms/FormsUtils';
import { NumberInputField } from '../../common/forms/NumberInputField';
import { DimensionsInputField } from './DimensionsInputField';

interface IProps {
    header: string;
    loadOperation?: ILoadOperation;
    onSubmit: (load: ILoadOperation) => void;
    onPrevious: () => void;
    modal?: boolean;
    isEdit: boolean;
    validate: (model: ILoadOperation) => Promise<IValidateCapacityResponse>;
    packageOptions: ILanguageItemDescriptions[];
}

const emptyModel: ILoadOperation = {
    id: '',
    quantity: undefined,
    description: '',
    weightPerPackage: undefined,
    lengthPerPackage: undefined,
    heightPerPackage: undefined,
    widthPerPackage: undefined,
}

function getPackageOptions(packageOptions: ILanguageItemDescriptions[], lang: string) : IOption<string>[]{
    const r = packageOptions.find(t => t.language === lang);
    if (r === undefined || r.descriptions === undefined) {
        return [];
    }
    return r.descriptions.map<IOption<string>>(t => ({ key: t, value: t, text: t}));
}

export const LoadOperationComponent = ({ validate, header, loadOperation, onSubmit, onPrevious, modal = false, isEdit, packageOptions, }: IProps) => {
    const locContext = useLocalizationContext();
    const localizationContext = useContext(LocalizationContext);
    const lang = localizationContext.getLanguage();
    const options = getPackageOptions(packageOptions, lang);
    
    // const options = packageOptions.find(t => t.language == lang).map<IOption<string>>(t => ({ key: t, value: t, text: t }));
    const handleSubmit = async (model: ILoadOperation, actions: FormikActions<ILoadOperation>) => {
        const response = await validate(model);
        actions.setSubmitting(false);
        if (response.hasError) {
            if (response.isMaxLengthExceeded) {
                setFieldError<ILoadOperation>('lengthPerPackage', response.maxLengthError ? response.maxLengthError : 'Error', actions);
            } else if (response.isVolumeError) {
                setFieldError<ILoadOperation>('lengthPerPackage', response.volumeError ? response.volumeError : 'Error', actions);
            }
            if (response.isMaxWidthExceeded) {
                setFieldError<ILoadOperation>('widthPerPackage', response.maxWidthError ? response.maxWidthError : 'Error', actions);
            }
            if (response.isMaxHeightExceeded) {
                setFieldError<ILoadOperation>('heightPerPackage', response.maxHeightError ? response.maxHeightError : 'Error', actions);
            }
            if (response.isWeightExceeded) {
                setFieldError<ILoadOperation>('weightPerPackage', response.weightError ? response.weightError : 'Error', actions);
            }
        } else {
            onSubmit(model);
        }
    }
    const schema = yup.object<ILoadOperation>().shape<ILoadOperation>({
        quantity: yup.number()
            .integer(locContext.quantityMustBeAnInteger)
            .min(1, locContext.quantityMustBeMinimalOne)
            .required(locContext.fillInQuantity),
        description: yup.string()
            .required(locContext.validateDescription)
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        weightPerPackage: yup.number()
            .moreThan(0, locContext.validateWeightPerPackage)
            .required(locContext.validateWeightPerPackage),
        lengthPerPackage: yup.number()
            .moreThan(0, locContext.validateLengthPerPackage)
            .required(locContext.validateLengthPerPackage),
        widthPerPackage: yup.number()
            .moreThan(0, locContext.validateWidthPerPackage)
            .required(locContext.validateWidthPerPackage),
        heightPerPackage: yup.number()
            .moreThan(0, locContext.validateHeightPerPackage)
            .required(locContext.validateHeightPerPackage),
    });

    return (
        <div className="blank-container">
            <div className="header-text">{header}</div>
            <Formik<ILoadOperation>
                initialValues={loadOperation !== undefined ? clearNullOrUndefinedValues<ILoadOperation>(loadOperation, LoadOperation) 
                    : clearNullOrUndefinedValues<ILoadOperation>(emptyModel, LoadOperation)}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="pos-rel">
                            <AutoCompleteSelectField<ILoadOperation>
                                xName="description"
                                options={options}
                                label="Type"
                                placeholder={locContext.placeholderPackageType}
                                canAdd />
                            <NumberInputField<ILoadOperation>
                                xName="quantity"
                                label={locContext.quantity}
                                min={0}
                                step={1}
                                props={props} />
                            <NumberInputField<ILoadOperation>
                                xName="weightPerPackage"
                                label={locContext.placeholderWeightInKgPerPackage}
                                props={props} />
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <DimensionsInputField<ILoadOperation>
                                    xName="heightPerPackage"
                                    heightName="heightPerPackage"
                                    widthName="widthPerPackage"
                                    lengthName="lengthPerPackage"
                                    label={locContext.placeholderDimensions}
                                    props={props} />
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <NumberInputField<ILoadOperation>
                                    xName="heightPerPackage"
                                    label={locContext.heightPerPackage}
                                    props={props} />
                                <NumberInputField<ILoadOperation>
                                    xName="widthPerPackage"
                                    label={locContext.widthPerPackage}
                                    props={props} />
                                <NumberInputField<ILoadOperation>
                                    xName="lengthPerPackage"
                                    label={locContext.lengthPerPackage}
                                    props={props} />
                            </Responsive>
                            <div className={`df-row-ac blank-container-buttons ${modal ? 'jc-e' : 'jc-c'}`}>
                                <div className="df-row-ac">
                                    <TextLinkButton className="previous-button" onClick={onPrevious}>{modal ? locContext.cancel : locContext.previous}</TextLinkButton>
                                    <PrimaryButton rectanglePadding disabled={props.isSubmitting || props.isValid === false} showLoader={props.isSubmitting} type="submit">{!modal ? locContext.next : isEdit ? locContext.edit : locContext.add}</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}