import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { IFieldGroupProps, FieldGroup } from '../../common/forms/FieldGroup';
import './DimensionsInputField.scss';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { MakeAString, MakeANumber } from '../../../infrastructure/Utils';

interface IProps<T> extends IFieldGroupProps<T> {
    props: any,
    lengthName: keyof T;
    widthName: keyof T;
    heightName: keyof T;
}

export function DimensionsInputField<T>({ props, lengthName, widthName, heightName, ...rest }: IProps<T>) {
    const locContext = useLocalizationContext();
    const [l, setL] = useState<string>('');
    const [w, setW] = useState<string>('');
    const [h, setH] = useState<string>('');

    useEffect(() => {
        setL(MakeAString(props.values[lengthName]));
        setW(MakeAString(props.values[widthName]));
        setH(MakeAString(props.values[heightName]));
        // eslint-disable-next-line 
    }, [props]);

    const setValue = (name: string, val: number) => {
        props.setFieldValue(name, val, true);
        props.setFieldTouched(name, true, true);
    }

    return (
        <FieldGroup {...rest} xNames={[lengthName, widthName, heightName]}>
            <div className="df-row-ac jc-sb dimensions-field-container">
                <RenderField name={lengthName.toString()} placeholder={locContext.lengthPerPackage} val={l} setVal={setL} parseVal={setValue} />
                <RenderField name={widthName.toString()} placeholder={locContext.widthPerPackage} val={w} setVal={setW} parseVal={setValue} className="middle-element" />
                <RenderField name={heightName.toString()} placeholder={locContext.heightPerPackage} val={h} setVal={setH} parseVal={setValue} />
            </div>
        </FieldGroup>
    );
}

interface IRenderProps {
    name: string; 
    placeholder: string;
    val: string;
    setVal: (val: string) => any;
    parseVal: (name: string, val: number) => void;
    className?: string;
}

const RenderField = ({ name, placeholder, val, setVal, className, parseVal }: IRenderProps) => {
    return (
        <Field className={className} type="text" name={name} placeholder={placeholder} value={val} onChange={(e: any) => setVal(e.target.value)} onBlur={() => parseVal(name, MakeANumber(val))} />
    );
}