import React, { useContext, useState } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { NotificationsContext } from '../../context/NotificationsContext';
import { useCombineClassNames } from '../../hooks/useCombineClassNames';
import './NotificationsDropdown.scss';

interface IProps {
    className?: string;
}

export const NotificationsDropdown = ({ ...rest }: IProps) => {
    const className = useCombineClassNames('notifications-dropdown df-row jc-c', rest);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const context = useContext(NotificationsContext);

    // TODO -> unset!
    // if (context.unread === undefined || context.unread.length === 0) {
    //     return null;
    // }
    if (context.unread === undefined){
        return null;
    }

    return (
        <div className={className}>
            <Dropdown
                text={context.countUnread.toString()} floating labeled compact
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                onClick={() => setIsOpen(false)}
                pointing='right'>
                <Dropdown.Menu>
                    {context.unread.map(t =>
                        <Dropdown.Item key={t.id} onClick={() => context.show(t)}>{t.title}</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            {context.countUnread > 0
                ? <Label circular empty color='red' className="new-notifications-label"></Label>
                : null}
        </div>
    );
}