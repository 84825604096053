import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './ProgressBar.scss';
import { Responsive } from 'semantic-ui-react';

export const ProgressBar = () => {
    const context = useContext(TransportSubmitContext);
    const locContext = useLocalizationContext();

    const handleClick = (step: number) => {
        if (context.step > step && context.step !== 5) {
            context.goBackToStartOfStep(step);
        }
    }

    return (
        <div className="df-row-ac progress-bar">
            <Responsive minWidth={Responsive.onlyTablet.minWidth} className="df-row-ac">
                <Step description={locContext.vehicle} step={1} currentStep={context.step} onClick={() => handleClick(1)} progress={context.progress} />
                <Step description={locContext.load} step={2} currentStep={context.step} onClick={() => handleClick(2)} progress={context.progress} />
                <Step description={locContext.unload} step={3} currentStep={context.step} onClick={() => handleClick(3)} progress={context.progress} />
                <Step description={locContext.price} step={4} currentStep={context.step} onClick={() => handleClick(4)} progress={context.progress} />
                <Step description={locContext.overview} step={5} currentStep={context.step} last={true} onClick={() => handleClick(5)} progress={0} />
            </Responsive>
            <Responsive {...Responsive.onlyMobile} className="stretch-hor">
                <Rectangle currentStep={context.step} />
            </Responsive>
        </div>
    );
}

interface IRectangleProps {
    currentStep: number;
}
const Rectangle = ({ currentStep }: IRectangleProps) => {
    const progress = (currentStep / 5) * 100;
    var divStyle: React.CSSProperties = {
        width: progress + '%',
    }
    return (
        <div className="df-row-ac mobile-progress-bar-container">
            <div className="left-progress" style={divStyle}></div>
        </div>
    );
}

interface IOvalProps {
    step: number;
    currentStep: number;
    onClick: () => void;
}
const Oval = ({ step, currentStep, onClick }: IOvalProps) => {
    return (
        <div className={`df-row-ac jc-c oval${currentStep >= step ? ' busy' : ''}${currentStep > step && currentStep !== 5 ? ' can-click' : ''}`} onClick={onClick}>
            {currentStep > step ? <FontAwesomeIcon icon={['fas', 'check']} /> : step.toString()}
        </div>
    );
}

interface IStepProps extends IOvalProps {
    description: string;
    last?: boolean;
    progress?: number;
}
const Step = ({ description, last = false, progress, ...rest }: IStepProps) => {
    // WATCH OUT 45 is linked to css property
    progress = rest.currentStep > rest.step ? 100 : rest.currentStep < rest.step ? 0 : progress;
    var divStyle: React.CSSProperties = {
        width: progress ? (progress * 2 * 45) / 100 : 0,
        border: progress && progress > 0 ? '1.5px solid #4EB064' : 'none',
    }

    return (
        <div className="df-row step">
            <div className="df-col-ac step">
                <Oval {...rest} />
                <div className="description">{description}</div>
            </div>
            {last ? null :
                <div className="line"></div>
            }
            <div className="progress-line" style={divStyle} />
            {progress && progress > 0 ?
                <div className="progress-line" style={{...divStyle, backgroundColor: '#4EB064' }} />
                : null}
        </div>
    );
}