import React, { useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import "../../common/modal/BaseConfirmation.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {
    confirm: () => void | Promise<any>;
    cancel: () => void;
}

export const BookingOutOfOfficeHoursConfirmation = ({ confirm, cancel, ...rest }: IProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const locContext = useLocalizationContext();

    const submit = async () => {
        setIsSubmitting(true);
        await confirm();
        setIsSubmitting(false);
    }

    return (
        <div className={useCombineClassNames('df-col stretch-ver confirmation-modal', rest)}>
            <div className="df-col confirmation-modal-inner">
                <div className="mc-title">{locContext.warningBookOutsideOfficeHours}</div>
                <div className="mc-description">{locContext.bookOutsideOfficeHoursContent}</div>
                <div style={{ fontWeight: 'bold' }}>{locContext.phoneNumber}</div>
                <div className="df-row-ac jc-sb" style={{ marginTop: '2px', marginBottom: '24px' }}>
                    <div>0800 32 233</div>
                    <div>{locContext.available247}</div>
                </div>
            </div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-e">
                    <div className="df-row-ac">
                        <PrimaryButton disabled={isSubmitting} showLoader onClick={() => submit()}>{locContext.okUnderstood}</PrimaryButton>
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-col">
                    <PrimaryButton className="stretch-hor" onClick={() => confirm()}>{locContext.okUnderstood}</PrimaryButton>
                </div>
            </Responsive>
        </div>
    );
}