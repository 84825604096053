import React, { useEffect, useState } from 'react';
import { AddressesClient, IAddressesQueryParams } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { ITrigger } from '../../../hooks/useTrigger';
import { FilterBar } from '../../common/filter/FilterBar';
import { StringListFilterItem } from '../../common/filter/StringListFilterItem';

interface IProps {
    params: IAddressesQueryParams;
    onFilter: (params: IAddressesQueryParams) => void;
    trigger: ITrigger;
}

function isFiltered(pars: IAddressesQueryParams) {
    if (!pars) {
        return false;
    }
    if ((pars.companies && pars.companies.length > 0) || (pars.contacts && pars.contacts.length > 0)) {
        return true;
    }
    return false;
}

export const AddressesFilter = ({ params, onFilter, trigger }: IProps) => {
    const locContext = useLocalizationContext();
    const [companies, setCompanies] = useState<string[]>([]);
    const [contacts, setContacts] = useState<string[]>([]);
    const client = useClient(AddressesClient);

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    [trigger]);
    const load = async () => {
        setCompanies(await client.companies());
        setContacts(await client.contacts());
    }
    const handleClear = () => {
        onFilter({ ...params, contacts: [], companies: [] });
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac df-col-if-mobile">
                <StringListFilterItem
                    icon={['fas', 'industry']}
                    label={locContext.company}
                    options={companies}
                    selection={params.companies ? params.companies : []}
                    onFilter={(val) => onFilter({ ...params, companies: val })} />
                <StringListFilterItem
                    icon={['fas', 'users']}
                    label={locContext.contact}
                    options={contacts}
                    selection={params.contacts ? params.contacts : []}
                    onFilter={(val) => onFilter({ ...params, contacts: val })} />
            </div>
        </FilterBar>
    );
}