import React, { useState } from 'react';
import { empty } from '../infrastructure/Utils';
import { IConfirmedTransportsQueryParams } from '../gen/ApiClients';

interface IProps {
    params: IConfirmedTransportsQueryParams;
    setParams: (pars: IConfirmedTransportsQueryParams) => void;
}

const fallbackProps: IProps = {
    params: {},
    setParams: empty,
}

export const ConfirmedTransportsQueryParamsContext = React.createContext<IProps>(fallbackProps);

export const ConfirmedTransportsQueryParamsProvider = ({ children }: any) => {
    const [params, setParams] = useState<IConfirmedTransportsQueryParams>(fallbackProps.params);

    return (
        <ConfirmedTransportsQueryParamsContext.Provider
            value={{
                params: params,
                setParams: setParams,
            }}>
            {children}
        </ConfirmedTransportsQueryParamsContext.Provider>
    );
}