import React, { useState, useEffect } from 'react';
import { IFieldGroupProps, FieldGroup } from './FieldGroup';
import { Field } from 'formik';
import { MakeANumber, MakeAString } from '../../../infrastructure/Utils';

interface IProps<T> extends IFieldGroupProps<T> {
    props: any;
    min?: number;
    max?: number;
    step?: number;
}

export function NumberInputField<T>({ props, min, max, step, ...rest }: IProps<T>) {
    const [val, setVal] = useState<string>('');

    useEffect(() => {
        setVal(MakeAString(props.values[rest.xName]));
        // eslint-disable-next-line 
    }, [props]);

    const setValue = (val: number) => {
        props.setFieldValue(rest.xName, val, true);
        props.setFieldTouched(rest.xName, true, true);
    }

    return (
        <FieldGroup {...rest}>
            <Field name={rest.xName} type="text" value={val} onChange={(e: any) => setVal(e.target.value)} onBlur={() => setValue(MakeANumber(val))} />
        </FieldGroup>
    );
}

