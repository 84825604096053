import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { SelectField } from '../../../common/forms/SelectField';
import { TextLinkButton } from '../../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { IOption } from '../../../../infrastructure/Models';
import { setFieldTouched } from '../../../common/forms/FormsUtils';
import { IAddressDetail } from '../../../../gen/ApiClients';
import * as yup from 'yup';
import './SelectFromAddressBookComponent.scss';

interface ISelectAddressBookModel {
    id: string | undefined;
}

interface IProps {
    id: string | undefined;
    modal?: boolean;
    addresses: IAddressDetail[];
    onPrevious: () => void;
    submit: (id: string) => void;
    previousText: string;
    onEdit: (address: IAddressDetail) => void;
}

export const SelectFromAddressBookComponent = ({ id, modal = false, addresses, onPrevious, submit, previousText, onEdit }: IProps) => {
    const options = addresses.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.companyAndFormatted ? t.companyAndFormatted : '' }));
    const locContext = useLocalizationContext();

    const selectValidateSchema = yup.object<ISelectAddressBookModel>().shape<ISelectAddressBookModel>({
        id: yup.string()
            .required(locContext.selectAnAddress),
    });

    const localSubmit = (e: any, props: FormikProps<ISelectAddressBookModel>) => {
        e.preventDefault();
        setFieldTouched<ISelectAddressBookModel>('id', props);
        if (props.values.id !== undefined) {
            submit(props.values.id);
        }
    }

    const renderAddressDetails = (id: string) => {
        const x = addresses.find(t => t.id === id);
        if (x === undefined) {
            return null;
        } else {
            return (
                <div className="df-row jc-sb">
                    <div className="df-col selected-address fg1">
                        <div className="font-bold">{locContext.addressDetails}</div>
                        <div>{locContext.company}: {x.company}</div>
                        <div>{locContext.address}: {x.formatted}</div>
                        <div>{locContext.labelAdditionalInfo}: {x.additionalInfo}</div>
                        <div>{locContext.contactPerson}: {x.contact}</div>
                        <div>{locContext.phoneNumber}: {x.phoneNumber}</div>
                        <div>{locContext.comments}: {x.comment}</div>
                    </div>
                    <div>
                        <TextLinkButton gray className="stop-button" onClick={() => onEdit(x)}>{locContext.change}</TextLinkButton>
                    </div>
                </div>
            );
        }
    }

    return (
        <Formik<ISelectAddressBookModel>
            initialValues={{ id: id }}
            validationSchema={selectValidateSchema}
            onSubmit={() => { }}>
            {(props) => (
                <Form className="select-from-addressbook-container">
                    <SelectField<ISelectAddressBookModel> options={options} label={locContext.labelAddressBook} xName="id" />
                    {props.values.id ?
                        renderAddressDetails(props.values.id)
                        : null}
                    <div className={`df-row-ac blank-container-buttons ${modal ? 'jc-e' : 'jc-c'}`}>
                        <div className="df-row-ac">
                            <TextLinkButton className="previous-button" onClick={onPrevious}>{previousText}</TextLinkButton>
                            <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={e => localSubmit(e, props)}>{locContext.next}</PrimaryButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}