import React, { useContext } from 'react';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { StopsViewComponent } from '../common/StopsViewComponent';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';

interface IProps {
    onPrevious: () => void;
    onNext: () => void;
}

export const Overview = ({ onPrevious, onNext }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportSubmitContext);

    return (
        <div className="blank-container">
            <div className="header-text">{locContext.price}: {locContext.checkYourData}</div>
            <StopsViewComponent 
                firstStop={context.state.firstStop}
                extraStops={context.state.extraStops}
                lastStop={context.state.lastStop}
                showDividers
                addTopMargin={false}
                hideLastDivider
                formatStopAsLabel={false}/>
            <div className="df-row-ac jc-c blank-container-buttons">
                <div className="df-row-ac">
                    <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                    <PrimaryButton rectanglePadding onClick={onNext}>{locContext.next}</PrimaryButton>
                </div>
            </div>
        </div>
    );
}