import React, { useContext, useEffect, useState } from 'react';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { Card, Responsive } from 'semantic-ui-react';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StopsViewComponent } from '../common/StopsViewComponent';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { ITransportDetail, IValidateTimingsResponse, IConfirmResult } from '../../../gen/ApiClients';
import { ErrorScreen } from '../common/ErrorScreen';
import { SaveAsTemplateForm } from '../common/SaveAsTemplateForm';
import { TransportSubmitModalContext } from '../../../context/TransportSubmitModalContext';
import * as routes from '../../../routes';
import './Step5.scss';

export const Step5 = () => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportSubmitContext);
    const [transport, setTransport] = useState<ITransportDetail | undefined>();
    const [isSavedAsTemplate, setIsSavedAsTemplate] = useState<boolean>(false);
    const modalContext = useContext(TransportSubmitModalContext);

    useEffect(() => {
        if (transport) {
            setTransport(undefined);
        }
        if (context.confirmResult && context.confirmResult.fileId && context.confirmResult.hasConfirmError === false && context.confirmResult.hasTimingsError === false) {
            loadTransport(context.confirmResult.fileId);
        }
    }, 
    // eslint-disable-next-line 
    [context.confirmResult]);

    const loadTransport = async (fileId: string) => {
        setTransport(await context.loadDetail(fileId));
    }

    const onSaveAsTemplate = () => {
        modalContext.open(
            <SaveAsTemplateForm
                cancel={() => modalContext.close()}
                submit={async (model) => {
                    await context.saveAsTemplate(model);
                    setIsSavedAsTemplate(true);
                    modalContext.close();
                }} />, false);
    }

    const resolveError = (valResponse: IValidateTimingsResponse | undefined) => {
        if (valResponse === undefined || valResponse.hasLeadTimeError) {
            context.goBackToStartOfStep(2);
        } else if (valResponse.hasExtraStopError) {
            context.goBackToStep(2);
        } else if (valResponse.hasLastStopError) {
            context.goBackToStep(3);
        } else {
            context.goBackToStartOfStep(2);
        }
    }

    const renderContent = (confirmResult: IConfirmResult, transport: ITransportDetail) => {
        return (
            <div className="df-col">
                <div className="font-bold">{locContext.yourOrderNumber}</div>
                <div>{confirmResult.fileId}</div>
                <div className="descr">{locContext.price}</div>
                <div>&euro; {transport.amount}</div>
                <div className="price-comment">{locContext.excludingWaitingTimes}</div>
                <div className="price-comment">{locContext.contentExtraCosts}</div>
                <div className="descr">{locContext.department}</div>
                <div>{transport.department}</div>
                <StopsViewComponent
                    firstStop={context.state.firstStop}
                    extraStops={context.state.extraStops}
                    lastStop={context.state.lastStop}
                    showDividers={false}
                    addTopMargin
                    formatStopAsLabel={false} />
            </div>
        );
    }

    if (!context.confirmResult || !context.state) {
        return null;
    }
    else if (context.confirmResult.hasTimingsError) {
        return (
            <div className="blank-container step5-container">
                <div className="header-text">{locContext.errorTimingsInYourTransport}</div>
                <Card className="step5-card">
                    <div className="df-col">
                        <div className="font-error error-msg">{context.confirmResult.timingsErrorMessage}</div>
                        <TextLinkButton className="resolve-button" onClick={() => resolveError(context.confirmResult ? context.confirmResult.validateTimingsResponse : undefined)}>{locContext.clickHereToResolve}</TextLinkButton>
                    </div>
                </Card>
            </div>
        );
    }
    else if (context.confirmResult.hasConfirmError) {
        return <ErrorScreen />
    } else if (transport === undefined || transport === null) {
        return null;
    } else {
        return (
            <div className="blank-container step5-container">
                <div className="header-text">{locContext.yourTransportInputIsSuccess}</div>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Card className="step5-card">
                        {renderContent(context.confirmResult, transport)}
                    </Card>
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                    {renderContent(context.confirmResult, transport)}
                </Responsive>
                <div className="df-row-ac jc-c stretch-hor save-as-template-button">
                    {isSavedAsTemplate === false ?
                        <TextLinkButton onClick={() => onSaveAsTemplate()}>
                            <div className="df-row-ac">
                                <div className="save-as-template-text">{locContext.saveAsTemplate}</div>
                                <FontAwesomeIcon icon={['far', 'star']} />
                            </div>
                        </TextLinkButton>
                        : <div className="df-row-ac">
                            <div className="save-as-template-text">{locContext.storedAsTemplate}</div>
                            <FontAwesomeIcon className="stored-as-template-icon" icon={['fas', 'star']} />
                        </div>}

                </div>
                <div className="df-row-ac jc-c blank-container-buttons">
                    <div className="df-row-ac">
                        <Link to={routes.DashboardRoute}>
                            <TextLinkButton className="previous-button">{locContext.backToDashboard}</TextLinkButton>
                        </Link>
                        <Link to={routes.NewTransportRoute}>
                            <PrimaryButton rectanglePadding>{locContext.newTransport}</PrimaryButton>
                        </Link>
                    </div>
                </div>
            </div>);
    }
}