import React, { useContext, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { TransportSubmitContext } from '../../../../context/TransportSubmitContext';
import { ILoadOperation, ILoadOperationInCargo, IUnloadOperationModel, IValidateResponse } from '../../../../gen/ApiClients';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../../common/buttons/TextLinkButton';
import { LoadOperationComponent } from '../../common/LoadOperationComponent';
import { LoadOperationViewComponent, UnloadOperationViewComponent } from '../LoadOperations';
import './CargoOperations.scss';
import { UnloadOperationComponent } from './UnloadOperationComponent';

enum stateOption {
    Overview,
    Load,
    Unload,
}

function calculateLoadOperationsInCargo(packagesInCargo: ILoadOperationInCargo[] | undefined, unloadOperations: IUnloadOperationModel[] | undefined): ILoadOperationInCargo[] {
    if (!packagesInCargo) {
        return [];
    }
    if (!unloadOperations || unloadOperations.length === 0) {
        return packagesInCargo;
    }
    return packagesInCargo.filter(x => unloadOperations.map(t => t.loadId).includes(x.loadId) === false);
}

interface IProps {
    header: string;
    loadOperations: ILoadOperation[] | undefined;
    unloadOperations: IUnloadOperationModel[] | undefined;
    onPrevious: () => void;
    onAddStop: () => void;
    addLoad: (load: ILoadOperation) => void;
    addUnload: (unload: IUnloadOperationModel) => void;
    deleteUnload: (index: number) => void;
    deleteLoad: (index: number) => void;
    validate: (loadOp: ILoadOperation) => Promise<IValidateResponse>;
}
export const CargoOperations = ({ validate, header, loadOperations, unloadOperations, onPrevious, onAddStop, addLoad, addUnload, deleteUnload, deleteLoad }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportSubmitContext);
    const [state, setState] = useState<stateOption>(stateOption.Overview);
    const [showError, setShowError] = useState<boolean>(false);
    const newLoadOperation = () => {
        setShowError(false);
        setState(stateOption.Load);
    }
    const newUnloadOperation = () => {
        setShowError(false);
        setState(stateOption.Unload);
    }
    const loadOperationsInCargo = calculateLoadOperationsInCargo(context.state.loadOperationsInCargo, unloadOperations);

    const onSubmit = () => {
        setShowError(false);
        if ((loadOperations === undefined || loadOperations.length === 0) && (unloadOperations === undefined || unloadOperations.length === 0)) {
            setShowError(true);
        }
        else {
            onAddStop();
        }
    }

    const renderOverview = () => {
        return (
            <div className="blank-container cargo-ops-container stretch-ver df-col">
                <div className="header-text">{header}</div>
                <div className="df-col fg1">
                    {loadOperations && loadOperations.length > 0 ?
                        <div className="df-col">
                            <div className="load-or-unload-text">{locContext.load}:</div>
                            {loadOperations.map(t =>
                                <div key={loadOperations.indexOf(t)} className="df-col">
                                    <LoadOperationViewComponent
                                        loadOperation={t}
                                        onDelete={() => deleteLoad(loadOperations.indexOf(t))} />
                                    <Divider />
                                </div>)}
                        </div>
                        : null}
                    {unloadOperations && unloadOperations.length > 0 ?
                        <div className="df-col">
                            <div className="load-or-unload-text">{locContext.unload}:</div>
                            {unloadOperations.map(t =>
                                <div key={unloadOperations.indexOf(t)} className="df-col">
                                    <UnloadOperationViewComponent
                                        unloadOperation={t}
                                        loadOperationsInCargo={context.state.loadOperationsInCargo}
                                        onDelete={() => deleteUnload(unloadOperations.indexOf(t))} />
                                    <Divider />
                                </div>)}
                        </div>
                        : null}
                    <TextLinkButton className="font-bold add-button" onClick={newLoadOperation}>+ {locContext.loadNewPackage}</TextLinkButton>
                    <TextLinkButton className="font-bold add-button" onClick={newUnloadOperation} disabled={loadOperationsInCargo.length === 0}>+ {locContext.unloadPackage}</TextLinkButton>
                    {showError && <span className="error-message">{locContext.errorNoCargoOperation}</span>} 
                </div>
                <div className="df-row-ac jc-e blank-container-buttons">
                    <div className="df-row-ac">
                        <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                        {/* <PrimaryButton rectanglePadding disabled={!loadOperations && !unloadOperations} onClick={onAddStop}>{locContext.addStop}</PrimaryButton> */}
                        <PrimaryButton rectanglePadding disabled={context.isSubmitting} showLoader onClick={onSubmit}>{locContext.addStop}</PrimaryButton>
                    </div>
                </div>
            </div>
        );
    }

    const renderLoad = () => {
        return <LoadOperationComponent
            header={header}
            loadOperation={{}}
            onSubmit={(x) => { addLoad(x); setState(stateOption.Overview); }}
            onPrevious={() => setState(stateOption.Overview)}
            modal
            isEdit={false}
            validate={validate}
            packageOptions={context.packages} />
    }

    const renderUnload = () => {
        return <UnloadOperationComponent
            loadOperationsInCargo={loadOperationsInCargo}
            onSubmit={(x) => { addUnload(x); setState(stateOption.Overview); }}
            header={header}
            onPrevious={() => setState(stateOption.Overview)} />
    }

    return (
        state === stateOption.Overview ? renderOverview() : state === stateOption.Load ? renderLoad() : renderUnload()
    );
}
