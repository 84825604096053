import React, { useState } from 'react';
import {
	AddressModel,
	IAddressModel,
	IExtraStopModel,
	ILoadOperation,
	IUnloadOperationModel,
	LoadOperation,
	TransportValidateClient,
	UnloadOperationModel,
	ValidateTimingsModel,
} from '../../../../gen/ApiClients';
import { useClient } from '../../../../hooks/useClient';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { AddressComponent } from '../../common/addresses/AddressComponent';
import { DateComponent } from '../../common/DateComponent';
import { validateCapacity } from '../../common/Utils';
import { CargoOperations } from './CargoOperations';

interface IProps {
	transportId: string;
	stopId?: string | undefined;
	stop?: IExtraStopModel;
	onCancel: () => void;
	onSubmit: (model: IExtraStopModel) => void;
}
export const ExtraStop = ({ transportId, stopId, stop, onCancel, onSubmit }: IProps) => {
	const validateClient = useClient(TransportValidateClient);
	const locContext = useLocalizationContext();
	const [subStep, setSubStep] = useState<number>(0);
	const [model, setModel] = useState<IExtraStopModel | undefined>(stop);

	const handleSetAddress = (id: string | undefined, address: IAddressModel | undefined, mustSave: boolean) => {
		setModel({ ...model, fromAddressBook: id, address: new AddressModel(address), mustSaveAddress: mustSave });
		setSubStep(1);
	};
	const handleSetDate = (date: Date, specifier: string) => {
		setModel({ ...model, date: date, dateSpecifier: specifier });
		setSubStep(2);
	};
	const handleAddUnloadOp = (unload: IUnloadOperationModel) => {
		const x = model && model.unloadOperations ? [...model.unloadOperations, new UnloadOperationModel(unload)] : [new UnloadOperationModel(unload)];
		setModel({ ...model, unloadOperations: x });
	};
	const handleAddLoadOp = (loadOp: ILoadOperation) => {
		const x = model && model.loadOperations ? [...model.loadOperations, new LoadOperation(loadOp)] : [new LoadOperation(loadOp)];
		setModel({ ...model, loadOperations: x });
	};
	const handleDeleteLoadOp = (index: number) => {
		if (!model || !model.loadOperations) {
			return;
		}
		const x = [...model.loadOperations.slice(0, index), ...model.loadOperations.slice(index + 1)];
		setModel({ ...model, loadOperations: x });
	};
	const handleDeleteUnload = (index: number) => {
		if (!model || !model.unloadOperations) {
			return;
		}
		const x = [...model.unloadOperations.slice(0, index), ...model.unloadOperations.slice(index + 1)];
		setModel({ ...model, unloadOperations: x });
	};
	const handleAddStop = () => {
		if (model) {
			onSubmit(model);
		}
	};

	const validateTimings = async (date: Date, spec: string) => {
		return await validateClient.validateTimingExtraStop(
			transportId,
			new ValidateTimingsModel({
				date: date,
				dateSpecifier: spec,
				stopId: stopId,
				fromAddressBook: model ? model.fromAddressBook : undefined,
				address: model?.address,
			})
		);
	};

	if (subStep === 0) {
		return (
			<AddressComponent
				header={`${locContext.stopover} 1/3: ${locContext.addressDetails}`}
				mustSave={stop && stop.mustSaveAddress !== undefined ? stop.mustSaveAddress : false}
				defaultLoadAddress={undefined}
				address={stop ? stop.address : undefined}
				fromAddressBookId={stop ? stop.fromAddressBook : ''}
				onPrevious={() => onCancel()}
				submit={handleSetAddress}
				modal
				previousText={locContext.cancel}
			/>
		);
	} else if (subStep === 1 && model) {
		return (
			<DateComponent
				header={`${locContext.stopover} 2/3: ${locContext.date} & ${locContext.time}`}
				date={model && model.date ? model.date : new Date()}
				setTime={false}
				specifier={model && model.dateSpecifier ? model.dateSpecifier : 'Before'}
				onSubmit={handleSetDate}
				onPrevious={() => setSubStep(subStep - 1)}
				validate={(date, spec) => validateTimings(date, spec)}
				modal
			/>
		);
	} else if (subStep === 2) {
		return (
			<CargoOperations
				header={`${locContext.stopover} 3/3: ${locContext.load} & ${locContext.unload}`}
				loadOperations={model ? model.loadOperations : undefined}
				unloadOperations={model ? model.unloadOperations : undefined}
				onPrevious={() => setSubStep(subStep - 1)}
				onAddStop={() => handleAddStop()}
				addLoad={handleAddLoadOp}
				addUnload={handleAddUnloadOp}
				deleteUnload={handleDeleteUnload}
				deleteLoad={handleDeleteLoadOp}
				validate={loadOp => validateCapacity(validateClient, transportId, stopId, model, loadOp)}
			/>
		);
	}
	return <div></div>;
};
