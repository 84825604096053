import React from 'react';
import { Header } from './header/Header';
import { StartSelect } from './start-select/StartSelect';
import { TransportSubmitProvider } from '../../context/TransportSubmitContext';
import { Switch, Route } from 'react-router';
import { InnerWrapper } from './InnerWrapper';
import * as routes from '../../routes';
import { TransportSubmitModalProvider } from '../../context/TransportSubmitModalContext';

export const OuterWrapper = () => {
    return (
        <div className="df-col-ac stretch-ver">
            <TransportSubmitProvider>
                <TransportSubmitModalProvider>
                    <Header />
                    <Switch>
                        <Route path={routes.CreatedTransportRoute} component={InnerWrapper} />
                        <Route path={routes.NewTransportRoute} component={StartSelect} />
                    </Switch>
                </TransportSubmitModalProvider>
            </TransportSubmitProvider>
        </div>
    );
}
