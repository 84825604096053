import React, { useState, useEffect } from 'react';
import { UserClient, IUserInfo, BuildClient } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { Divider, Responsive } from 'semantic-ui-react';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { clearCacheAndReloadApplication } from '../../../infrastructure/Utils';
import { build } from '../../../build';
import { LanguageDropDown } from './LanguageDropdown';
import './Settings.scss';

export const Settings = () => {
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    const [currentBuild, setCurrentBuild] = useState<number>();
    const [isOutdated, setIsOutdated] = useState<boolean>(false);
    const client = useClient(UserClient);
    const buildClient = useClient(BuildClient);

    useEffect(() => {
        load();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => {
        if (currentBuild === undefined) {
            setIsOutdated(false);
        } else if (build < currentBuild) {
            setIsOutdated(true)
        } else {
            setIsOutdated(false);
        }
    }, [currentBuild]);

    const load = async () => {
        setUserInfo(await client.info());
        setCurrentBuild(await buildClient.current());
    }

    return (
        <div className="df-col">
            <div className="language-label">Taal/Language/Langue</div>
            <div>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <LanguageDropDown isMobile={false} />
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                    <LanguageDropDown isMobile={true} />
                </Responsive>
            </div>
            <Divider />
            <div>ContactId: {userInfo ? userInfo.contactId : ''}</div>
            <div>ClientId: {userInfo ? userInfo.clientId : ''}</div>
            <div>Name: {userInfo ? userInfo.name : ''}</div>
            <div>CompanyName: {userInfo ? userInfo.companyName : ''}</div>
            <Divider />
            <div>Current-Build: {currentBuild}</div>
            <div>Loaded-Build: {build}</div>
            {isOutdated ?
                <div className="font-error">You're using an old version of this application. Click Ctrl+F5 to load the latest version.</div>
                : <div className="font-success">Application is up-to-date!</div>}
            {isOutdated ?
                <div className="df-row-ac update-button">
                    <div>
                        <PrimaryButton small rectanglePadding onClick={() => clearCacheAndReloadApplication()}>Update application</PrimaryButton>
                    </div>
                </div> : null}
        </div>
    );
}