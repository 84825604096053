import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { AddressModel, IAddressDetail, IAddressModel, ISuggestion } from '../../../../gen/ApiClients';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { clearNullOrUndefinedValues } from '../../../../infrastructure/Utils';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../../common/buttons/TextLinkButton';
import { AddressTypeAheadField } from '../../../common/forms/AddressTypeAheadField';
import { setFieldTouched } from '../../../common/forms/FormsUtils';
import { TextareaField } from '../../../common/forms/TextareaField';
import { TextInputField } from '../../../common/forms/TextInputField';
import { ToggleCheckbox } from '../../../common/ToggleCheckbox';
import { createValidateSchemaWithHouseNumber, createValidateSchemaWithoutHouseNumber, setFields } from '../../../dashboard/addresses/AddressUtils';
import './AddressFormComponent.scss';
import { anyNullValuesOnAddress } from './anyNullValuesOnAddress';

interface IProps {
    address: IAddressDetail | undefined;
    defaultMustSave: boolean;
    defaultLoadAddress: IAddressDetail | undefined;
    modal?: boolean;
    previousText: string;
    onPrevious: () => void;
    submit: (model: IAddressModel, mustSave: boolean) => void;
}
export const AddressFormComponent = ({ address, defaultMustSave, defaultLoadAddress, modal, previousText, onPrevious, submit }: IProps) => {
    const locContext = useLocalizationContext();
    const [mustSave, setMustSave] = useState<boolean>(false);
    const [isValidatedOnce, setIsValidatedOnce] = useState<boolean>(false);
    const [useDefaultLoadAddress, privateSetUseDefaultLoadAddress] = useState<boolean>(false);
    const [hasSelAddressError, setHasSelAddressError] = useState<boolean>(false);
    const [hasNullValuesError, setHasNullValuesError] = useState<boolean>(false);
    const initValues = clearNullOrUndefinedValues<IAddressModel>(address, AddressModel);
    const [selAddress, setSelAddress] = useState<ISuggestion | undefined>(undefined);
    const validateSchema = selAddress && !selAddress.isExact
        ? createValidateSchemaWithHouseNumber(locContext)
        : createValidateSchemaWithoutHouseNumber(locContext);
    const addressError = useMemo(() => hasSelAddressError ? locContext.selectAnAddress : hasNullValuesError ? locContext.errorNullValuesAddress : undefined, [hasNullValuesError, hasSelAddressError, locContext]);

    useEffect(() => {
        if (address) {
            setSelAddress(address);
        }
    }, [address]);

    useEffect(() => {
        if (mustSave !== defaultMustSave) {
            setMustSave(defaultMustSave);
        }
        // eslint-disable-next-line
    }, [defaultMustSave]);

    const setUseDefaultLoadAddress = (val: boolean, props: FormikProps<IAddressModel>) => {
        privateSetUseDefaultLoadAddress(val);
        if (val && defaultLoadAddress) {
            props.setValues(defaultLoadAddress);
            setSelAddress(defaultLoadAddress);
        } else {
            props.setValues(initValues);
            setSelAddress(undefined);
        }
    }

    const localSubmit = async (props: FormikProps<IAddressModel>, e: any) => {
        e.preventDefault();
        props.setSubmitting(true);
        setFieldTouched<IAddressModel>('company', props);
        setFieldTouched<IAddressModel>('contact', props);
        setFieldTouched<IAddressModel>('additionalInfo', props);
        setFieldTouched<IAddressModel>('notFoundHouseNumber', props);
        if (selAddress) {
            setIsValidatedOnce(true);
        }
        var isValid = await validateSchema.isValid(props.values);
        if (selAddress === undefined) {
            setHasSelAddressError(true);
        } else if (anyNullValuesOnAddress(selAddress)) {
            setHasNullValuesError(true);
        } else if (props.isValid || (props.dirty === false && isValid)) {
            const x = setFields(props.values, selAddress);
            await submit(x, mustSave);
        }
        props.setSubmitting(false);
    }

    return (
        <Formik<IAddressModel>
            initialValues={initValues}
            validationSchema={validateSchema}
            onSubmit={() => { }}>
            {(props) => (
                <Form>
                    <div>
                        {defaultLoadAddress &&
                            <div className="df-row-ac jc-sb use-default-load-address-container">
                                <div className="use-default-load-address-title">{locContext.useDefaultLoadAddress}</div>
                                <ToggleCheckbox externalProps={{ value: useDefaultLoadAddress, setValue: (val) => setUseDefaultLoadAddress(val, props) }} />
                            </div>}
                        <TextInputField<IAddressModel> label={locContext.companyName} xName="company" placeholder={locContext.placeholderCompany} />
                        <AddressTypeAheadField<IAddressModel>
                            label={locContext.labelStreetNumberCityAndCode}
                            xName="street"
                            placeholder={locContext.placeholderAddress}
                            warningMessage={locContext.alsoFillInAHouseNumber}
                            value={selAddress}
                            onValueChanged={(val) => {
                                if (val) {
                                    setHasSelAddressError(false);
                                    setHasNullValuesError(false);
                                }
                                setSelAddress(val);
                            }}
                            overrideError={addressError} />
                        {selAddress && selAddress.isExact === false && (isValidatedOnce || (address && address.notFoundHouseNumber)) &&
                            <TextInputField<IAddressModel> label={locContext.houseNumber} xName="notFoundHouseNumber" />}
                        <TextInputField<IAddressModel> label={locContext.labelAdditionalInfo} xName="additionalInfo" placeholder={locContext.placeholderAdditionalInfo} optional />
                        <TextInputField<IAddressModel> label={locContext.labelContactPerson} xName="contact" placeholder={locContext.placeholderContactPerson} />
                        <TextInputField<IAddressModel> label={locContext.phoneNumber} xName="phoneNumber" placeholder={locContext.placeholderPhonenumber} optional />
                        <TextareaField<IAddressModel> label={locContext.comments} xName="comment" optional />
                        <div className="df-row-ac jc-sb">
                            <div className="add-to-addressbook">{locContext.addToAddressBook}</div>
                            <ToggleCheckbox externalProps={{ value: mustSave, setValue: setMustSave }} />
                        </div>
                        <div className={`df-row-ac blank-container-buttons ${modal ? 'jc-e' : 'jc-c'}`}>
                            <div className="df-row-ac">
                                <TextLinkButton className="previous-button" onClick={onPrevious}>{previousText}</TextLinkButton>
                                <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => localSubmit(props, e)}>{locContext.next}</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}