import React, { useState, useEffect } from 'react';
import { empty } from '../infrastructure/Utils';
import { LanguageStore } from '../infrastructure/LanguageStore';
import { Strings } from '../localization/Strings';
import { IStrings } from '../localization/IStrings';

// documentation: 
// https://github.com/stefalda/react-localization#readme

interface ILocalizationProps {
    strings: IStrings;
    setLanguage: (lang: 'en' | 'nl' | 'fr') => void;
    getLanguage: () => 'en' | 'nl' | 'fr';
    formatString: (key: keyof IStrings, ...values: any) => string;
}

const fallback: ILocalizationProps = {
    strings: Strings,
    setLanguage: empty,
    getLanguage: () => 'en',
    formatString: () => '',
}

export const LocalizationContext = React.createContext<ILocalizationProps>(fallback);

export const LocalizationProvider = ({ children }: any) => {
    const [language, setLanguage] = useState<('en' | 'nl' | 'fr')>(LanguageStore.getAndParseToDefault());

    useEffect(() => {
        Strings.setLanguage(language);
    }, [language]);

    const overrideSetLanguage = (lang: 'en' | 'nl' | 'fr') => {
        setLanguage(lang);
        Strings.setLanguage(lang);
        LanguageStore.set(lang);
    }
    const getLanguage = (): "en" | "fr" | "nl" => {
        const x = Strings.getLanguage();
        if (x !== "en" && x !== "nl" && x !== "fr"){
            return "en";
        } else {
            return x;
        }
    }
    function formatString(str: keyof IStrings, ...values: Array<string>) : string {
        return Strings.formatString(Strings[str], ...values).toString();
    }

    return (
        <LocalizationContext.Provider
            value={{
                strings: Strings,
                setLanguage: overrideSetLanguage,
                getLanguage: getLanguage,
                formatString: formatString
            }}>
            {children}
        </LocalizationContext.Provider>
    );
}
