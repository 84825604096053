import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { RouterContext } from '../../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { DraftsClient, ITransportFlow } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { tryCatchWithLoading } from '../../../infrastructure/Utils';
import * as routes from '../../../routes';
import { StopsViewComponent } from '../../blank/common/StopsViewComponent';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import './DraftDetail.scss';

export const DraftDetail = ({ match }: any) => {
    const id = match.params.id;
    const locContext = useLocalizationContext();
    const client = useClient(DraftsClient);
    const [detail, setDetail] = useState<ITransportFlow>();
    const loaderContext = useContext(WrapperFullScreenLoadContext);
    const routerContext = useContext(RouterContext);
    useContext(DashboardSearchContext).hideAndShowGoBackButton();

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    [id]);

    const load = async () => {
        if (id) {
            const t = await tryCatchWithLoading(client.detail(id), loaderContext.setLoading, locContext.serverError);
            setDetail(t);
        }
    }

    const onEdit = (inst: ITransportFlow) => {
        if (!inst.id) {
            return;
        }
        routerContext.navigate(routes.calculateNewRoute(inst.id));
    }

    if (detail === undefined) {
        return null;
    }

    return (
        <Card className="draft-detail-card df-col">
            <div className="df-row jc-sb">
                <div>
                    <PrimaryButton small onClick={() => onEdit(detail)}><FontAwesomeIcon icon={['far', 'edit']} />{locContext.createFromDraft}</PrimaryButton>
                </div>
            </div>
            <div className="label">{locContext.department}</div>
            <div className="info">{detail.vehicleName}</div>
            <div className="label">{locContext.reference}</div>
            <div className="info">{detail.reference}</div>
            <div className="label">{locContext.costCenter}</div>
            <div className="info">{detail.costCenter}</div>
            <StopsViewComponent
                className="calc-stops-view"
                firstStop={detail.firstStop}
                extraStops={detail.extraStops}
                lastStop={detail.lastStop}
                showDividers={false}
                addTopMargin={false}
                hideLastDivider
                formatStopAsLabel={true} />
        </Card>
    );
}