import React from 'react';
import { IDepartmentSummary } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { ReactComponent as Logo1500 } from './icons/1500.svg';
import { ReactComponent as Logo450 } from './icons/450.svg';
import { ReactComponent as Logo800 } from './icons/800.svg';
import './Step1.scss';

interface IProps {
    vehicle: IDepartmentSummary;
    selected: boolean;
    onSelect: () => void;
    invalid: boolean;
}

export const Vehicle = ({ vehicle, selected, onSelect, invalid }: IProps) => {
    const locContext = useLocalizationContext();
    if (!vehicle) {
        return null;
    }
    return (
        <div className="df-col-ac vehicle-option-container">
            <div className={`df-row-ac vehicle-option-item${selected ? ' has-selected' : ''}${invalid ? ' invalid' : ''}`} onClick={() => onSelect()}>
                <div className="df-col-ac fg1">
                    {vehicle.name === '450' ? <Logo450 /> : vehicle.name === '800' ? <Logo800 /> : <Logo1500 />}
                    <div className="vehicle-name">{vehicle.name}</div>
                </div>
            </div>
            <div className="vehicle-description">{locContext.max} {vehicle.maxWeight}{locContext.kg}</div>
            <div className="vehicle-description">{vehicle.maxLength} x {vehicle.maxWidth} x {vehicle.maxHeight}</div>
            <div className="vehicle-description">({locContext.LxWxH_inMeters})</div>
        </div>
    );
}