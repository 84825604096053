import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../../context/ModalContext';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { ITransportDetail, TransportValidateClient } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { toDefaultFormat } from '../../../infrastructure/Utils';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { ErrorScreen } from '../common/ErrorScreen';
import { StopsViewComponent } from '../common/StopsViewComponent';
import { TransportAbroadConfirmation } from './TransportAbroadConfirmation';
import './Calculated.scss';

interface IProps {
    onPrevious: () => void;
    onNext: () => void;
}
export const Calculated = ({ onPrevious, onNext }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportSubmitContext);
    const [transport, setTransport] = useState<ITransportDetail | undefined>();
    const [hasError, setHasError] = useState<boolean>(false);
    const validateClient = useClient(TransportValidateClient);
    const modalContext = useContext(ModalContext);

    useEffect(() => {
        if (transport) {
            setTransport(undefined);
        }
        if (context.calculatedResult) {
            if (context.calculatedResult.hasError === true || context.calculatedResult.fileId === undefined) {
                setHasError(true);
            }
            else {
                loadTransport(context.calculatedResult.fileId);
            }
        }
    }, 
    // eslint-disable-next-line 
    [context.calculatedResult]);

    const loadTransport = async (fileId: string) => {
        setTransport(await context.loadDetail(fileId));
    }

    const onConfirmTransport = async () => {

        const r = await validateClient.isAnyAddressAbroad(context.state.id ? context.state.id : '');
        if (r) {
            modalContext.open(<TransportAbroadConfirmation
                confirm={async () => {
                    modalContext.close();
                    onNext();
                }}
                cancel={() => {
                    modalContext.close();
                    onNext();
                }}
            />, false);
        } else {
            onNext();
        }
    }

    if (!context.calculatedResult) {
        return null;
    } else if (hasError || context.calculatedResult.hasError) {
        return <ErrorScreen />
    } else if (transport === undefined || transport === null) {
        return null;
    } else {
        return (
            <div className="blank-container calculated-container">
                <div className="header-text">{locContext.price}: {locContext.confirmYourTransport}</div>
                <div className="label">{locContext.fileNumber}</div>
                <div className="info">{transport.fileNumber}</div>
                <div className="label">{locContext.date}</div>
                <div className="info">{toDefaultFormat(transport.date)}</div>
                <StopsViewComponent
                    className="calc-stops-view"
                    firstStop={transport.firstStop}
                    extraStops={transport.extraStops}
                    lastStop={transport.lastStop}
                    showDividers={false}
                    addTopMargin={false}
                    hideLastDivider
                    formatStopAsLabel={true} />
                <div className="label">{locContext.department}</div>
                <div className="info">{transport.department}</div>
                <div className="label">{locContext.price}</div>
                <div className="info">&euro; {transport.amount}</div>
                <div className="df-row">
                    {transport.costs ?
                        transport.costs.map(t =>
                            <div className="df-col single-price-calc" key={t.description}>
                                <div className="label">{t.description}</div>
                                <div className="info">&euro; {t.amount}</div>
                            </div>)
                        : null}
                </div>
                <div className="df-row">
                    {transport.discounts ?
                        transport.discounts.map(t =>
                            <div className="df-col single-price-calc" key={t.description}>
                                <div className="label">{t.description}</div>
                                <div className="info">&euro; {t.amount}</div>
                            </div>)
                        : null}
                </div>
                <div className="label">{locContext.route}</div>
                <div className="info">{transport.totalDistance} km</div>                
                <div className="df-row-ac jc-c blank-container-buttons">
                    <div className="df-row-ac">
                        <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                        <PrimaryButton disabled={context.isSubmitting} showLoader rectanglePadding onClick={onConfirmTransport}>{locContext.confirmTransport}</PrimaryButton>
                    </div>
                </div>
                <div className="df-row gen-cond-container">
                    <FontAwesomeIcon icon={['fas', 'info-circle']} className="gen-cond-icon" />
                    <div className="gen-cond">
                        {locContext.generalConditions}
                    </div>
                </div>
            </div>
        );
    }
}