import { Form, Formik, FormikActions } from 'formik';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Responsive } from 'semantic-ui-react';
import { AuthContext } from '../../context/AuthContext';
import { LocalizationContext } from '../../context/LocalizationContext';
import { RouterContext } from '../../context/RouterContext';
import { ILoginModel, LoginModel, UserClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { IOption } from '../../infrastructure/Models';
import { clearNullOrUndefinedValues, tryCatchWithLoading } from '../../infrastructure/Utils';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { FormDialog } from '../common/forms/FormDialog';
import { setFieldError, setFieldTouched } from '../common/forms/FormsUtils';
import { PasswordField } from '../common/forms/PasswordField';
import { SelectField } from '../common/forms/SelectField';
import { TextInputField } from '../common/forms/TextInputField';
import { Logo } from '../common/Logo';
import { LanguageDropDown } from '../dashboard/settings/LanguageDropdown';
import * as routes from '../../routes';
import * as yup from 'yup';
import './Login.scss';

export const Login = () => {
    const locContext = useLocalizationContext();
    const routerContext = useContext(RouterContext);
    const authContext = useContext(AuthContext);
    const client = useClient<UserClient>(UserClient);
    const [mustSelectBusinessUnit, setMustSelectBusinessUnit] = useState<boolean>(false);
    const [options, setOptions] = useState<IOption<string>[]>([]);
    const languageContext = useContext(LocalizationContext);
    const initialValues = clearNullOrUndefinedValues<ILoginModel>({ userName: '', companyName: '', password: '' }, LoginModel);

    const validateSchema = mustSelectBusinessUnit ?
        yup.object<ILoginModel>().shape<ILoginModel>({
            userName: yup
                .string()
                .required(locContext.validateUsername),
            companyName: yup
                .string()
                .required(locContext.validateCompanyName),
            password: yup
                .string()
                .required(locContext.validatePassword),
            businessUnitId: yup
                .string()
                .required(locContext.selectAClient),
        })
        : yup.object<ILoginModel>().shape<ILoginModel>({
            userName: yup
                .string()
                .required(locContext.validateUsername),
            companyName: yup
                .string()
                .required(locContext.validateCompanyName),
            password: yup
                .string()
                .required(locContext.validatePassword),
        });

    const submit = async (props: ILoginModel, actions: FormikActions<ILoginModel>) => {
        const loginResult = await tryCatchWithLoading(client.login(new LoginModel(props)), actions.setSubmitting, locContext.serverError);
        if (loginResult !== undefined && !loginResult.hasError && loginResult.token) {
            authContext.login(loginResult.token);
            routerContext.history.push('/dashboard');
        } else if (loginResult.mustChooseBusinessUnit) {
            setMustSelectBusinessUnit(true);
            setFieldTouched<ILoginModel>('businessUnitId', actions);
            const x = loginResult.businessUnits ? loginResult.businessUnits.map<IOption<string>>(t => ({ key: t.id ? t.id : '', text: t.value ? t.value : '', value: t.id ? t.id : '' })) : [];
            setOptions(x);
        } else {
            setMustSelectBusinessUnit(false);
            setFieldError<ILoginModel>('password', locContext.invalidCombinationUsernamePassword, actions);
        }
    }
    const onContactUs = (e: any) => {
        e.preventDefault();
        const lang = languageContext.getLanguage();
        const url = lang === 'nl'
            ? 'https://www.eurosprinters.be/word-klant/'
            : lang === 'en'
                ? 'https://www.eurosprinters.be/en/become-customer/'
                : 'https://www.eurosprinters.be/fr/devenir-client/';
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
    }

    return (
        <div className="df-col full-page-sheet">
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-sb login-header">
                    <Logo />
                    <div>
                        <span>{locContext.noAccount}? </span>
                        <TextLinkButton className="contact-us-button" underline onClick={onContactUs}>{locContext.contactUs}</TextLinkButton>
                        <LanguageDropDown isMobile={false} />
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-row-ac jc-c login-header">
                    <Logo />
                </div>
            </Responsive>
            <div className="df-col-ac login-body">
                <span className="welcome-text">{locContext.welcomeToMySprint}</span>
                <span className="sub-welcome-text">{locContext.loginWithUsernamePassword}</span>
                <FormDialog>
                    <Formik<ILoginModel>
                        initialValues={initialValues}
                        validationSchema={validateSchema}
                        onSubmit={submit}>
                        {(props) => (
                            <Form>
                                <div className="pos-rel">
                                    {mustSelectBusinessUnit ?
                                        <SelectField<ILoginModel> options={options} label={locContext.departmentOrBusinessUnit} xName="businessUnitId" />
                                        :
                                        <div>
                                            <TextInputField<ILoginModel> label={locContext.webCompany} xName="companyName" />
                                            <TextInputField<ILoginModel> label={locContext.webUser} xName="userName" />
                                            <PasswordField<ILoginModel> label={locContext.webPassword} xName="password" noMargin />
                                            <Link to={routes.ForgotPasswordRoute}>
                                                <TextLinkButton gray className="forgot-password-button">{locContext.forgotPassword}</TextLinkButton>
                                            </Link>
                                        </div>
                                    }
                                    <PrimaryButton disabled={props.isSubmitting} showLoader type="submit">{locContext.login}</PrimaryButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </FormDialog>
                <Responsive {...Responsive.onlyMobile} className="stretch-hor">
                    <div className="df-col-ac stretch-hor login-footer">
                        <div className="df-row-ac mobile-no-account">
                            <span>{locContext.noAccount}? </span>
                            <TextLinkButton underline onClick={onContactUs}>{locContext.contactUs}</TextLinkButton>
                        </div>
                        <LanguageDropDown isMobile={true} />
                    </div>
                </Responsive>
            </div>
        </div>
    );
}

