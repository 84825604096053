import { Form, Formik, FormikActions, FormikProps } from 'formik';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ModalContext } from '../../../context/ModalContext';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { IDepartmentSummary, IStep1Model, Step1Model } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { clearNullOrUndefinedValues } from '../../../infrastructure/Utils';
import * as routes from '../../../routes';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { TextInputField } from '../../common/forms/TextInputField';
import './Step1.scss';
import { Vehicle } from './Vehicle';
import { VehicleSizeConfirmation } from './VehicleSizeConfirmation';

export const Step1 = () => {
	const locContext = useLocalizationContext();
	const context = useContext(TransportSubmitContext);
	const [vehicleInvalid, setVehicleInvalid] = useState<boolean>(false);
	const modalContext = useContext(ModalContext);

	if (context.referenceInfo === undefined || context.selectableDepartments === undefined) {
		return null;
	}

	const initValues = clearNullOrUndefinedValues<IStep1Model>(
		{
			reference: context.state.reference ? context.state.reference : context.referenceInfo.defaultReference,
			costCenter: context.state.costCenter ? context.state.costCenter : context.referenceInfo.defaultCostCenter,
			vehicleName: context.state.vehicleName,
		},
		Step1Model
	);

	const submit = async (props: IStep1Model, actions: FormikActions<IStep1Model>) => {
		actions.setSubmitting(true);
		if (!props.vehicleName) {
			setVehicleInvalid(true);
		} else {
			await context.setStep1(props);
		}
		actions.setSubmitting(false);
	};

	const validateSchema = yup.object<IStep1Model>().shape<IStep1Model>({
		reference:
			context.referenceInfo.canChangeReference && context.referenceInfo.isReferenceRequired
				? yup.string().max(60, locContext.formatString('maxLengthValidation', 60)).required(locContext.validateReference)
				: yup.string().max(60, locContext.formatString('maxLengthValidation', 60)),
		costCenter:
			context.referenceInfo.canChangeCostCenter && context.referenceInfo.isCostCenterRequired
				? yup.string().max(30, locContext.formatString('maxLengthValidation', 30)).required(locContext.validateCostCenter)
				: yup.string().max(30, locContext.formatString('maxLengthValidation', 30)),
	});

	const onSelectVehicle = (props: FormikProps<IStep1Model>, vehicle: IDepartmentSummary) => {
		props.setValues({ ...props.values, vehicleName: vehicle.name });
		setVehicleInvalid(false);
		if (vehicle) {
			modalContext.open(
				<VehicleSizeConfirmation
					vehicle={vehicle}
					confirm={async () => modalContext.close()}
					cancel={() => modalContext.close()}
				/>,
				false
			);
		}
	};

	return (
		<div className='blank-container'>
			<div className='header-text'>
				{locContext.vehicle}: {locContext.chooseAType}
			</div>
			<Formik<IStep1Model>
				initialValues={clearNullOrUndefinedValues(new Step1Model(initValues), Step1Model)}
				validationSchema={validateSchema}
				onSubmit={submit}>
				{props => (
					<Form>
						<div className='form-input'>
							<TextInputField<IStep1Model>
								disabled={context.referenceInfo && context.referenceInfo.canChangeReference === false}
								label={locContext.reference}
								xName='reference'
							/>
							<TextInputField<IStep1Model>
								disabled={context.referenceInfo && context.referenceInfo.canChangeCostCenter === false}
								label={locContext.costCenter}
								xName='costCenter'
							/>
							<div className='df-row-ac jc-sb df-col-ac-if-mobile vehicle-options'>
								{context.selectableDepartments
									? context.selectableDepartments.map(t => (
											<Vehicle
												key={t.name}
												invalid={vehicleInvalid}
												vehicle={t}
												selected={props.values.vehicleName === t.name}
												onSelect={() => onSelectVehicle(props, t)}
											/>
									  ))
									: null}
							</div>
						</div>
						<div className='df-row-ac jc-c blank-container-buttons'>
							<div className='df-row-ac'>
								<Link to={routes.NewTransportRoute}>
									<TextLinkButton className='previous-button'>{locContext.previous}</TextLinkButton>
								</Link>
								<PrimaryButton
									rectanglePadding
									disabled={props.isSubmitting}
									showLoader
									type='submit'>
									{locContext.next}
								</PrimaryButton>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
