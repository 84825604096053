import React, { useContext } from 'react';
import { Divider } from 'semantic-ui-react';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { TransportSubmitModalContext } from '../../../context/TransportSubmitModalContext';
import { ILoadOperation, ILoadOperationInCargo, IUnloadOperationModel, IValidateResponse } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { BaseConfirmation } from '../../common/modal/BaseConfirmation';
import { LoadOperationComponent } from '../common/LoadOperationComponent';
import './LoadOperations.scss';

interface IProps {
    loadOperations: ILoadOperation[];
    add: (loadOp: ILoadOperation) => Promise<any>;
    update: (index: number, loadOp: ILoadOperation) => Promise<any>;
    del: (index: number) => Promise<any>;
    onPrevious: () => void;
    onNext: () => Promise<any>;
    validate: (loadOp: ILoadOperation) => Promise<IValidateResponse>;
}

export const LoadOperations = ({ validate, loadOperations, add, update, del, onPrevious, onNext }: IProps) => {
    const locContext = useLocalizationContext();
    const modalContext = useContext(TransportSubmitModalContext);
    const context = useContext(TransportSubmitContext);

    const onAddNew = () => {
        modalContext.open(
            <LoadOperationComponent
                packageOptions={context.packages}
                validate={validate}
                onPrevious={() => modalContext.close()}
                onSubmit={async (loadOp) => {
                    await add(loadOp);
                    modalContext.close();
                }}
                header={locContext.addExtraPackage}
                modal
                isEdit={false} />, false);
    }
    const onEdit = (loadOp: ILoadOperation) => {
        const index = loadOperations.indexOf(loadOp);
        modalContext.open(
            <LoadOperationComponent
                packageOptions={context.packages}
                validate={validate}
                onPrevious={() => modalContext.close()}
                onSubmit={async (updated) => {
                    await update(index, updated);
                    modalContext.close();
                }}
                header={locContext.editPackage}
                loadOperation={loadOp}
                modal
                isEdit />, false);
    }
    const onDelete = (loadOp: ILoadOperation) => {
        const index = loadOperations.indexOf(loadOp);
        modalContext.open(
            <BaseConfirmation
                title={locContext.deletePackage}
                description={locContext.confirmDeletePackage}
                confirmText={locContext.yesDelete}
                cancelText={locContext.cancel}
                confirm={async () => {
                    await del(index);
                    modalContext.close();
                }}
                cancel={() => modalContext.close()}
            />, false);
    }

    if (loadOperations && loadOperations.length > 0) {
        return (
            <div className="blank-container">
                <div className="header-text">{locContext.load}: {locContext.selectAPackage}</div>
                {loadOperations.map(t =>
                    <div key={loadOperations.indexOf(t)} className="df-col">
                        <LoadOperationViewComponent
                            loadOperation={t}
                            onEdit={() => onEdit(t)}
                            onDelete={() => onDelete(t)} />
                        <Divider />
                    </div>)}
                <TextLinkButton className="font-bold" onClick={() => onAddNew()}>+ {locContext.addPackage}</TextLinkButton>
                <div className="df-row-ac jc-c blank-container-buttons">
                    <div className="df-row-ac">
                        <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                        <PrimaryButton disabled={context.isSubmitting} showLoader rectanglePadding onClick={onNext}>{locContext.next}</PrimaryButton>
                    </div>
                </div>
            </div>
        );
    } else {
        return <LoadOperationComponent
            packageOptions={context.packages}
            validate={validate}
            onPrevious={onPrevious}
            onSubmit={add}
            header={`${locContext.load}: ${locContext.selectAPackage}`}
            loadOperation={{}}
            isEdit={false} />
    }
}

interface ILoadOpViewProps {
    loadOperation: ILoadOperation;
    onEdit?: () => void;
    onDelete: () => void;
}

export const LoadOperationViewComponent = ({ loadOperation, onEdit, onDelete }: ILoadOpViewProps) => {
    const locContext = useLocalizationContext();
    return (
        <div className="df-row jc-sb load-operation-view">
            <div className="df-col fg1">
                <div className="font-bold">{loadOperation.description}</div>
                <div>{locContext.quantity}: {loadOperation.quantity}</div>
                <div>{locContext.placeholderWeightInKgPerPackage}: {loadOperation.weightPerPackage}kg</div>
                <div>{locContext.placeholderDimensions}: {loadOperation.lengthPerPackage}m x {loadOperation.widthPerPackage}m x {loadOperation.heightPerPackage}m</div>
            </div>
            <div className="df-col">
                {onEdit ?
                    <TextLinkButton gray className="lo-button" onClick={onEdit}>{locContext.change}</TextLinkButton>
                    : null}
                <TextLinkButton gray className="lo-button" onClick={onDelete}>{locContext.delete}</TextLinkButton>
            </div>
        </div>
    );
}

interface IUnloadOpViewProps {
    loadOperationsInCargo: ILoadOperationInCargo[] | undefined;
    unloadOperation: IUnloadOperationModel;
    onDelete: () => void;
}

export const UnloadOperationViewComponent = ({ loadOperationsInCargo, unloadOperation, onDelete }: IUnloadOpViewProps) => {
    const locContext = useLocalizationContext();
    const x = loadOperationsInCargo ? loadOperationsInCargo.find(t => t.loadId === unloadOperation.loadId) : undefined;
    return (
        <div className="df-row jc-sb load-operation-view">
            <div className="df-col fg1">
                <div>{x ? x.formatted : ''}</div>
            </div>
            <div className="df-col">
                <TextLinkButton gray className="lo-button" onClick={onDelete}>{locContext.delete}</TextLinkButton>
            </div>
        </div>
    );
}