import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IOption } from '../../../infrastructure/Models';
import { ListFilterItem } from './ListFilterItem';


interface IProps {
    onFilter: (selection: string[]) => void;
    icon: IconProp;
    label: string;
    options: IOption<string>[];
    selection: string[];
    canSearch?: boolean;
}

export const EnumListFilterItem = ({ icon, label, options, selection, canSearch, onFilter }: IProps) => {
    return (
        <ListFilterItem<string>
            icon={icon}
            label={label}
            options={options}
            selection={selection}
            onFilter={onFilter}
            canSearch={canSearch} />
    );
}