import moment from 'moment';
import { DefaultDateFormat } from '../../../constants';

export function roundToNearestTime(date: Date): string {
	const format = 'HH:mm';
	const x = moment(date, DefaultDateFormat);
	if (date.getMinutes() === 0 || date.getMinutes() === 15 || date.getMinutes() === 45) {
		return moment(date, DefaultDateFormat).format(format);
	} else if (date.getMinutes() > 45) {
		x.add(1, 'hours');
		x.set('minutes', 0);
		return x.format(format);
	} else if (date.getMinutes() > 30) {
		x.set('minutes', 45);
		return x.format(format);
	} else if (date.getMinutes() > 15) {
		x.set('minutes', 30);
		return x.format(format);
	} else {
		x.set('minutes', 15);
		return x.format(format);
	}
}
