import React, { useState, useContext } from 'react';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { useClient, useDownloadClient, useUploadClient } from '../../../hooks/useClient';
import { RouterContext } from '../../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { tryCatchWithLoading } from '../../../infrastructure/Utils';
import { showSuccessToast, showErrorToast } from '../../common/toast/ToastNotification';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './ImportFileForm.scss';
import { BuildClient } from '../../../gen/ApiClients';

interface IProps {
	onClose: () => void;
}
export const ImportFileForm = ({ onClose }: IProps) => {
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const routerContext = useContext(RouterContext);
	const downloadClient = useDownloadClient(routerContext);
	const uploadClient = useUploadClient(routerContext);
	const loaderContext = useContext(WrapperFullScreenLoadContext);
	const locContext = useLocalizationContext();
	const buildClient = useClient(BuildClient);

	const importFile = () => {
		const x = document.getElementById('file-upload') as any;
		x.value = '';
		x.click();
	};

	const handleSelect = async (e: any) => {
		const file = e.target.files[0];
		const baseUrl = await buildClient.url();
		const x = await tryCatchWithLoading(uploadClient.upload(baseUrl + '/api/addresses/import', file), setIsUploading, locContext.serverError);
		if (x !== undefined && x.hasError === false) {
			showSuccessToast(locContext.successfullyImportedAddresses);
			onClose();
		} else {
			showErrorToast(x.error ? x.error : locContext.serverError);
		}
	};

	const downloadExample = async () => {
		loaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		const url = `${baseUrl}/api/addresses/exportExampleFile`;
		await downloadClient.download(url, `example.xlsx`, () => loaderContext.setLoading(false));
	};

	return (
		<div className='uf-container df-col stretch-ver'>
			<div className='uf-title'>
				{locContext.import} {locContext.addressBook}
			</div>
			<div className='fg1'>
				<div className='uf-description'>{locContext.importAddressBookContent}</div>
				<div className='uf-headers'>'Id'-'Country'-'PostalCode'-'City'-'Street'-'HouseNumber'-'AdditionalInfo'-'Company'-'PhoneNumber'-'Contact'-'Comment'</div>
				<div className='df-row-ac uf-description uf-download'>
					<div>
						<TextLinkButton
							small
							underline
							onClick={() => downloadExample()}>
							{locContext.clickHereToDownloadExampleFile}
						</TextLinkButton>
					</div>
				</div>
				<input
					accept='.xlsx'
					id='file-upload'
					multiple={false}
					type='file'
					style={{ display: 'none' }}
					onChange={e => handleSelect(e)}
				/>
			</div>
			<PrimaryButton
				disabled={isUploading}
				showLoader
				noStretch
				rectanglePadding
				onClick={() => importFile()}>
				{locContext.selectAFile}
			</PrimaryButton>
		</div>
	);
};
