import React from 'react';
import { ErrorMessage } from 'formik';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { nameof } from '../../../infrastructure/Utils';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './FieldGroup.scss';

export interface IFieldGroupProps<T> {
    xName: keyof T;
    label: string;
    noMargin?: boolean;
    optional?: boolean;
    xNames?: Array<keyof T>;
    children?: React.ReactNode;
    className?: string;
    placeholder?: string;
    overrideError?: string;
}

export function FieldGroup<T>({ xName, label, noMargin = false, children, optional = false, xNames = undefined, overrideError = undefined, ...rest }: IFieldGroupProps<T>) {
    const locContext = useLocalizationContext();
    const className = useCombineClassNames(`field-group${noMargin ? ' no-margin' : ''}`, rest);
    return (
        <div className={className}>
            <div className="df-row-ac jc-sb label-container">
                <label htmlFor={nameof(xName)}>{label}</label>
                {optional ? <div className="optional-tag">{locContext.optional.toLowerCase()}</div> : null}
            </div>
            {children}
            {overrideError ?
                <span className="error-message">{overrideError}</span>
                : xNames ?
                    <div className="df-col">
                        {xNames.map(t => <ErrorMessage key={nameof(t)} className="error-message" name={nameof(t)} component="span" />)}
                    </div>
                    :
                    <ErrorMessage className="error-message" name={nameof(xName)} component="span" />
            }
        </div>
    );
}
