import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { DashboardSearchContext } from '../../../context/DashboardSearchTextContext';
import { DraftsQueryParamsContext } from '../../../context/DraftsQueryParamsContext';
import { ModalContext } from '../../../context/ModalContext';
import { RouterContext } from '../../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../../context/WrapperFullScreenLoadContext';
import { DraftsClient, DraftsQueryParams, IDraftsQueryParams, IDraftSummary, IDraftSummaryQueryResult } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { useTrigger } from '../../../hooks/useTrigger';
import { toDefaultFormat, tryCatch, tryCatchWithLoading } from '../../../infrastructure/Utils';
import * as routes from '../../../routes';
import { IconButton } from '../../common/buttons/IconButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { BaseConfirmation } from '../../common/modal/BaseConfirmation';
import { BaseTableSortAndPage } from '../../common/table/BaseTableSortAndPage';
import { PagingFooter } from '../../common/table/PagingFooter';
import { createEmptyHeader, createHeader, createSortableHeader } from '../../common/table/TableUtils';
import * as subRoutes from '../subRoutes';
import './Drafts.scss';
import { FilterDrafts } from './FilterDrafts';

export const Drafts = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const routerContext = useContext(RouterContext);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const locContext = useLocalizationContext();
	const client = useClient(DraftsClient);
	const [queryResult, setQueryResult] = useState<IDraftSummaryQueryResult>();
	const { params, setParams } = useContext(DraftsQueryParamsContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(DashboardSearchContext).init(`${locContext.searchOn} ${locContext.reference.toLowerCase()}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		const t = await tryCatchWithLoading(client.query(new DraftsQueryParams(params)), wrapperLoaderContext.setLoading);
		setQueryResult(t);
	};

	const onEdit = (inst: IDraftSummary) => {
		routerContext.navigate(routes.calculateDraftsRoute(inst));
	};

	const viewDetail = (inst: IDraftSummary) => {
		routerContext.navigate(subRoutes.calculateDraftDetailRoute(inst));
	};

	function renderActions(inst: IDraftSummary) {
		return (
			<div className='df-row-ac jc-e actions-buttons'>
				<IconButton
					color='success'
					size='large'
					icon={['far', 'edit']}
					onClick={() => onEdit(inst)}
					popupText={locContext.createFromDraft}
				/>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'trash-alt']}
					onClick={() => del(inst)}
					popupText={locContext.deleteDraft}
				/>
			</div>
		);
	}

	const headers = [
		createSortableHeader<IDraftSummary>(locContext.lastModifiedDate, 'lastModifiedDate', t => toDefaultFormat(t.lastModifiedDate)),
		createHeader<IDraftSummary>(locContext.reference, t => t.reference),
		createHeader<IDraftSummary>(locContext.vehicle, t => t.vehicleName),
		createHeader<IDraftSummary>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
		createEmptyHeader<IDraftSummary>('actions', t => renderActions(t), false, true),
	];

	const del = (inst: IDraftSummary) => {
		modalContext.open(
			<BaseConfirmation
				title={locContext.deleteDraft}
				description={locContext.confirmDeleteDraft}
				confirmText={locContext.yesDelete}
				cancelText={locContext.cancel}
				confirm={async () => {
					if (inst.id) {
						await tryCatch(client.delete(inst.id), locContext.serverError);
						hitTrigger();
					}
					modalContext.close();
				}}
				cancel={() => modalContext.close()}
			/>
		);
	};

	return (
		<Card className='drafts-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb header'>
					<div>
						<Link to={routes.NewTransportRoute}>
							<PrimaryButton small>+ {locContext.newTransport}</PrimaryButton>
						</Link>
					</div>
					<Responsive {...Responsive.onlyMobile}>
						<PrimaryButton
							small
							outline
							className='filter-button'
							onClick={() => setIsFilterOpen(!isFilterOpen)}>
							{locContext.filterOn}
						</PrimaryButton>
					</Responsive>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<FilterDrafts
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<FilterDrafts
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
					/>
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<BaseTableSortAndPage<IDraftSummary, IDraftsQueryParams>
						params={params}
						onSortOrPage={t => setParams(t)}
						queryResult={queryResult}
						canSort={true}
						headers={headers}
						uniqueIdentifier='id'
						singleIdentifier={locContext.concept}
						onClickRow={t => viewDetail(t)}
					/>
				</Responsive>
				<Responsive {...Responsive.onlyMobile}>
					{queryResult && queryResult.values ? (
						<div className='df-col'>
							{queryResult.values.map(t => (
								<div
									className='df-col'
									key={t.reference}>
									<div className='mobile-single-draft padding-10px df-row'>
										<div
											className='fg1 df-col'
											onClick={() => viewDetail(t)}>
											<div className='reference'>{t.reference}</div>
											<div className='info'>{t.formattedAddress}</div>
											<div className='info'>{t.vehicleName}</div>
											<div className='info'>{toDefaultFormat(t.lastModifiedDate)}</div>
										</div>
										<div>{renderActions(t)}</div>
									</div>
									<Divider className='no-margin' />
								</div>
							))}
							<PagingFooter
								className='padding-10px'
								singleIdentifier={locContext.concept}
								qr={queryResult ? queryResult : {}}
								onNext={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex + 1 : 1 })}
								onPrevious={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex - 1 : 1 })}
							/>
						</div>
					) : null}
				</Responsive>
			</div>
		</Card>
	);
};
