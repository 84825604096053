import { ITransportSummary, IDraftSummary, ITemplateSummary } from "../../gen/ApiClients";

export const OverviewRoute = '/dashboard';
export const TranportsRoute = '/transports';
export const SettingsRoute = '/settings';
export const NotificationsRoute = '/notifications';
export const AddressesRoute = '/addresses';
export const DraftsRoute = '/transports/drafts';
export const DraftDetailRoute =  DraftsRoute +'/:id';
export const TemplatesRoute = '/transports/templates';
export const TemplateDetailRoute =  TemplatesRoute +'/:id';
export const TransportRoute = '/transports/:fileNumber';

export const calculateTransportDetailRoute = (inst: ITransportSummary): string => '/transports/' + inst.fileNumber;
export const calculateDraftDetailRoute = (inst: IDraftSummary): string => '/transports/drafts/' + inst.id;
export const calculateTemplateDetailRoute = (inst: ITemplateSummary): string => '/transports/templates/' + inst.id;