import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTablePage } from '../../../hooks/useTableSortAndPage';
import { IHeader, IQueryResult, ISortAndPageProps, ISortProps } from '../../../infrastructure/Models';
import './BaseTable.scss';
import { BaseTableSort } from './BaseTableSort';
import { PagingFooter } from './PagingFooter';

interface IProps<T, TQueryParams extends ISortAndPageProps> {
    canSort: boolean;
    headers: IHeader<T>[];
    uniqueIdentifier: keyof T;
    queryResult: IQueryResult<T> | undefined;
    params: TQueryParams;
    onSortOrPage: (params: TQueryParams) => void;
    fallbackSortProps?: ISortProps;
    className?: string;
    singleIdentifier: string;
    onClickRow?: (inst: T) => void;
    formatRow?: (inst: T) => string;
}

export function BaseTableSortAndPage<T, TQueryParams extends ISortAndPageProps>({ queryResult, onSortOrPage, params, singleIdentifier, onClickRow, formatRow, ...rest }: IProps<T, TQueryParams>) {
    const [next, previous] = useTablePage<TQueryParams>();
    if (!queryResult || !queryResult.values) {
        return null;
    }

    function renderFooter(qr: IQueryResult<T>) {
        return (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={rest.headers.length}>
                        <PagingFooter
                            singleIdentifier={singleIdentifier}
                            qr={queryResult ? queryResult : {}}
                            onNext={() => onSortOrPage(next(params))}
                            onPrevious={() => onSortOrPage(previous(params))} />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    }

    return (
        <BaseTableSort<T, TQueryParams>
            {...rest}
            params={params}
            values={queryResult.values}
            onSort={(t) => onSortOrPage(t)}
            footer={renderFooter(queryResult)}
            onClickRow={onClickRow}
            formatRow={formatRow} />
    );
}