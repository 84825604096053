import React, { useContext } from 'react';
import { Logo } from '../../common/Logo';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { ProgressBar } from './ProgressBar';
import { TransportSubmitContext } from '../../../context/TransportSubmitContext';
import { Link } from 'react-router-dom';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { SaveAsDraftForm } from '../common/SaveAsDraftForm';
import { Responsive } from 'semantic-ui-react';
import { TransportSubmitModalContext } from '../../../context/TransportSubmitModalContext';
import { ValidGreenIcon } from '../../common/ValidGreenIcon';
import * as routes from '../../../routes';
import './Header.scss';

export const Header = () => {
    const context = useContext(TransportSubmitContext);
    const locContext = useLocalizationContext();
    const modalContext = useContext(TransportSubmitModalContext);

    const saveAsDraft = () => {
        modalContext.open(
            <SaveAsDraftForm
                cancel={() => modalContext.close()}
                submit={(model) => {
                    modalContext.close();
                    context.saveAsDraft(model);
                }} />, false);
    }

    const renderSaveAsDraftButton = () => {
        if (context.state.isSavedAsDraft === true) {
            return (
                <div className="df-row-ac saved-as-draft">
                    <ValidGreenIcon />
                    <div className="status-text">{locContext.savedAsConcept}</div>
                </div>
            );
        } else if (context.step === 0 || context.step === 5) {
            return null;
        } else {
            return <TextLinkButton className="save-as-draft-button" onClick={() => saveAsDraft()}>{locContext.saveAsConcept}</TextLinkButton>
        }
    }

    const renderCancelShutdownButton = (isMobile: boolean) => {
        return (
            context.step === 5 ?
                <Link to={routes.DashboardRoute}>
                    <TextLinkButton>{locContext.shutDown}</TextLinkButton>
                </Link>
                :
                context.step === 0 && isMobile === true ?
                    null :
                    <TextLinkButton className="cancel-button" onClick={() => context.cancel()}>{locContext.cancel}</TextLinkButton>
        );
    }

    return (
        <div className="header-container">
            <Responsive minWidth={Responsive.onlyTablet.minWidth} className="df-row-afs">
                <div className="left">
                    <Logo />
                </div>
                {context.step === 0 ? null : <ProgressBar />}
                <div className="right df-row-ac">
                    {renderSaveAsDraftButton()}
                    {renderCancelShutdownButton(false)}
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-col stretch-hor">
                    {context.step === 0 ? null : <ProgressBar />}
                    <div className="df-row-ac jc-sb padding-10px">
                        {renderSaveAsDraftButton()}
                        {renderCancelShutdownButton(true)}
                    </div>
                </div>
            </Responsive>
        </div>
    );
}
