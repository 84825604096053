import { library } from '@fortawesome/fontawesome-svg-core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Dashboard } from './components/dashboard/Dashboard';
import { Login } from './components/login/Login';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouterProvider } from './context/RouterContext';
import { ProtectedRoute } from './infrastructure/ProtectedRoute';
import * as fromRoutes from './routes';
import React from 'react';
// library stuff for font-awesome
import { faEdit, faEye, faEyeSlash, faFilePdf, faSquare, faStar as faStarRegular, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
	faArrowLeft,
	faBars,
	faCalendar,
	faCarSide,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronDown,
	faChevronUp,
	faEllipsisV,
	faExclamationTriangle,
	faFile,
	faFileSignature,
	faIndustry,
	faInfo,
	faInfoCircle,
	faPlus,
	faPlusCircle,
	faReceipt,
	faSearch,
	faStar,
	faTimes,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { AppUpdateWall } from './components/AppUpdateWall';
import { OuterWrapper } from './components/blank/Wrapper';
import { ForgotPassword } from './components/login/ForgotPassword';
import { AddressesQueryParamsProvider } from './context/AddressesQueryParamsContext';
import { ConfirmedTransportsQueryParamsProvider } from './context/ConfirmedTransportsQueryParamsContext';
import { DraftsQueryParamsProvider } from './context/DraftsQueryParamsContext';
import { LocalizationProvider } from './context/LocalizationContext';
import { ModalProvider } from './context/ModalContext';
import { NotificationsQueryParamsProvider } from './context/NotificationsQueryParamsContext';
import { TemplatesQueryParamsProvider } from './context/TemplatesQueryParamsContext';
import { TransportsQueryParamsProvider } from './context/TransportsQueryParamsContext';

library.add(
	faEye,
	faEyeSlash,
	faSearch,
	faStar,
	faStarRegular,
	faChevronUp,
	faChevronDown,
	faEdit,
	faEllipsisV,
	faUsers,
	faCheckSquare,
	faSquare,
	faCheck,
	faCalendar,
	faInfo,
	faCarSide,
	faTimes,
	faInfoCircle,
	faIndustry,
	faPlus,
	faCheckCircle,
	faExclamationTriangle,
	faReceipt,
	faFilePdf,
	faFile,
	faFileSignature,
	faPlusCircle,
	faTrashAlt,
	faArrowLeft,
	faBars
);

export function App() {
	return (
		<BrowserRouterProvider>
			<LocalizationProvider>
				<AuthProvider>
					<ModalProvider>
						<AddressesQueryParamsProvider>
							<ConfirmedTransportsQueryParamsProvider>
								<DraftsQueryParamsProvider>
									<TemplatesQueryParamsProvider>
										<TransportsQueryParamsProvider>
											<NotificationsQueryParamsProvider>
												<ToastContainer
													position={toast.POSITION.TOP_CENTER}
													autoClose={3000}
													hideProgressBar={false}
													newestOnTop={true}
													closeOnClick
													closeButton={false}
													transition={Slide}
													draggable={false}
												/>
												<AppUpdateWall>
													<Switch>
														<Route
															path={fromRoutes.LoginRoute}
															component={Login}
														/>
														<Route
															path={fromRoutes.ForgotPasswordRoute}
															component={ForgotPassword}
														/>
														<ProtectedRoute
															path={fromRoutes.NewTransportRoute}
															component={OuterWrapper}
														/>
														<ProtectedRoute
															path={fromRoutes.DashboardRoute}
															component={Dashboard}
														/>
														<Redirect
															path='/'
															to={fromRoutes.DashboardRoute}
														/>
													</Switch>
												</AppUpdateWall>
											</NotificationsQueryParamsProvider>
										</TransportsQueryParamsProvider>
									</TemplatesQueryParamsProvider>
								</DraftsQueryParamsProvider>
							</ConfirmedTransportsQueryParamsProvider>
						</AddressesQueryParamsProvider>
					</ModalProvider>
				</AuthProvider>
			</LocalizationProvider>
		</BrowserRouterProvider>
	);
}
