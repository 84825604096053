import { IAddressModel, ISuggestion } from '../../../gen/ApiClients';
import { IStringsWithFormat } from '../../../hooks/useLocalizationContext';
import * as yup from 'yup';

export function setFields(values: IAddressModel, selAddress: ISuggestion): IAddressModel {
    const x = {
        ...values,
        ...selAddress,
        country: selAddress.country,
        postalCode: selAddress.postalCode,
        city: selAddress.city,
        street: selAddress.street,
        houseNumber: selAddress.houseNumber,
        isExact: selAddress.isExact,
        xCoordinate: selAddress.xCoordinate,
        yCoordinate: selAddress.yCoordinate,
        additionalInfo: values.additionalInfo,
        company: values.company,
        phoneNumber: values.phoneNumber,
        contact: values.contact,
        comment: values.comment
    };
    return x;
}

export function createValidateSchemaWithHouseNumber(locContext: IStringsWithFormat): yup.ObjectSchema<yup.Shape<IAddressModel, IAddressModel>> {
    return yup.object<IAddressModel>().shape<IAddressModel>({
        street: yup.string(),
            // .required(locContext.validateAddress),
        company: yup.string()
            .required(locContext.validateCompanyName)
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        contact: yup.string()
            .required(locContext.validateContactPerson)
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        additionalInfo: yup.string()
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        comment: yup.string()
            .max(60, locContext.formatString('maxLengthValidation', 60)),
        phoneNumber: yup.string()
            .max(30, locContext.formatString('maxLengthValidation', 30)),
        notFoundHouseNumber: yup.string()
            .required(locContext.validateHouseNumberIfNotFoundAbove),
    });    
}
export function createValidateSchemaWithoutHouseNumber(locContext: IStringsWithFormat): yup.ObjectSchema<yup.Shape<IAddressModel, IAddressModel>> {
    return yup.object<IAddressModel>().shape<IAddressModel>({
        street: yup.string(),
        //     .required(locContext.validateAddress),
        company: yup.string()
            .required(locContext.validateCompanyName)
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        contact: yup.string()
            .required(locContext.validateContactPerson)
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        additionalInfo: yup.string()
            .max(50, locContext.formatString('maxLengthValidation', 50)),
        comment: yup.string()
            .max(60, locContext.formatString('maxLengthValidation', 60)),
        phoneNumber: yup.string()
            .max(30, locContext.formatString('maxLengthValidation', 30)),
    });  
}