import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { Responsive } from 'semantic-ui-react';
import * as yup from 'yup';
import { ISaveAsDraftModel } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { TextInputField } from '../../common/forms/TextInputField';

interface IProps {
    cancel: () => void;
    submit: (model: ISaveAsDraftModel) => void;
}

export const SaveAsDraftForm = ({ cancel, submit }: IProps) => {
    const locContext = useLocalizationContext();

    const localSubmit = (e: any, props: FormikProps<ISaveAsDraftModel>) => {
        e.preventDefault();
        setFieldTouched<ISaveAsDraftModel>('reference', props);
        if (props.isValid) {
            submit(props.values);
        }
    }

    const validateSchema = yup.object<ISaveAsDraftModel>().shape<ISaveAsDraftModel>({
        reference: yup.string()
            .required(locContext.validateReference),
    });

    return (
        <div className="blank-container pos-rel df-col stretch-ver">
            <div className="header-text">{locContext.saveAsConcept}</div>
            <div className="fg1">
                <Formik<ISaveAsDraftModel>
                    initialValues={{ reference: '' }}
                    validationSchema={validateSchema}
                    onSubmit={() => { }}>
                    {(props) => (
                        <Form className="stretch-ver">
                            <div className="stretch-ver df-col">
                                <div className="fg1">
                                    <TextInputField<ISaveAsDraftModel> label={locContext.reference} xName="reference" placeholder={locContext.reference} />
                                </div>
                                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                    <div className={`df-row-ac blank-container-buttons jc-e`}>
                                        <div className="df-row-ac">
                                            <TextLinkButton className="previous-button" onClick={() => cancel()}>{locContext.cancel}</TextLinkButton>
                                            <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => localSubmit(e, props)}>{locContext.saveAsConcept}</PrimaryButton>
                                        </div>
                                    </div>
                                </Responsive>
                                <Responsive {...Responsive.onlyMobile}>
                                    <div className="df-col">
                                        <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => localSubmit(e, props)}>{locContext.saveAsConcept}</PrimaryButton>
                                        <TextLinkButton className="previous-button" onClick={() => cancel()}>{locContext.cancel}</TextLinkButton>
                                    </div>
                                </Responsive>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}