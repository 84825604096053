import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { Responsive } from 'semantic-ui-react';
import * as yup from 'yup';
import { ISaveAsTemplateModel } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { TextInputField } from '../../common/forms/TextInputField';
import './SaveAsTemplateForm.scss';

interface IProps {
    cancel: () => void;
    submit: (model: ISaveAsTemplateModel) => Promise<any>;
}

export const SaveAsTemplateForm = ({ cancel, submit }: IProps) => {
    const locContext = useLocalizationContext();

    const localSubmit = async (e: any, props: FormikProps<ISaveAsTemplateModel>) => {
        e.preventDefault();
        props.setSubmitting(true)
        setFieldTouched<ISaveAsTemplateModel>('reference', props);
        if (props.isValid) {
            await submit(props.values);
        }
        props.setSubmitting(false);
    }

    const validateSchema = yup.object<ISaveAsTemplateModel>().shape<ISaveAsTemplateModel>({
        reference: yup.string()
            .required(locContext.validateReference),
    });

    return (
        <div className="blank-container pos-rel df-col stretch-ver save-as-template-form">
            <div className="header-text">{locContext.saveAsTemplate}</div>
            <div className="fg1">
                <Formik<ISaveAsTemplateModel>
                    initialValues={{ reference: '' }}
                    validationSchema={validateSchema}
                    onSubmit={() => { }}>
                    {(props) => (
                        <Form>
                            <div className="stretch-ver df-col">
                                <div className="fg1">
                                    <TextInputField<ISaveAsTemplateModel> label={locContext.reference} xName="reference" placeholder={locContext.reference} />
                                </div>
                                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                    <div className={`df-row-ac blank-container-buttons jc-e`}>
                                        <div className="df-row-ac">
                                            <TextLinkButton className="previous-button" onClick={() => cancel()}>{locContext.cancel}</TextLinkButton>
                                            <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => localSubmit(e, props)}>{locContext.saveAsTemplate}</PrimaryButton>
                                        </div>
                                    </div>
                                </Responsive>
                                <Responsive {...Responsive.onlyMobile}>
                                    <div className="df-col">
                                        <PrimaryButton className="stretch-hor" rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => localSubmit(e, props)}>{locContext.saveAsTemplate}</PrimaryButton>
                                        <TextLinkButton className="text-button" onClick={() => cancel()}>{locContext.cancel}</TextLinkButton>
                                    </div>
                                </Responsive>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}