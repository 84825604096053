import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import './PrimaryButton.scss';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    outline?: boolean;
    small?: boolean;
    noStretch?: boolean;
    rectanglePadding?: boolean;
    showLoader?: boolean;
}

export const PrimaryButton = ({ children, outline = false, small = false, noStretch = false, rectanglePadding = false, showLoader = false, ...rest }: IProps) => {
    const className = useCombineClassNames(`primary-button${outline ? ' outline' : ''}${small ? ' small' : ''}${noStretch ? '' : ' stretch'}${rectanglePadding ? ' rectangle-padding' : ''}`, rest);
    return (
        // type="button" before {...rest} -> so can override this behaviour
        <button type="button" {...rest} className={className}>
            {showLoader === true && rest.disabled === true ?
                <div className="df-row-ac pos-rel">
                    <div className="loader-wrapper"><Loader active inline='centered' /></div>
                    <div className="loader-children">{children}</div>
                </div>
                :
                children}
        </button>
    );
}