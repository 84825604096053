import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useContext } from 'react';
import { IRouterProps, RouterContext } from '../context/RouterContext';
import { HttpResponse } from '../gen/ApiClients';
import { LanguageStore } from '../infrastructure/LanguageStore';
import { TokenStore } from '../infrastructure/TokenStore';
import { ApiUrl } from '../keys';
import * as fromRoutes from '../routes';

export interface IDownloadClient {
	download: (url: string, fileName: string, doOnEnd?: () => void) => void;
}
export interface IUploadClient {
	upload: (url: string, file: any, doOnEnd?: () => void) => Promise<HttpResponse>;
}

export function useUploadClient(router: IRouterProps): IUploadClient {
	return {
		upload: (url, file, doOnEnd) => {
			const x = new FormData();
			x.append('File', file);
			let inst = axios.create();
			inst.interceptors.request.use(tokenInterceptor, error => {
				if (doOnEnd) {
					doOnEnd();
				}
				return Promise.reject(error);
			});
			inst.interceptors.request.use(languageInterceptor, error => Promise.reject(error));
			inst.interceptors.response.use(
				response => response,
				error => unauthorizedRedirectInterceptor(router, error)
			);
			return inst.post(url, x).then(response => {
				const status = response.status;
				if (status === 200) {
					const _responseText = response.data;
					let result200: any = null;
					let resultData200 = _responseText;
					result200 = HttpResponse.fromJS(resultData200);
					return result200;
				}
				return Promise.reject();
			});
		},
	};
}

export function useDownloadClient(router: IRouterProps): IDownloadClient {
	return {
		download: (url, fileName, doOnEnd) => {
			let inst = axios.create({ responseType: 'blob' });
			inst.interceptors.request.use(tokenInterceptor, function (error) {
				if (doOnEnd) {
					doOnEnd();
				}
				return Promise.reject(error);
			});
			inst.interceptors.request.use(languageInterceptor, error => Promise.reject(error));
			inst.interceptors.response.use(
				response => response,
				error => unauthorizedRedirectInterceptor(router, error)
			);
			inst.get(url).then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();
				if (doOnEnd) {
					doOnEnd();
				}
			});
		},
	};
}

export function useClient<T>(type: new (baseUrl?: string, instance?: AxiosInstance) => T): T {
	let inst = axios.create();
	const router = useContext(RouterContext);

	inst.interceptors.request.use(tokenInterceptor, error => {
		return Promise.reject(error);
	});
	inst.interceptors.request.use(languageInterceptor, error => {
		return Promise.reject(error);
	});
	inst.interceptors.response.use(
		response => response,
		error => {
			return unauthorizedRedirectInterceptor(router, error);
		}
	);
	return new type(ApiUrl, inst);
}

function tokenInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
	const token = TokenStore.getToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
}

function unauthorizedRedirectInterceptor(router: any, error: any): any {
	if (error && error.response && error.response.status === 401) {
		router.history.push(fromRoutes.LoginRoute);
	}
	return Promise.reject(error);
}

function languageInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
	const language = LanguageStore.get();
	if (language) {
		config.headers.Language = language;
	}
	return config;
}
