import {
	IValidateResponse,
	TransportValidateClient,
	IExtraStopModel,
	IFirstStopModel,
	ValidateCapacityModel,
	ILoadOperation,
	LoadOperation,
} from '../../../gen/ApiClients';

export async function validateCapacity(
	client: TransportValidateClient,
	transportId: string,
	stopId: string | undefined,
	model: IExtraStopModel | undefined,
	loadOp: ILoadOperation
): Promise<IValidateResponse> {
	return await client.validateCapacity(
		transportId,
		new ValidateCapacityModel({
			stopId: stopId,
			loadOperations: calculateLoadOpList(model, loadOp),
			unloadOperations: model && model.unloadOperations ? model.unloadOperations.map(t => (t.loadId ? t.loadId : '')) : undefined,
		})
	);
}

const calculateLoadOpList = (model: IFirstStopModel | IExtraStopModel | undefined, loadOp: ILoadOperation) => {
	if (loadOp.id) {
		if (!model || !model.loadOperations) {
			return [new LoadOperation(loadOp)];
		} else {
			const index = model.loadOperations.findIndex(t => t.id === loadOp.id);
			return [...model.loadOperations.slice(0, index), new LoadOperation(loadOp), ...model.loadOperations.slice(index + 1)];
		}
	} else {
		return model && model.loadOperations ? [...model.loadOperations, new LoadOperation(loadOp)] : [new LoadOperation(loadOp)];
	}
};
